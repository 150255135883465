import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";

const CourseStep5 = (props) => {
  const [template, setTemplate] = useState(null);
  useEffect(() => {
    if (props.course.template !== null && template === null) {
      setTemplate(props.course.template);
    }
  }, [props.course.template, template]);

  const templates = [
    {
      code: "basic",
      name: "Solo Firma doña Rossana",
    },
    {
      code: "advanced",
      name: "Firma doña Rossana y Facilitador",
    },
  ];
  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined ",
  };
  const imageFileUpload = (
    <FileUpload
      style={{ width: "", borderRadius: "12px" }}
      name="imageUpload"
      accept="image/*"
      customUpload={true}
      onSelect={(e) => props.onChangeHandler("image", e.files[0])}
      mode="advanced"
      chooseOptions={chooseOptions}
      uploadOptions={uploadOptions}
      cancelOptions={cancelOptions}
      emptyTemplate={
        <p className="m-0">Seleccione el archivo con la imagen del curso.</p>
      }
    />
  );
  const brochureFileUpload = (
    <FileUpload
      style={{ width: "", borderRadius: "12px" }}
      name="imageUpload"
      accept="application/pdf"
      customUpload={true}
      onSelect={(e) => props.onChangeHandler("brochure", e.files[0])}
      mode="advanced"
      chooseOptions={chooseOptions}
      uploadOptions={uploadOptions}
      cancelOptions={cancelOptions}
      emptyTemplate={
        <p className="m-0">Seleccione el archivo con el brochure del curso.</p>
      }
    />
  );

  return (
    <>
      <div
        className="mb-4 bg-blue-900 p-3 mt-4"
        style={{ marginLeft: "0px", width: "98%" }}
      >
        <h2
          style={{
            color: "#fff",
            textDecoration: "underline",
            textAlign: "center",
            fontSize: "xx-large",
          }}
        >
          Multimedia del Curso
        </h2>
        <div className="formgrid grid">
          <div className="field col-12 md:col-6">
            <label
              htmlFor="ZoomUrl"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Link de Zoom
            </label>
            <InputText
              id="zoomUrl"
              value={props.course.zoomUrl}
              onChange={(e) => props.onChangeHandler("zoomUrl", e.target.value)}
              placeholder="Link de Zoom"
              style={{ width: "100%" }}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label
              htmlFor="ZoomUrl"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Video de Youtube
            </label>
            <InputText
              id="zoomUrl"
              value={props.course.videoUrl}
              onChange={(e) =>
                props.onChangeHandler("videoUrl", e.target.value)
              }
              placeholder="Video de Youtube"
              style={{ width: "100%" }}
            />
          </div>
          <div className="field col-12 md:col-4">
            <label
              htmlFor="logo"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Imagen: {imageFileUpload}
            </label>
          </div>
          <div className="field col-12 md:col-4">
            <label
              htmlFor="logo"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Brochure: {brochureFileUpload}
            </label>
          </div>
          <div className="field col-12 md:col-4">
            <label
              htmlFor="module"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Nombre del Template:
            </label>
            <Dropdown
              value={template}
              onChange={(e) =>
                props.onChangeHandler("template", e.target.value)
              }
              options={templates}
              optionLabel="name"
              placeholder="Selecciona un template"
              filter
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default CourseStep5;
