import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "primereact/card";
import { Button } from "primereact/button";

import Loading from "../../../components/UI/Loading";
import {
  ActiveRoomPersonType,
  GetRoomPersonType,
  InactiveRoomPersonType,
} from "../../../store/roompersontype-store/roompersontype-actions";

const RoomPersonTypeDetail = () => {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roompersontype = useSelector((state) => state.roomPersonType.roomPersonType);
  const auth = useSelector((state) => state.auth);
  const notification = useSelector((state) => state.ui.notification);

  useEffect(() => {
    if (roompersontype.id !== undefined || roompersontype.id !== 0) {
      dispatch(GetRoomPersonType(param.roompersontypeId, auth.UserInfo.email));
    }
  }, [param.roompersontypeId, auth, dispatch, roompersontype.id]);

  const activeHandler = () => {
    dispatch(ActiveRoomPersonType(param.roompersontypeId, auth.UserInfo.email));
  };
  const inactiveHandler = () => {
    dispatch(InactiveRoomPersonType(param.roompersontypeId, auth.UserInfo.email));
  };
  let activeSuspend;
  if (auth.roles !== undefined && auth.roles.includes("roompersontype.active")) {
    activeSuspend = (
      <Button
        rounded
        text
        raised
        style={{ backgroundColor: "#fff", marginLeft: "1%" }}
        label="Activar"
        className="p-button-success"
        onClick={activeHandler}
        icon={
          notification && notification.status === "pending"
            ? "pi pi-spin pi-spinner"
            : "pi pi-check"
        }
        disabled={notification && notification.status === "pending"}
      />
    );
  }
  if (roompersontype.status) {
    if (auth.roles !== undefined && auth.roles.includes("roompersontype.inactive")) {
      activeSuspend = (
        <Button
          rounded
          text
          raised
          style={{ backgroundColor: "#fff", marginLeft: "1%" }}
          label="Suspender"
          className="p-button-warning"
          onClick={inactiveHandler}
          icon={
            notification && notification.status === "pending"
              ? "pi pi-spin pi-spinner"
              : "pi pi-times"
          }
          disabled={notification && notification.status === "pending"}
        />
      );
    }
  }
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  console.log(roompersontype);
  

  return (
    <>
      {(roompersontype.id === undefined || roompersontype.id <= 0) && (
        <>
          <Loading visible={true} />
        </>
      )}
      {(roompersontype.id !== undefined || roompersontype.id > 0) && (
        <Card
          style={{
            backgroundColor: "#183462",
            marginLeft: "0%",
            marginTop: "8px",
            boxShadow: "none",
            width: "98%",
            border: "solid #326fd1",
          }}
        >
          <div
            className="user-info text-center"
            style={{ borderBottom: "solid #fff", paddingBottom: "4px" }}
          >
            <h2
              id="header"
              className="mb-2"
              style={{ color: "#fff", textDecoration: "underline" }}
            >
              {roompersontype.name.toUpperCase()}
            </h2>
            <span
              className="badge bg-label-secondary"
              style={{
                fontSize: "large",
                color: "lightskyblue",
                fontWeight: "bold",
              }}
            >
              Tipo de Habitación
            </span>
          </div>
          <div className="formgrid grid mt-3">
            <div className="field col-12 md:col-3">
              <>
                <label style={{ color: "#fff", fontSize: "large" }}>
                  Congreso:{" "}
                </label>
                <label
                  style={{
                    color: "lightskyblue",
                    marginLeft: "1%",
                    fontSize: "large",
                  }}
                >
                  {" "}
                  {roompersontype.conferences.name}
                </label>
              </>
            </div>
            <div className="field col-12 md:col-3">
              <>
                <label style={{ color: "#fff", fontSize: "large" }}>
                  Precio:{" "}
                </label>
                <label
                  style={{
                    color: "lightskyblue",
                    marginLeft: "1%",
                    fontSize: "large",
                  }}
                >
                  {" "}
                  {USDollar.format(roompersontype.price)}
                </label>
              </>
            </div>
            <div className="field col-12 md:col-12">
              <>
                <label style={{ color: "#fff", fontSize: "large" }}>
                  Descripción:{" "}
                </label>
                <label
                  style={{
                    color: "lightskyblue",
                    marginLeft: "1%",
                    fontSize: "large",
                  }}
                >
                  {" "}
                  {roompersontype.description}
                </label>
              </>
            </div>
            <div className="field col-12 md:col-12 mt-3">
              <Button
                rounded
                text
                raised
                style={{ backgroundColor: "#fff" }}
                label="Regresar"
                icon="pi pi-arrow-left"
                className="p-button-danger"
                onClick={() => {
                  navigate("/roompersontypes");
                }}
              />
              {auth.roles !== undefined &&
                auth.roles.includes("roompersontype.edit") && (
                  <Button
                    rounded
                    text
                    raised
                    style={{ backgroundColor: "#fff", marginLeft: "1%" }}
                    label="Editar"
                    icon="pi pi-file-edit"
                    className="p-button-primary"
                    onClick={() => {
                      navigate(`/roompersontype/edit/${param.roompersontypeId}`);
                    }}
                  />
                )}
              {activeSuspend}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
export default RoomPersonTypeDetail;
