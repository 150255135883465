import { Card } from "primereact/card";
import { useEffect, useState } from "react";

const PaymentDetail = (props) => {
  const orden = props.orden;
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");

  useEffect(() => {
    if (orden.id !== undefined && orden.id > 0) {
      if (
        orden.conference !== undefined &&
        orden.conference !== null &&
        orden.conference.name !== undefined &&
        orden.conference.name !== null &&
        orden.conference.name !== ""
      ) {
        setName(orden.conference.name);
        setStartDate(orden.conference.startDate);
      } else {
        
        setName(orden.course.topic);
        setStartDate(orden.course.startDate);
      }
    }
  }, [orden]);


  return (
    <>
      {orden.id !== undefined && orden.id > 0 && (
        <>
          <div
            className="card flex justify-content-center "
            style={{ marginTop: "10px", backgroundColor: "#142f47" }}
          >
            <Card
              style={{
                paddingBottom: "0px",
                width: "90%",
                border: "none",
                borderRadius: "15px",
                backgroundColor: "#142f47",
              }}
            >
              <div className="formgrid grid">
                <>
                  <div className="field col-12 md:col-6 ">
                    <label style={{ color: "#fff", fontSize: "large" }}>
                      Congreso:
                    </label>
                    <label
                      style={{
                        marginLeft: "1%",
                        fontSize: "large",
                        color: "#F5EED8",
                      }}
                    >
                      {name}
                    </label>
                  </div>

                  <div className="field col-12 md:col-6 ">
                    <label style={{ color: "#fff", fontSize: "large" }}>
                      Fecha de inicio:
                    </label>
                    <label
                      style={{
                        marginLeft: "1%",
                        fontSize: "large",
                        color: "#F5EED8",
                      }}
                    >
                      {" "}
                      {startDate}
                    </label>
                  </div>
                  <div className="field col-12 md:col-6 ">
                    <label style={{ color: "#fff", fontSize: "large" }}>
                      Método de pago:
                    </label>
                    <label
                      style={{
                        marginLeft: "1%",
                        fontSize: "large",
                        color: "#F5EED8",
                      }}
                    >
                      {orden.paymentMethod.name}
                    </label>
                  </div>
                  <div className="field col-12 md:col-6 ">
                    <label style={{ color: "#fff", fontSize: "large" }}>
                      Tipo de orden:
                    </label>
                    <label
                      style={{
                        marginLeft: "1%",
                        fontSize: "large",
                        color: "#F5EED8",
                      }}
                    >
                      {orden.ordenType.name}
                    </label>
                  </div>
                  <div
                    className={`field col-12 md:col-${
                      orden.taxBill ? "4" : 6
                    } `}
                  >
                    <label style={{ color: "#fff", fontSize: "large" }}>
                      Categoría:
                    </label>
                    <label
                      style={{
                        marginLeft: "1%",
                        fontSize: "large",
                        color: "#F5EED8",
                      }}
                    >
                      {orden.category}
                    </label>
                  </div>
                  <div
                    className={`field col-12 md:col-${
                      orden.taxBill ? "4" : 6
                    } `}
                  >
                    <label style={{ color: "#fff", fontSize: "large" }}>
                      Lleva Comprobante?:
                    </label>
                    <label
                      style={{
                        marginLeft: "1%",
                        fontSize: "large",
                        color: orden.taxBill ? "#0FCA07" : "#FF0000",
                      }}
                    >
                      {orden.taxBill ? "Si" : "No"}
                    </label>
                  </div>
                  {orden.taxBill && (
                    <div className="field col-12 md:col-4 ">
                      <label style={{ color: "#fff", fontSize: "large" }}>
                        RNC:
                      </label>
                      <label
                        style={{
                          marginLeft: "1%",
                          fontSize: "large",
                          color: "#F5EED8",
                        }}
                      >
                        {orden.rnc}
                      </label>
                    </div>
                  )}
                  <div className="field col-12 md:col-6 ">
                    <label style={{ color: "#fff", fontSize: "large" }}>
                      Factura a nombre de:
                    </label>
                    <label
                      style={{
                        marginLeft: "1%",
                        fontSize: "large",
                        color: "#F5EED8",
                      }}
                    >
                      {orden.billName}
                    </label>
                  </div>
                  <div className="field col-12 md:col-6 ">
                    <label style={{ color: "#fff", fontSize: "large" }}>
                      Dirección de facturación:
                    </label>
                    <label
                      style={{
                        marginLeft: "1%",
                        fontSize: "large",
                        color: "#F5EED8",
                      }}
                    >
                      {orden.billAddress}
                    </label>
                  </div>
                  <div className="field col-12 md:col-6 ">
                    <label style={{ color: "#fff", fontSize: "large" }}>
                      Creado por:
                    </label>
                    <label
                      style={{
                        marginLeft: "1%",
                        fontSize: "large",
                        color: "#F5EED8",
                      }}
                    >
                      {orden.user.name + " " + orden.user.lastName}
                    </label>
                  </div>
                  <div className="field col-12 md:col-6 ">
                    <label style={{ color: "#fff", fontSize: "large" }}>
                      Email de creación:
                    </label>
                    <label
                      style={{
                        marginLeft: "1%",
                        fontSize: "large",
                        color: "#F5EED8",
                      }}
                    >
                      {orden.user.email}
                    </label>
                  </div>
                </>

                <div className="field col-12 md:col-12 ">{props.buttons}</div>
              </div>
            </Card>
          </div>
        </>
      )}
    </>
  );
};
export default PaymentDetail;
