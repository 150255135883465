import { roomtypeActions } from "./roomtype-slice";
import { uiActions } from "../ui-slice";

export const GetRoomTypes = (email, showNotification, getAll) => {
  return async (dispatch) => {
    if (showNotification) {
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "Cargando inforamción",
          message: "Se esta solicitando la información al servidor.",
        })
      );
    }
    const GetRoomTypesInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/roomtype/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la información solicitada");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetRoomTypesInfo();
      if (response.result.errorCode === "OK") {
        if (getAll) {
          dispatch(
            roomtypeActions.setRoomTypes({
              roomtypes: response.data.map((roomtype) => {
                return {
                  ...roomtype,
                  status: roomtype.status === true ? "Activo" : "Inactivo",
                };
              }),
            })
          );
        } else {
          dispatch(
            roomtypeActions.setRoomTypes({
              roomtypes: response.data
                .filter((roomtype) => roomtype.status === true)
                .map((roomtype) => {
                  return {
                    code: roomtype.id,
                    name: roomtype.name,
                    quantity: roomtype.quantity,
                  };
                }),
            })
          );
        }
        if (showNotification) {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Datos Cargados",
              message: response.result.message,
            })
          );
        }
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          roomtypeActions.setRoomTypes({
            statues: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la información solicitada",
        })
      );
    }
  };
};
export const GetRoomType = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando tipo de habitación",
        message: "Solicitando tipo de habitación al servidor.",
      })
    );
    const getRoomTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/roomtype/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el tipo de habitación");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getRoomTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          roomtypeActions.setRoomType({
            roomtype: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          roomtypeActions.setRoomType({
            roomtype: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el tipo de habitación",
        })
      );
    }
  };
};
export const UpdateRoomType = (roomtype, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando tipo de habitación",
        message: "Solicitando Actualización del tipo de habitación al servidor.",
      })
    );
    const updateRoomTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/roomtype/update`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: roomtype.id,
            Name: roomtype.name,
            Description: roomtype.description,
            Quantity: roomtype.quantity,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el tipo de habitación");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateRoomTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          roomtypeActions.setRoomType({
            roomtype: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          roomtypeActions.setRoomType({
            roomtype: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el tipo de habitación",
        })
      );
    }
  };
};
export const CreateRoomType = (roomtype, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando tipo de habitación",
        message: "Solicitando Creación del tipo de habitación al servidor.",
      })
    );
    const createRoomTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/roomtype/create`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Name: roomtype.name,
            Description: roomtype.description,
            Quantity: roomtype.quantity,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el tipo de habitación");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createRoomTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          roomtypeActions.setRoomType({
            roomtype: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          roomtypeActions.setRoomType({
            roomtype: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el tipo de habitación",
        })
      );
    }
  };
};
export const ActiveRoomType = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Activando tipo de habitación",
        message: "Solicitando activación del tipo de habitación al servidor.",
      })
    );
    const activeRoomTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/roomtype/active`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo activar el tipo de habitación");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await activeRoomTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          roomtypeActions.setRoomType({
            roomtype: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Activado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          roomtypeActions.setRoomType({
            roomtype: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo activar el tipo de habitación",
        })
      );
    }
  };
};
export const InactiveRoomType = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Inactivando tipo de habitación",
        message: "Solicitando activación del tipo de habitación al servidor.",
      })
    );
    const inactiveRoomTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/user/roomtype/inactive`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo inactivar el tipo de habitación");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await inactiveRoomTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          roomtypeActions.setRoomType({
            roomtype: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Inactivado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          roomtypeActions.setRoomType({
            roomtype: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo inactivar el tipo de habitación",
        })
      );
    }
  };
};
