import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetRoomType,
  UpdateRoomType,
} from "../../../store/roomtype-store/roomtype-actions";
import RoomTypeForms from "../../../components/Forms/RoomTypeForms";

const RoomTypeEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const roomtype = useSelector((state) => state.roomtype.roomtype);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (roomtype.id===undefined || roomtype.id === 0) {
      dispatch(GetRoomType(param.roomtypeId, auth.UserInfo.email));
    }
  }, [param.roomtypeId, auth.UserInfo.email, dispatch, roomtype]);

  return (
    <>
      <RoomTypeForms actionRoomType={UpdateRoomType} roomtype={roomtype} />
    </>
  );
};
export default RoomTypeEdit;
