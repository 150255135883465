import { useEffect } from "react";
import { useState } from "react";
import parse from "html-react-parser";

const CourseDetailComponent = (props) => {
  const [speakers, setSpeakers] = useState("");
  console.log(props.course);
  

  useEffect(() => {
    if (props.course.courseTeachers) {
      let speakers = "";
      props.course.courseTeachers.forEach((speaker) => {
        speakers += speaker.name + " " + speaker.lastName + ", ";
      });
      setSpeakers(speakers.slice(0, -2));
    }
  }, [props.course.courseTeachers]);

  return (
    <>
      {props.showtopic && (
        <div
          className="user-info text-center"
          style={{ borderBottom: "solid #000", paddingBottom: "4px" }}
        >
          <h2
            id="header"
            className="mb-2"
            style={{ color: "#000", textDecoration: "underline" }}
          >
            {props.course.topic}
          </h2>
          <span
            className="badge bg-label-secondary"
            style={{
              fontSize: "large",
              fontWeight: "bold",
            }}
          >
            Tema
          </span>
        </div>
      )}
      <div className="formgrid grid">
        {!props.showtopic && (
          <div className="field col-12 md:col-12 mt-3">
            <label style={{ color: "#000", fontSize: "large" }}>Tema:</label>
            <label
              style={{
                marginLeft: "1%",
                fontSize: "large",
                textDecoration: "underline",
              }}
            >
              {props.course.topic}
            </label>
          </div>
        )}
        <div className="field col-12 md:col-6 mt-3">
          <label style={{ color: "#000", fontSize: "large" }}>
            <i
              className="pi pi-microphone text-info"
              style={{ fontSize: "large" }}
            >
              &nbsp;
            </i>
            Instructor(ers):
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            {speakers}
          </label>
        </div>
        <div className="field col-12 md:col-6 mt-3">
          <label style={{ color: "#000", fontSize: "large" }}>
            <i
              className="pi pi-stopwatch text-info"
              style={{ fontSize: "large" }}
            >
              &nbsp;
            </i>
            Duración (hrs):
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            {props.course.duration}
          </label>
        </div>
        <div
          className="field col-12 md:col-1 mt-3"
          style={{ marginLeft: "4%" }}
        >
          <label style={{ color: "#000", fontSize: "large" }}>
            {props.course !== undefined &&
              props.course.courseDays !== undefined &&
              props.course.courseDays.monday && (
                <i
                  className="pi pi-check-circle"
                  style={{ fontSize: "large", color: "#76db9b" }}
                >
                  &nbsp;
                </i>
              )}
            {props.course !== undefined &&
              props.course.courseDays !== undefined &&
              !props.course.courseDays.monday && (
                <i
                  className="pi pi-check-circle text-secundary"
                  style={{ fontSize: "large", color: "#64748b" }}
                >
                  &nbsp;
                </i>
              )}
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            Lunes
          </label>
        </div>
        <div
          className="field col-12 md:col-1 mt-3"
          style={{ marginLeft: "4%" }}
        >
          <label style={{ color: "#000", fontSize: "large" }}>
            {props.course !== undefined &&
              props.course.courseDays !== undefined &&
              props.course.courseDays.tuesday && (
                <i
                  className="pi pi-check-circle"
                  style={{ fontSize: "large", color: "#76db9b" }}
                >
                  &nbsp;
                </i>
              )}
            {props.course !== undefined &&
              props.course.courseDays !== undefined &&
              !props.course.courseDays.tuesday && (
                <i
                  className="pi pi-check-circle text-secundary"
                  style={{ fontSize: "large", color: "#64748b" }}
                >
                  &nbsp;
                </i>
              )}
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            Martes
          </label>
        </div>
        <div
          className="field col-12 md:col-1 mt-3"
          style={{ marginLeft: "4%" }}
        >
          <label style={{ color: "#000", fontSize: "large" }}>
            {props.course !== undefined &&
              props.course.courseDays !== undefined &&
              props.course.courseDays.wednesday && (
                <i
                  className="pi pi-check-circle"
                  style={{ fontSize: "large", color: "#76db9b" }}
                >
                  &nbsp;
                </i>
              )}
            {props.course !== undefined &&
              props.course.courseDays !== undefined &&
              !props.course.courseDays.wednesday && (
                <i
                  className="pi pi-check-circle text-secundary"
                  style={{ fontSize: "large", color: "#64748b" }}
                >
                  &nbsp;
                </i>
              )}
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            Miércoles
          </label>
        </div>
        <div
          className="field col-12 md:col-1 mt-3"
          style={{ marginLeft: "4%" }}
        >
          <label style={{ color: "#000", fontSize: "large" }}>
            {props.course !== undefined &&
              props.course.courseDays !== undefined &&
              props.course.courseDays.thursday && (
                <i
                  className="pi pi-check-circle"
                  style={{ fontSize: "large", color: "#76db9b" }}
                >
                  &nbsp;
                </i>
              )}
            {props.course !== undefined &&
              props.course.courseDays !== undefined &&
              !props.course.courseDays.thursday && (
                <i
                  className="pi pi-check-circle text-secundary"
                  style={{ fontSize: "large", color: "#64748b" }}
                >
                  &nbsp;
                </i>
              )}
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            Jueves
          </label>
        </div>
        <div
          className="field col-12 md:col-1 mt-3"
          style={{ marginLeft: "4%" }}
        >
          <label style={{ color: "#000", fontSize: "large" }}>
            {props.course !== undefined &&
              props.course.courseDays !== undefined &&
              props.course.courseDays.friday && (
                <i
                  className="pi pi-check-circle"
                  style={{ fontSize: "large", color: "#76db9b" }}
                >
                  &nbsp;
                </i>
              )}
            {props.course !== undefined &&
              props.course.courseDays !== undefined &&
              !props.course.courseDays.friday && (
                <i
                  className="pi pi-check-circle text-secundary"
                  style={{ fontSize: "large", color: "#64748b" }}
                >
                  &nbsp;
                </i>
              )}
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            Viernes
          </label>
        </div>
        <div
          className="field col-12 md:col-1 mt-3"
          style={{ marginLeft: "4%" }}
        >
          <label style={{ color: "#000", fontSize: "large" }}>
            {props.course !== undefined &&
              props.course.courseDays !== undefined &&
              props.course.courseDays.saturday && (
                <i
                  className="pi pi-check-circle"
                  style={{ fontSize: "large", color: "#76db9b" }}
                >
                  &nbsp;
                </i>
              )}
            {props.course !== undefined &&
              props.course.courseDays !== undefined &&
              !props.course.courseDays.saturday && (
                <i
                  className="pi pi-check-circle text-secundary"
                  style={{ fontSize: "large", color: "#64748b" }}
                >
                  &nbsp;
                </i>
              )}
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            Sábados
          </label>
        </div>
        <div
          className="field col-12 md:col-1 mt-3"
          style={{ marginLeft: "4%" }}
        >
          <label style={{ color: "#000", fontSize: "large" }}>
            {props.course !== undefined &&
              props.course.courseDays !== undefined &&
              props.course.courseDays.sunday && (
                <i
                  className="pi pi-check-circle"
                  style={{ fontSize: "large", color: "#76db9b" }}
                >
                  &nbsp;
                </i>
              )}
            {props.course !== undefined &&
              props.course.courseDays !== undefined &&
              !props.course.courseDays.sunday && (
                <i
                  className="pi pi-check-circle text-secundary"
                  style={{ fontSize: "large", color: "#64748b" }}
                >
                  &nbsp;
                </i>
              )}
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            Domingo
          </label>
        </div>
        <div className="field col-12 md:col-12 mt-3">
          <label style={{ color: "#000", fontSize: "large" }}>
            Descripción:
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            {props.course.description !== undefined && 
              parse(props.course.description)}
          </label>
        </div>
        <div className="field col-12 md:col-3 mt-3">
          <label style={{ color: "#000", fontSize: "large" }}>
            Fecha de inicio:
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            {props.course.startDate}
          </label>
        </div>
        <div className="field col-12 md:col-3 mt-3">
          <label style={{ color: "#000", fontSize: "large" }}>
            Hora de inicio:
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            {props.course.startTime}
          </label>
        </div>
        <div className="field col-12 md:col-3 mt-3">
          <label style={{ color: "#000", fontSize: "large" }}>Fecha fin:</label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            {props.course.endDate}
          </label>
        </div>
        <div className="field col-12 md:col-3 mt-3">
          <label style={{ color: "#000", fontSize: "large" }}>
            Hora de fin:
          </label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            {props.course.endTime}
          </label>
        </div>
        <div className="field col-12 md:col-3 mt-3">
          <label style={{ color: "#000", fontSize: "large" }}>Precio:</label>
          <label
            style={{
              marginLeft: "1%",
              fontSize: "large",
            }}
          >
            US$ {props.course.price}.00
          </label>
        </div>
        <div className="field col-12 md:col-3 mt-3">
          <strong>Estado:</strong>
          {props.statusCourse}
        </div>
        <div className="field col-12 md:col-12 mt-3">{props.buttons}</div>
      </div>
    </>
  );
};
export default CourseDetailComponent;
