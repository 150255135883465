import { useRef, useEffect, useCallback } from "react";
import { Toast } from "primereact/toast";

const Notification = (props) => {
  const toast = useRef(null);

  const showSuccess = useCallback(() => {
    toast.current.show({
      severity: "success",
      summary: props.title,
      detail: props.message,
      life: 4500,
    });
  }, [props.message, props.title]);

  const showInfo = useCallback(() => {
    toast.current.show({
      severity: "info",
      summary: props.title,
      detail: props.message,
      life: 4500,
    });
  }, [props.message, props.title]);

  const showWarn = useCallback(() => {
    toast.current.show({
      severity: "warn",
      summary: props.title,
      detail: props.message,
      life: 4500,
    });
  }, [props.message, props.title]);

  const showError = useCallback(() => {
    toast.current.show({
      severity: "error",
      summary: props.title,
      detail: props.message,
      life: 4500,
    });
  }, [props.message, props.title]);

  useEffect(() => {
    if (props.status === "pending") {
      showInfo();
    }
    if (props.status === "success") {
      showSuccess();
    }
    if (props.status === "warning") {
      showWarn();
    }
    if (props.status === "error") {
      showError();
    }
  }, [props.status, showError, showInfo, showSuccess, showWarn]);

  return (
    <div className="card flex justify-content-center">
      <Toast ref={toast} />
    </div>
  );
};

export default Notification;
