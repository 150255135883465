import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GetRoomPersonTypes } from "../../../store/roompersontype-store/roompersontype-actions";
import ABADatatable from "../../../components/ABADatatable";
import { roomPersonTypeActions } from "../../../store/roompersontype-store/roompersontype-slice";
import { Button } from "primereact/button";
import { Message } from "primereact/message";

const RoomPersonTypeHomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roomPersonTypes = useSelector(
    (state) => state.roomPersonType.roomPersonTypes
  );
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      auth.roles !== undefined &&
      auth.roles.includes("roompersontype.showAll")
    ) {
      dispatch(GetRoomPersonTypes(auth.UserInfo.email, true, true, 0));
    }
  }, [dispatch, auth]);

  const viewDetailHanlder = (val) => {
    navigate(`/roompersontype/detail/${val.id}`);
  };
  const editHanlder = (val) => {
    navigate(`/roompersontype/edit/${val.id}`);
  };
  const createHanlder = () => {
    dispatch(
      roomPersonTypeActions.setRoomPersonType({
        roomPersonType: {
          id: 0,
          conferences: {},
          name: "",
          description: "",
          status: false,
          required: false,
          price: 0,
        },
      })
    );
    navigate(`/roompersontype/create`);
  };
  const backHanlder = () => {
    navigate(`/`);
  };
  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const buttonEdit = (
      <Button
        label="Editar"
        icon="pi pi-file-edit"
        className="p-button-rounded p-button-success p-button-text"
        onClick={() => {
          editHanlder(rowData);
        }}
      />
    );
    const buttonView = (
      <Button
        label="Ver"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-text"
        onClick={() => {
          viewDetailHanlder(rowData);
        }}
      />
    );
    if (
      auth.roles !== undefined &&
      auth.roles.includes("roompersontype.edit")
    ) {
      buttons.push(buttonEdit);
    }
    if (
      auth.roles !== undefined &&
      auth.roles.includes("roompersontype.view")
    ) {
      buttons.push(buttonView);
    }
    return buttons;
  };
  actions.push(buttons);

  const columns = [
    {
      field: "name",
      header: "Nombre",
      filterMatchMode: "contains",
    },
    {
      header: "Congreso",
      body: (rowData) => {
        return rowData.conferences !== undefined &&
          rowData.conferences.name !== "" ? (
          <Message severity="success" text={rowData.conferences.name} />
        ) : (
          <Message severity="error" text={"Sin Congreso"} />
        );
      },
    },
    {
      header: "Precio",
      filterMatchMode: "contains",
      body: (rowData) => {
        return formatCurrency(rowData.price);
      },
    },

    {
      field: "description",
      header: "Descripción",
      filterMatchMode: "contains",
    },
    {
      field: "status",
      header: "Estado",
      filterMatchMode: "contains",
    },
    {
      
      header: "Requerido",
      body: (rowData) => {
        return rowData.required ? (
          <Message severity="success" text={"Si"} />
        ) : (
          <Message severity="error" text={"No"} />
        );
      },
    },
  ];

  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };
  return (
    <>
      <ABADatatable
        width="20%"
        title="Tipos de personas por Habitación"
        values={roomPersonTypes}
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        Columns={columns}
        actions={actions}
        createButton={{
          label: "Crear tipo de habitación",
          onClick: createHanlder,
          permission: "roompersontype.create",
        }}
        backButton={{
          label: "Regresar",
          onClick: backHanlder,
          permission: "goBack",
        }}
      />
    </>
  );
};
export default RoomPersonTypeHomePage;
