import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";

import {
  DownloadPaymentTransfer,
  RemoveUser,
} from "../../../store/orden-store/orden-actions";

import {
  ActiveCourse,
  InactiveCourse,
  GetCourseAndOrdens,
} from "../../../store/courses-store/course-actions";

import Loading from "../../../components/UI/Loading";
import { Accordion, AccordionTab } from "primereact/accordion";
import ABADatatableExpandable from "../../../components/ABADatatableExpandable";
import { ValidateCourseDiscountByCode } from "../../../store/discount-store/discount-actions";
import { arregloPaises } from "../../../components/List";
import {
  DownloadCertificate,
  SendAgain,
} from "../../../store/user-store/user-actions";
import {
  getBalanceReport,
  getListUserCourseReport,
} from "../../../utils/Reports";
import { userActions } from "../../../store/user-store/user-slice";

import CourseDetailComponent from "../../../components/Capacitaciones/CourseDetailComponent";
import OrdenDetailComponent from "../../../components/Orden/OrdenDetailComponent";
import NewQuoteCourseComponent from "../../../components/Orden/NewQuoteCourseComponent";
import NewOrderCourseComponent from "../../../components/Orden/NewOrderCourseComponent";
import StatusCourseComponent from "../../../components/Orden/StatusCourseComponent";
import SendBiillComponent from "../../../components/Orden/SendBillComponent";
import AddUserComponent from "../../../components/Orden/AddUserComponent";
import PaymentMethodCourseComponent from "../../../components/Orden/PaymentMethodCourseComponent";
import UserViewComponent from "../../../components/Orden/UserViewComponent";
import DiscountComponent from "../../../components/Orden/DiscountComponent";
import TaxBillComponent from "../../../components/Orden/TaxBillComponent";


const CourseDetail = () => {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const course = useSelector((state) => state.course.course);

  const notification = useSelector((state) => state.ui.notification);

  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);

  const [visibleNewOrden, setVisibleNewOrden] = useState(false);
  const [visibleOrdenDetail, setVisibleOrdenDetail] = useState(false);
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [visibleNewQuote, setVisibleNewQuote] = useState(false);
  const [visibleSendBill, setVisibleSendBill] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [refresh, setRefresh] = useState(true);
  const [statusFilter, setStatusFilter] = useState(null);
  const [visibleUser, setVisibleUser] = useState(false);
  const [visibleUserView, setVisibleUserView] = useState(false);
  const [visiblePaymentMethod, setVisiblePaymentMethod] = useState(false);
  const [visibleDiscountCode, setVisiblDiscountCode] = useState(false);
  const [visibleCertificate, setVisibleCertificate] = useState(false);
  const [visibleAttended, setVisibleAttended] = useState(false);

  const [visibleTaxBill, setVisibleTaxBill] = useState(false);

  const [totalSubscribed, setTotalSubscribed] = useState(0);
  const [totalBillSent, setTotalBillSent] = useState(0);
  const [totalBillNoSent, setTotalBillNoSent] = useState(0);
  const [userId, setUserId] = useState(0);
  const [users, setUsers] = useState([]);

  const [orden, setOrden] = useState({
    course: { id: parseInt(param.courseId) },
    ordenType: { id: 2 },
    paymentMethod: { id: 1 },
    status: { id: 1 },
    amount: 0,
    discountCode: "",
    Category: "",
    taxBill: false,
    RNC: "",
    users: [],
  });
  const [ordenId, setOrdenId] = useState(0);
  const [updateOrden, setUpdateOrden] = useState({
    id: 0,
    paymentMethod: { id: 0, name: "" },
    status: {
      id: 0,
      description: "",
      color: "",
    },
  });

  useEffect(() => {
    if (course && (course.id === 0 || course.id === undefined)) {
      dispatch(GetCourseAndOrdens(param.courseId, auth.UserInfo.email));
      setRefresh(false);
    }
  }, [auth.UserInfo.email, course, dispatch, param.courseId]);

  useEffect(() => {
    if (
      orden.discountCode !== undefined &&
      orden.discountCode !== "" &&
      visibleNewOrden === true
    ) {
      dispatch(
        ValidateCourseDiscountByCode(
          orden.discountCode,
          parseInt(param.courseId),
          orden.users.length,
          auth.UserInfo.email
        )
      );
    }
  }, [
    auth.UserInfo.email,
    dispatch,
    visibleNewOrden,
    orden.discountCode,
    orden.users.length,
    param.courseId,
  ]);

  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  useEffect(() => {
    if (course && course.id > 0) {
      if (course.startDate !== undefined && course.startDate !== "") {
        setStartDate(
          new Date(course.startDate + "T01:00:00").toLocaleDateString(
            "en-US",
            options
          )
        );
      }
      if (course.endDate !== undefined && course.endDate !== "") {
        setEndDate(
          new Date(course.endDate + "T01:00:00").toLocaleDateString(
            "en-US",
            options
          )
        );
      }
    }
  }, [course, options]);

  // let startDate = new Date().toLocaleDateString("en-US", options);
  // if (course.startDate !== "" || course.startDate !== undefined) {
  //   startDate = new Date(course.startDate + "T01:00:00").toLocaleDateString(
  //     "en-US",
  //     options
  //   );
  // }
  // let endDate = new Date().toLocaleDateString("en-US", options);
  // if (course.endDate !== "" || course.endDate !== undefined) {
  //   endDate = new Date(course.endDate + "T01:00:00").toLocaleDateString(
  //     "en-US",
  //     options
  //   );
  // }

  const activeHandler = () => {
    dispatch(ActiveCourse(param.courseId, auth.UserInfo.email));
  };
  const inactiveHandler = () => {
    dispatch(InactiveCourse(param.courseId, auth.UserInfo.email));
  };

  let statusCourse = (
    <>
      <span className="fw-bold me-2" style={{ color: "#fff" }}>
        Estatus:&nbsp;
      </span>
      <Tag
        value="Inactivo"
        severity={"warning"}
        style={{ fontSize: "medium" }}
      ></Tag>
    </>
  );
  let activeSuspend;
  if (auth.roles !== undefined && auth.roles.includes("course.active")) {
    activeSuspend = (
      <Button
        label="Activar"
        className="p-button-success"
        style={{ marginLeft: "1%" }}
        onClick={activeHandler}
        icon={
          notification && notification.course === "pending"
            ? "pi pi-spin pi-spinner"
            : "pi pi-check"
        }
        disabled={notification && notification.course === "pending"}
      />
    );
  }

  if (course.status) {
    statusCourse = (
      <>
        <span className="fw-bold me-2" style={{ color: "#fff" }}>
          Estatus:
        </span>
        <Tag
          value="Activo"
          severity={"success"}
          style={{ fontSize: "medium" }}
        ></Tag>
      </>
    );
    if (auth.roles !== undefined && auth.roles.includes("course.inactive")) {
      activeSuspend = (
        <Button
          label="Suspender"
          className="p-button-warning"
          style={{ marginLeft: "1%" }}
          onClick={inactiveHandler}
          icon={
            notification && notification.course === "pending"
              ? "pi pi-spin pi-spinner"
              : "pi pi-ban"
          }
          disabled={notification && notification.course === "pending"}
        />
      );
    }
  }

  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const paises = arregloPaises.map((pais) => ({
    code: pais.nombre,
    name: pais.nombre,
    flag: pais.abreviatura,
  }));
  /************************  Informacion de las columnas*/
  const OrdeIdTemplate = (orden) => {
    const newOrden = {
      ...orden,
      course: {
        ...course,
      },
    };

    const button = (
      <Button
        link
        label={orden.id}
        onClick={() => {
          setVisibleOrdenDetail(true);
          setOrden(newOrden);
        }}
      />
    );
    return button;
  };
  const StatusOrdenTemplate = (orden) => {
    if (
      orden.status.id === 10 &&
      auth.roles.includes("course.transferRecipt")
    ) {
      return (
        <Button
          icon="pi pi-download"
          rounded
          outlined
          label="Comprobante"
          severity="primary"
          tooltip="Descargar Comprobante"
          style={{
            width: "100%",
            height: "35px",
            marginTop: "2px",
            marginLeft: "2px",
          }}
          onClick={() => {
            dispatch(DownloadPaymentTransfer(orden.id, auth.UserInfo.email));
          }}
        />
      );
    }
    return (
      <span style={{ color: orden.status.color }}>
        {orden.status.description}
      </span>
    );
  };

  const TotalUserOrdenTemplate = (orden) => {
    let quantity = orden.orders.length;

    if (orden.status.id === 9) {
      quantity = orden.quantityOfUsers;
    }

    return <span>{quantity}</span>;
  };

  const amountWithTemplate = (orden) => {
    const discount = orden.discount;
    let quantity = orden.orders.length;
    if (
      orden.status.id === 9 &&
      orden.quantityOfUsers === orden.orders.length
    ) {
      quantity = orden.quantityOfUsers;
    } else if (
      orden.status.id === 9 &&
      orden.quantityOfUsers > orden.orders.length
    ) {
      quantity = orden.quantityOfUsers;
    }

    let discountValue = 0;
    if (discount !== undefined && discount.id !== 0) {
      if (discount.isValidDiscountCode) {
        if (discount.nominal) {
          discountValue = discount.discountNominal * quantity;
        }
        if (discount.percentage) {
          discountValue =
            course.price * (discount.discountPercentage / 100) * quantity;
        }
      }
    }
    return USDollar.format(course.price * quantity - discountValue);
  };
  const amountPerPeopleWithTemplate = (orden) => {
    const discount = orden.discount;

    let discountValue = 0;

    if (
      discount !== undefined &&
      discount.id !== 0 &&
      discount.isValidDiscountCode
    ) {
      if (discount.nominal) {
        discountValue = discount.discountNominal;
      }
      if (discount.percentage) {
        discountValue = course.price * (discount.discountPercentage / 100);
      }
    }
    return USDollar.format(course.price - discountValue);
  };
  const amountWithOutTemplate = () => {
    return USDollar.format(course.price);
  };
  const usersTemplate = (order) => {
    if (
      order.participantsNames !== undefined &&
      order.participantsNames.length > 0
    ) {
      return (
        <>
          {order.participantsNames.split(",").map((fullName) => {
            return (
              <span>
                {fullName}
                <br />
              </span>
            );
          })}
        </>
      );
    }
    return <span></span>;
  };
  const buttonsDetail = (
    <>
      {auth.roles !== undefined && auth.roles.includes("course.edit") && (
        <Button
          label="Editar"
          icon="pi pi-file-edit"
          className="p-button-primary"
          style={{ marginLeft: "1%" }}
          onClick={() => {
            navigate(`/course/edit/${param.courseId}`);
          }}
        />
      )}
      {activeSuspend}
      <Button
        label="Regresar"
        icon="pi pi-arrow-left"
        className="p-button-danger"
        style={{ marginLeft: "1%" }}
        onClick={() => {
          navigate("/admin/courses");
        }}
      />
    </>
  );
  const columnsOrders = [
    {
      field: "id",
      header: "Id",
      filter: true,
      sortable: false,
      body: (orden) => {
        return OrdeIdTemplate(orden);
      },
    },
    {
      field: "status.description",
      header: "Estado",
      filter: false,
      sortable: false,
      body: (orden) => {
        return StatusOrdenTemplate(orden);
      },
    },

    {
      header: "Cantidad",
      filter: false,
      sortable: false,
      body: (orden) => {
        return TotalUserOrdenTemplate(orden);
      },
    },
    {
      field: "course.price",
      header: "Precio",
      filter: false,
      sortable: false,
      body: (order) => {
        return amountWithOutTemplate(order);
      },
    },
    {
      header: "Precio con descuento",
      filter: false,
      sortable: false,
      body: (order) => {
        return amountPerPeopleWithTemplate(order);
      },
    },
    {
      field: "participantsNames",
      header: "Participantes",
      filter: true,
      sortable: false,
      body: (order) => {
        return usersTemplate(order);
      },
    },
    {
      field: "discount.discountCode",
      header: "Código",
      filter: false,
      sortable: false,
    },
    {
      header: "Total",
      filter: false,
      sortable: false,
      body: (orden) => {
        return amountWithTemplate(orden);
      },
    },

    {
      field: "billSend",
      header: "Factura Enviada",
      filter: false,
      sortable: false,
      body: (orden) => {
        return (
          <span>
            {orden.billSend !== undefined && orden.billSend === true
              ? "Si"
              : "No"}
          </span>
        );
      },
    },
  ];
  const columExpandable = [
    {
      field: "user.name",
      header: "Nombre",
      filterMatchMode: "contains",
    },
    {
      field: "user.lastName",
      header: "Apellido",
      filterMatchMode: "contains",
    },
    {
      field: "user.email",
      header: "Correo",
      filterMatchMode: "contains",
    },
    {
      field: "user.country",
      header: "País",
      filterMatchMode: "contains",
      body: (orden) => {
        const pais = paises.find((pais) => pais.name === orden.user.country);
        if (pais === undefined) {
          return <div></div>;
        }
        return (
          <div className="flex align-items-center" style={{ width: "100px" }}>
            <img
              alt={pais.name}
              src={`https://flagcdn.com/48x36/${pais.flag.toLowerCase()}.png`}
              style={{ width: "18px" }}
            />
            <div style={{ marginLeft: "5%" }}>{pais.name}</div>
          </div>
        );
      },
    },
  ];
  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const addUser = (
      <Button
        icon="pi pi-user-plus"
        rounded
        outlined
        label="Agregar"
        severity="primary"
        tooltip="Agregar Participante"
        style={{
          width: "115px",
          height: "35px",
          marginTop: "2px",
          marginLeft: "2px",
        }}
        onClick={() => {
          setOrdenId(rowData.id);
          setVisibleUser(true);
        }}
      />
    );

    const ChangeStatus = (
      <Button
        rounded
        outlined
        label="Estado"
        icon="pi pi-qrcode"
        severity="help"
        tooltip="Cambiar estado"
        style={{
          width: "115px",
          height: "35px",
          marginTop: "2px",
          marginLeft: "2px",
        }}
        onClick={() => {
          setVisibleStatus(true);
          setUpdateOrden({
            id: rowData.id,
            status: rowData.status,
            paymentMethod: rowData.paymentMethod,
          });
        }}
      />
    );
    const ChangePaymentMethod = (
      <Button
        rounded
        outlined
        label="Método"
        icon="pi pi-credit-card"
        severity="danger"
        style={{
          width: "115px",
          height: "35px",
          marginTop: "2px",
          marginLeft: "2px",
        }}
        tooltip="Cambiar Método de Pago"
        onClick={() => {
          setVisiblePaymentMethod(true);
          setUpdateOrden({
            id: rowData.id,
            paymentMethod: rowData.paymentMethod,
            status: rowData.status,
          });
        }}
      />
    );
    const ChangeDiscountMethod = (
      <Button
        rounded
        outlined
        label="Código"
        icon="pi pi-qrcode"
        severity="success"
        style={{
          width: "115px",
          height: "35px",
          marginTop: "2px",
          marginLeft: "2px",
        }}
        tooltip="Cambiar Codígo de Descuento"
        onClick={() => {
          setVisiblDiscountCode(true);
          setUpdateOrden({
            id: rowData.id,
            paymentMethod: rowData.paymentMethod,
            status: rowData.status,
          });
        }}
      />
    );
    const ChangeTaxBill = (
      <Button
        rounded
        outlined
        label="RNC"
        icon="pi pi-credit-card"
        severity="warning"
        style={{
          width: "115px",
          height: "35px",
          marginTop: "2px",
          marginLeft: "2px",
        }}
        tooltip="Cambiar RNC"
        onClick={() => {
          setVisibleTaxBill(true);
          setUpdateOrden({
            id: rowData.id,
            paymentMethod: rowData.paymentMethod,
            status: rowData.status,
            taxBill: rowData.taxBill,
            rnc: rowData.rnc,
          });
        }}
      />
    );

    const SendBiill = (
      <Button
        rounded
        outlined
        label="Factura"
        icon="pi pi-file-pdf"
        severity="secondary"
        style={{
          width: "115px",
          height: "35px",
          marginTop: "2px",
          marginLeft: "2px",
        }}
        tooltip="Enviar factura"
        onClick={() => {
          setVisibleSendBill(true);
          setOrden(rowData);
        }}
      />
    );

    if (
      rowData.status.id !== 2 &&
      rowData.status.id !== 7 &&
      rowData.status.id !== 9 &&
      auth.roles !== undefined &&
      auth.roles.includes("course.addUser")
    ) {
      buttons.push(addUser);
    }

    if (
      rowData.status.id !== 2 &&
      rowData.status.id !== 7 &&
      auth.roles !== undefined &&
      auth.roles.includes("course.changeStatus")
    ) {
      buttons.push(ChangeStatus);
    }
    if (
      rowData.status.id !== 2 &&
      rowData.status.id !== 7 &&
      rowData.status.id !== 9 &&
      auth.roles !== undefined &&
      auth.roles.includes("course.changePaymentMethod")
    ) {
      buttons.push(ChangePaymentMethod);
    }
    if (
      rowData.status.id !== 2 &&
      rowData.status.id !== 7 &&
      rowData.status.id !== 9 &&
      auth.roles !== undefined &&
      auth.roles.includes("course.changeDiscountMethod")
    ) {
      buttons.push(ChangeDiscountMethod);
    }
    if (
      rowData.status.id !== 2 &&
      rowData.status.id !== 7 &&
      rowData.status.id !== 9 &&
      auth.roles !== undefined &&
      auth.roles.includes("course.TaxBill")
    ) {
      buttons.push(ChangeTaxBill);
    }

    if (
      (rowData.status.id === 2 ||
        rowData.status.id === 18 ||
        rowData.status.id === 5 ||
        rowData.status.id === 10) &&
      auth.roles !== undefined &&
      auth.roles.includes("course.sendBill")
    ) {
      buttons.push(SendBiill);
    }

    return buttons;
  };
  actions.push(buttons);

  let actionsExpandable = [];
  const buttonsExpandable = (rowData) => {
    let buttons = [];
    const RemoveUserButton = (
      <>
        {refresh === true && (
          <>
            <Loading visible={refresh} />{" "}
          </>
        )}
        {refresh === false && (
          <>
            <Button
              label="Remover"
              icon="pi pi-times"
              className="p-button-rounded p-button-danger p-button-text"
              onClick={() => {
                dispatch(
                  RemoveUser(
                    rowData.id,
                    rowData.user.id,
                    course.price,
                    auth.UserInfo.email
                  )
                );
                setRefresh(true);
                setTimeout(() => {
                  window.location.reload();
                }, 2500);
              }}
            />
          </>
        )}
      </>
    );
    const SendCertifcate = (
      <>
        {refresh === true && (
          <>
            <Loading visible={refresh} />
          </>
        )}
        {refresh === false && (
          <Button
            label="Enviar Certificado"
            icon="pi pi-envelope"
            className="p-button-rounded p-button-primary p-button-text"
            onClick={() => {
              dispatch(
                SendAgain(rowData.user.id, rowData.id, auth.UserInfo.email)
              );
              setRefresh(true);
              setTimeout(() => {
                window.location.reload();
              }, 2500);
            }}
          />
        )}
        {refresh === false && (
          <>
            <Button
              label="Descargar Certificado"
              icon="pi pi-download"
              className="p-button-rounded p-button-help p-button-text"
              onClick={() => {
                dispatch(
                  DownloadCertificate(
                    rowData.user.id,
                    rowData.id,
                    auth.UserInfo.email
                  )
                );
              }}
            />
          </>
        )}
      </>
    );
    const ViewUser = (
      <Button
        label="Ver usuario"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-help p-button-text"
        onClick={() => {
          dispatch(userActions.restartState());
          setVisibleUserView(true);
          setUserId(rowData.user.id);
        }}
      />
    );
    buttons.push(ViewUser);

    const count = course.orders.find((x) => x.id === rowData.id).orders.length;
    if (rowData.status.id !== 2 && rowData.status.id !== 7 && count > 1) {
      buttons.push(RemoveUserButton);
    }
    if (rowData.user.certificateSent === true) {
      buttons.push(SendCertifcate);
    }
    return buttons;
  };
  actionsExpandable.push(buttonsExpandable);

  const actionsTable = (
    <>
      <div className="formgrid grid">
        {auth.roles !== undefined && auth.roles.includes("course.report") && (
          <>
            <div className="field col-12 md:col-2">
              <Button
                label="Ver Pagos"
                rounded
                text
                raised
                icon="pi pi-money-bill"
                className="p-button-help"
                style={{ width: "180px" }}
                onClick={async () => {
                  await getBalanceReport(course);
                }}
              />
            </div>
            <div className="field col-12 md:col-2">
              <Button
                label="Lista de usuarios"
                rounded
                text
                raised
                icon="pi pi-list"
                className="p-button-success"
                style={{ width: "180px" }}
                onClick={async () => {
                  await getListUserCourseReport(course);
                }}
              />
            </div>
            {auth.roles !== undefined &&
              auth.roles.includes("course.sendCertificate") && (
                <div className="field col-12 md:col-2">
                  <Button
                    label="Certificados"
                    rounded
                    text
                    raised
                    icon="pi pi-list"
                    className="p-button-info"
                    style={{ width: "180px" }}
                    onClick={async () => {
                      setVisibleCertificate(true);
                    }}
                  />
                </div>
              )}
          </>
        )}
        {auth.roles !== undefined &&
          auth.roles.includes("course.createOrden") && (
            <div className="field col-12 md:col-2">
              <Button
                label="Crear Orden"
                rounded
                text
                raised
                icon="pi pi-box"
                className="p-button-warning"
                style={{ width: "180px" }}
                onClick={() => {
                  setVisibleNewOrden(true);
                }}
              />
            </div>
          )}
        {auth.roles !== undefined && auth.roles.includes("course.quote") && (
          <div className="field col-12 md:col-2">
            <Button
              rounded
              text
              raised
              label="Cotizar"
              icon="pi pi-money-bill"
              severity="help"
              style={{ width: "180px" }}
              onClick={() => setVisibleNewQuote(true)}
            />
          </div>
        )}
        <div className="field col-12 md:col-2">
          <Button
            label="Asistencia"
            rounded
            text
            raised
            icon="pi pi-user-edit"
            className="p-button-info"
            style={{ width: "180px" }}
            onClick={async () => {
              setVisibleAttended(true);
            }}
          />
        </div>

        {auth.roles !== undefined &&
          auth.roles.includes("courseEvent.menu") && (
            <div className="field col-12 md:col-2">
              <Button
                rounded
                text
                raised
                label="Conferencias"
                icon="pi pi-calendar-plus"
                style={{
                  width: "180px",
                  color: "salmon",
                }}
                onClick={() => {
                  navigate(`/course/events/${param.courseId}`);
                }}
              />
            </div>
          )}
      </div>
    </>
  );

  const subtitle = () => {
    let status = [];
    for (const element of course.orders) {
      if (status.find((x) => x.id === element.status.id) === undefined) {
        const statusElement = {
          id: element.status.id,
          description: element.status.description,
          count: 1,
          color: element.status.color,
        };
        status.push(statusElement);
      } else {
        const found = status.find((x) => x.id === element.status.id);
        let quantity = element.orders.length;
        if (quantity === 1) {
          quantity++;
        }
        found.count += element.orders.length;
        status = [...status.filter((x) => x.id !== element.status.id), found];
      }
    }
  };

  return (
    <>
      {course && (course.id === undefined || course.id === 0) && (
        <>
          <Loading visible={true} />
        </>
      )}

      {course && course.id > 0 && !refresh && (
        <>
          <div className="card" style={{ width: "97.1%" }}>
            <Accordion activeIndex={1}>
              <AccordionTab header="Información General">
                <Card
                  style={{
                    backgroundColor: "#0d3c61",
                    marginLeft: "0%",
                    marginTop: "8px",
                    paddingBottom: "10px",
                    boxShadow: "none",
                    width: "98%",
                    border: "solid #326fd1",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#fff",
                      paddingBottom: "80px",
                      paddingLeft: "10px",
                      boxShadow: "none",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <CourseDetailComponent
                      course={course}
                      buttons={buttonsDetail}
                      startDate={startDate}
                      endDate={endDate}
                      statusCourse={statusCourse}
                      showtopic={true}
                    />
                  </div>
                </Card>
              </AccordionTab>
              <AccordionTab header="Ordenes">
                <ABADatatableExpandable
                  filter={statusFilter}
                  title=""
                  subtitle={subtitle}
                  values={course.orders}
                  rows={100}
                  rowsPerPageOptions={[10, 30, 50]}
                  Columns={columnsOrders}
                  columExpandable={columExpandable}
                  width="8%"
                  actions={actions}
                  actionsTable={actionsTable}
                  actionsExpandable={actionsExpandable}
                />
                <OrdenDetailComponent
                  orden={orden}
                  visibleOrdenDetail={visibleOrdenDetail}
                  setVisibleOrdenDetail={setVisibleOrdenDetail}
                />
                <NewQuoteCourseComponent
                  ordenTypeId={2}
                  course={course}
                  setVisibleNewQuote={setVisibleNewQuote}
                  visibleNewQuote={visibleNewQuote}
                />
                <NewOrderCourseComponent
                  visibleNewOrden={visibleNewOrden}
                  setVisibleNewOrden={setVisibleNewOrden}
                />
                <StatusCourseComponent
                  course={course}
                  updateOrden={updateOrden}
                  visibleStatus={visibleStatus}
                  setVisibleStatus={setVisibleStatus}
                />
                <SendBiillComponent
                  orden={orden}
                  visibleSendBill={visibleSendBill}
                  setVisibleSendBill={setVisibleSendBill}
                />
                <AddUserComponent
                  orden={orden}
                  ordenId={ordenId}
                  visibleUser={visibleUser}
                  setVisibleUser={setVisibleUser}
                />
                <PaymentMethodCourseComponent
                  course={course}
                  updateOrden={updateOrden}
                  ƒ
                  visiblePaymentMethod={visiblePaymentMethod}
                  setVisiblePaymentMethod={setVisiblePaymentMethod}
                />

                <UserViewComponent
                  visibleUserView={visibleUserView}
                  setVisibleUserView={setVisibleUserView}
                  userId={userId}
                />
                <DiscountComponent
                  orden={orden}
                  updateOrden={updateOrden}
                  visibleDiscountCode={visibleDiscountCode}
                  setVisiblDiscountCode={setVisiblDiscountCode}
                />
                 <TaxBillComponent
                  updateOrden={updateOrden}
                  setUpdateOrden={setUpdateOrden}
                  visibleTaxBill={visibleTaxBill}
                  setVisibleTaxBill={setVisibleTaxBill}
                />
                {/* <CertificatesComponent 
                  course={course}
                  setRefresh={setRefresh}
                  visibleCertificate={visibleCertificate}
                  setVisibleCertificate={setVisibleCertificate}
                />
                <AttendedComponent
                  users={users}
                  visibleAttended={visibleAttended}
                  setVisibleAttended={setVisibleAttended}
                />
                */}
              </AccordionTab>
            </Accordion>
          </div>
        </>
      )}
    </>
  );
};
export default CourseDetail;
