import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "primereact/card";
import { GetRoomTypes } from "../../../store/roomtype-store/roomtype-actions";
import { GetRoomPersonTypes } from "../../../store/roompersontype-store/roompersontype-actions";
import {
  ConfigConference,
  GetConferenceById,
  RemoveConfigConference,
} from "../../../store/conferences-store/conference-actions";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { uiActions } from "../../../store/ui-slice";
import { Message } from "primereact/message";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

const ConferencesConfigRooms = () => {
  const auth = useSelector((state) => state.auth);
  const notification = useSelector((state) => state.ui.notification);
  const conference = useSelector((state) => state.conference.conference);

  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roomtypes = useSelector((state) => state.roomtype.roomtypes);
  const roompersontypes = useSelector(
    (state) => state.roomPersonType.roomPersonTypes
  );

  const [selectRoomType, setSelectRoomType] = useState(null);
  const [selectRoomPersonType, setSelectRoomPersonType] = useState(null);
  const [roomConfigList, setRoomConfigList] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [expandedRows, setExpandedRows] = useState([]);
  const toast = useRef(null);

  useEffect(() => {
    if (conference.id === undefined || conference.id === 0) {
      dispatch(GetConferenceById(param.conferenceId, auth.UserInfo.email));
    }
  }, [auth.UserInfo.email, conference.id, dispatch, param.conferenceId]);

  useEffect(() => {
    if (
      conference.roomTypes !== undefined &&
      conference.roomTypes !== null &&
      conference.roomTypes.length > 0 &&
      roomConfigList.length === 0
    ) {
      const roomtypes = conference.roomTypes.map((room) => {
        return {
          ...room,
          roompersontypes: room.roomPersonTypes,
        };
      });

      setRoomConfigList(roomtypes);
    }
  }, [conference.roomTypes, roomConfigList]);

  useEffect(() => {
    if (
      roomtypes !== null &&
      roomtypes !== undefined &&
      roomtypes.length === 0
    ) {
      dispatch(GetRoomTypes(auth.UserInfo.email, false, false));
    }
    if (
      roompersontypes !== null &&
      roompersontypes !== undefined &&
      roompersontypes.length === 0
    ) {
      dispatch(
        GetRoomPersonTypes(
          auth.UserInfo.email,
          false,
          false,
          param.conferenceId
        )
      );
    }
  }, [
    auth.UserInfo.email,
    dispatch,
    param.conferenceId,
    roompersontypes,
    roomtypes,
  ]);

  const onChangeHandler = (id, value) => {
    if (id === "roomtype") {
      setSelectRoomType(value);
    }
    if (id === "roompersontype") {
      setSelectRoomPersonType(value);
    }
    setIsValid(true);
  };
  const addTolistHandler = () => {
    let roomConfigs = [...roomConfigList];
    if (selectRoomType !== null && selectRoomPersonType !== null) {
      let count = 0;
      let roomTypeConfig = roomConfigs.filter(
        (room) => room.code === selectRoomType.code
      )[0];

      if (roomTypeConfig !== undefined) {
        count = roomTypeConfig.roompersontypes.length;
      }
      if (count >= selectRoomType.quantity) {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message:
              "La cantidad de personas excede el límite de la habitación",
          })
        );
        setIsValid(false);
      } else {
        let roomTypeConfig = roomConfigs.filter(
          (room) => room.code === selectRoomType.code
        )[0];
        let roomPersonTypes = [];
        let id = 0;
        if (
          roomTypeConfig === undefined ||
          roomTypeConfig.roompersontypes.length === 0
        ) {
          id = 1;
        } else {
          id =
            roomTypeConfig.roompersontypes[
              roomTypeConfig.roompersontypes.length - 1
            ].id + 1;
        }
        if (roomTypeConfig === undefined) {
          roomPersonTypes.push({
            ...selectRoomPersonType,
            id: id,
          });
          roomTypeConfig = {
            id: selectRoomType.code,
            code: selectRoomType.code,
            configId: 0,
            name: selectRoomType.name,
            quantity: selectRoomType.quantity,
            roompersontypes: roomPersonTypes,
          };
          roomConfigs.push({
            ...roomTypeConfig,
          });
        } else {
          let roompersontypes = [...roomTypeConfig.roompersontypes];

          roompersontypes.push({
            ...selectRoomPersonType,
            id: id,
          });
          roomTypeConfig.roompersontypes = roompersontypes;
          var index = roomConfigs
            .map((x) => {
              return x.code;
            })
            .indexOf(selectRoomType.code);

          roomConfigs.splice(index, 1);
          roomConfigs.push({
            ...roomTypeConfig,
          });
        }

        setRoomConfigList(roomConfigs);
      }
    }
  };
  const removeRoomPersonType = (roomTypeCode, roomPersonTypeCode) => {
    let roomConfigs = [...roomConfigList];
    console.log(roomConfigs);
    let roomTypeConfig = roomConfigs.filter(
      (room) => room.code === roomTypeCode
    )[0];

    let roomPersonTypeConfig = roomTypeConfig.roompersontypes.filter(
      (roomPersonType) => roomPersonType.id !== roomPersonTypeCode
    );

    roomTypeConfig.roompersontypes = roomPersonTypeConfig;
    var index = roomConfigs
      .map((x) => {
        return x.code;
      })
      .indexOf(roomTypeCode);

    roomConfigs.splice(index, 1);
    roomConfigs.push({
      ...roomTypeConfig,
    });
    setRoomConfigList(roomConfigs);
  };
  const removeRoomPersonTypeRemote = (roomConfigId) => {
    dispatch(RemoveConfigConference(roomConfigId, auth.UserInfo.email));
    setTimeout(() => {
      window.location.reload();
    }, 2500);
  };

  const removeRoomType = (roomTypeCode) => {
    let roomConfigs = [...roomConfigList];
    let roomTypeConfig = roomConfigs.filter(
      (room) => room.code !== roomTypeCode
    );
    setRoomConfigList(roomTypeConfig);
  };

  const getFormErrorMessage = () => {
    return !isValid ? (
      <Message
        style={{ width: "100%" }}
        severity="warn"
        text="La cantidad de personas excede el límite de la habitación"
      />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };
  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const allowExpansion = (rowData) => {
    return rowData.roompersontypes.length > 0;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3" style={{ backgroundColor: "#103C61" }}>
        <h1 style={{ color: "#ffffff" }}>Personas para {data.name}</h1>
        <DataTable value={data.roompersontypes}>
          <Column field="name" header="Tipo persona" sortable></Column>
          <Column
            header="Precio"
            sortable
            body={(rowData) => {
              return formatCurrency(rowData.price);
            }}
          ></Column>
          <Column
            body={(rowData) => {
              return (
                <Button
                  rounded
                  text
                  raised
                  type="button"
                  style={{ backgroundColor: "#fff" }}
                  icon="pi pi-trash"
                  className="p-button-danger"
                  onClick={() => {
                    rowData.configId === 0
                      ? removeRoomPersonType(data.code, rowData.id)
                      : removeRoomPersonTypeRemote(data.configId, rowData.id);
                  }}
                />
              );
            }}
          ></Column>
        </DataTable>
      </div>
    );
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    if (roomConfigList.length === 0) {
      dispatch(
        uiActions.showNotification({
          status: "warning",
          title: "Advertencia",
          message: "Debe agregar al menos una habitación",
        })
      );
    } else {
      const newConference = {
        id: conference.id,
        roomtypes: roomConfigList,
      };
      dispatch(ConfigConference(newConference, auth.UserInfo.email));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return (
    <>
      <Card
        style={{
          backgroundColor: "#142f47",

          boxShadow: "none",
          height: "100%",
          width: "99%",
        }}
      >
        <div className="mb-4 bg-blue-900 p-3">
          <h2 style={{ color: "#fff" }}>
            Congreso:
            <span style={{ color: "#35c4dc", marginLeft: "1%" }}>
              {conference && conference.name && conference.name.toUpperCase()}
            </span>
          </h2>
          <form onSubmit={onSubmitHandler}>
            <div className="formgrid grid">
              {roomtypes.length > 0 && (
                <div className="field col-12 md:col-4">
                  <label
                    htmlFor="company"
                    style={{ fontSize: "large", color: "#35c4dc" }}
                  >
                    Tipo de Habitación:
                  </label>
                  <Dropdown
                    id="roomtype"
                    value={selectRoomType}
                    onChange={(e) => onChangeHandler("roomtype", e.value)}
                    options={roomtypes}
                    optionLabel="name"
                    placeholder="Seleccionar Tipo de Habitación"
                    filter
                    style={{ width: "100%" }}
                  />
                </div>
              )}
              {roompersontypes.length > 0 && (
                <div className="field col-12 md:col-4">
                  <label
                    htmlFor="roompersontype"
                    style={{ fontSize: "large", color: "#35c4dc" }}
                  >
                    Tipo de persona:
                  </label>
                  <Dropdown
                    id="roompersontype"
                    value={selectRoomPersonType}
                    onChange={(e) => onChangeHandler("roompersontype", e.value)}
                    options={roompersontypes}
                    optionLabel="name"
                    placeholder="Seleccionar Tipo de Habitación"
                    filter
                    style={{ width: "100%" }}
                  />
                </div>
              )}
              {selectRoomType !== null && selectRoomPersonType !== null && (
                <div className="field col-12 md:col-4 mt-4">
                  <Button
                    rounded
                    text
                    raised
                    type="button"
                    style={{ backgroundColor: "#fff" }}
                    label="Agregar"
                    icon="pi pi-plus"
                    className="p-button-success"
                    onClick={() => {
                      addTolistHandler();
                    }}
                  />
                </div>
              )}
              <div className="field col-12 md:col-12 mt-4">
                {getFormErrorMessage()}
              </div>
            </div>
            <div className="field col-12 md:col-12">
              <Button
                rounded
                text
                raised
                style={{ backgroundColor: "#fff" }}
                label="Regresar"
                icon="pi pi-arrow-left"
                className="p-button-danger"
                onClick={() => {
                  navigate("/conference/detail/" + conference.id);
                }}
              />
              {roomConfigList.length > 0 && (
                <Button
                  rounded
                  text
                  raised
                  style={{ backgroundColor: "#fff", marginLeft: "1%" }}
                  label="Guardar"
                  className="p-button-success"
                  icon={
                    notification && notification.status === "pending"
                      ? "pi pi-spin pi-spinner"
                      : "pi pi-save"
                  }
                  disabled={notification && notification.status === "pending"}
                />
              )}
            </div>
          </form>
        </div>
        <Toast ref={toast} />
        <DataTable
          stripedRows
          value={roomConfigList}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          dataKey="code"
          tableStyle={{ minWidth: "60rem" }}
        >
          <Column expander={allowExpansion} style={{ width: "5rem" }} />
          <Column field="name" header="Tipo de Habitación" sortable />
          <Column field="quantity" header="Máximo de personas" />
          <Column
            header="Cantidad Actual de personas"
            body={(rowData) => {
              return rowData.roompersontypes.length;
            }}
          />

          <Column
            body={(rowData) => {
              if (rowData.configId === 0) {
                return (
                  <Button
                    rounded
                    text
                    raised
                    type="button"
                    style={{ backgroundColor: "#fff" }}
                    icon="pi pi-trash"
                    className="p-button-danger"
                    onClick={() => {
                      removeRoomType(rowData.code);
                    }}
                  />
                );
              }
            }}
          ></Column>
        </DataTable>
      </Card>
    </>
  );
};
export default ConferencesConfigRooms;
