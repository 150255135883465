import { InputText } from "primereact/inputtext";
import YesNoModal from "../YesNoModal";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateQuoteCourse,
  SendQuote,
} from "../../store/orden-store/orden-actions";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { ValidateDiscountByCode } from "../../store/discount-store/discount-actions";
import generatePDF, { Margin, Resolution } from "react-to-pdf";
import { ordenActions } from "../../store/orden-store/orden-slice";
import { userActions } from "../../store/user-store/user-slice";
import Loading from "../UI/Loading";
import Quote from "../../Templates/Quote";
import DialogModal from "../DialogDialogModal";

const NewQuoteCourseComponent = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.user);
  const quote = useSelector((state) => state.orden.quote);
  const notification = useSelector((state) => state.ui.notification);
  const discountValidation = useSelector(
    (state) => state.discount.discountValidation.discountCode.isValid
  );
  const [visibleQuote, setVisibleQuote] = useState(false);
  const [visibleUser, setVisibleUser] = useState(false);
  const reportTemplateRef = useRef(null);
  const [refresh, setRefresh] = useState(false);

  const [newQuote, setNewQuote] = useState({
    course: { id: parseInt(params.courseId) },
    ordenType: { id: props.ordenTypeId },
    paymentMethod: {
      id: 1,
    },
    status: {
      id: 9,
    },
    amount: props.course.price,
    discountCode: "",
    category: "",
    quantity: 1,
  });
  useEffect(() => {
    if (
      notification !== null &&
      (notification.status === "warning" || notification.status === "error") &&
      props.visibleNewQuote
    ) {
      setRefresh(false);
    }
  }, [notification, props.visibleNewQuote]);

  useEffect(() => {
    if (newQuote.discountCode !== undefined && newQuote.discountCode !== "") {
      dispatch(
        ValidateDiscountByCode(
          newQuote.discountCode,
          parseInt(params.courseId),
          newQuote.quantity,
          auth.UserInfo.email
        )
      );
    }
  }, [
    auth.UserInfo.email,
    dispatch,
    newQuote.discountCode,
    newQuote.quantity,
    params.courseId,
  ]);
  useEffect(() => {
    if (props.course && props.course.id > 0 && quote && newQuote.amount === 0) {
      const nq = { ...newQuote };
      nq.amount = props.course.price;
      setNewQuote(nq);
    }
  }, [props.course, newQuote, quote]);

  const generateQuotePDF = async () => {
    const options = {
      method: "open",
      resolution: Resolution.LOW,
      page: {
        margin: Margin.SMALL,
        format: "a4",
      },
      canvas: {
        mimeType: "image/png",
        qualityRatio: 1,
      },
      overrides: {
        pdf: {
          compress: true,
        },
        canvas: {
          useCORS: true,
        },
      },
    };
    const pdf = await generatePDF(reportTemplateRef, options);
    dispatch(SendQuote(quote, pdf.output("blob"), auth.UserInfo.email));
    dispatch(ordenActions.setQuote({}));
    setNewQuote({});
    dispatch(userActions.restartState());
    props.setVisibleNewQuote(false);
    setVisibleQuote(false);
  };
  

  const createQuoteHandler = () => {
    dispatch(CreateQuoteCourse(newQuote, auth.UserInfo.email));

    setVisibleQuote(true);
  };
  const onChangeQuoteHandler = (id, value) => {
    let quote = { ...newQuote };
    quote.paymentMethod.id = 1;
    quote.status.id = 9;
    quote.amount = props.course.price;

    if (id === "discountCode") {
      quote.discountCode = value;
    }
    if (id === "quantity") {
      quote.quantity = value;
      if (value === 1) {
        quote.category = "individual";
      } else if (value > 1) {
        quote.category = "grupal";
      }
    }

    setNewQuote(quote);
  };
  const addUserHandler = () => {
    if (
      user.user !== undefined &&
      (user.user.name === undefined || user.user.name === "")
    ) {
      dispatch(userActions.setFieldValid({ id: "name", value: false }));
      return;
    }
    if (
      user.user !== undefined &&
      (user.user.lastName === undefined || user.user.lastName === "")
    ) {
      dispatch(userActions.setFieldValid({ id: "lastName", value: false }));
      return;
    }
    if (
      user.user !== undefined &&
      (user.user.email === undefined || user.user.email === "")
    ) {
      dispatch(userActions.setFieldValid({ id: "email", value: false }));
      return;
    }
    if (
      user.user !== undefined &&
      (user.user.cellPhone === undefined || user.user.cellPhone === "")
    ) {
      dispatch(userActions.setFieldValid({ id: "cellPhone", value: false }));
      return;
    }
    if (
      user.user !== undefined &&
      (user.user.country === undefined || user.user.country === "")
    ) {
      dispatch(userActions.setFieldValid({ id: "country", value: false }));
      return;
    }
    setNewQuote({ ...newQuote, user: user.user });
    setVisibleUser(false);
  };
  const headerNewQuote = (
    <>
      <span style={{ fontSize: "x-large" }}>Nueva Cotización</span>
    </>
  );
  const contentNewQuote = (
    <>
      {refresh === true && (
        <>
          <Loading visible={refresh} />
        </>
      )}
      {refresh === false && (
        <div className="formgrid grid">
          <div className="field col-12 md:col-12">
            <Button
              rounded
              text
              raised
              label="Solicitante"
              className="p-button-primary"
              icon="pi pi-user-plus"
              onClick={() => {
                setVisibleUser(true);
              }}
            />
          </div>
          {newQuote.user !== undefined && (
            <>
              <div className="field col-12 md:col-12">
                <label
                  htmlFor="status"
                  style={{
                    fontSize: "large",
                    fontWeight: "bolder",
                    color: "black",
                  }}
                >
                  Cotización para:
                </label>{" "}
                <label htmlFor="status" style={{ fontSize: "large" }}>
                  {newQuote.user.name} {newQuote.user.lastName}{" "}
                  {newQuote.user.email}
                </label>
              </div>
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="rnc"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Cantidad de participantes:
                </label>
                <InputNumber
                  value={
                    newQuote.quantity !== undefined ? newQuote.quantity : 1
                  }
                  onValueChange={(e) =>
                    onChangeQuoteHandler("quantity", e.value)
                  }
                  min={1}
                  max={100}
                  style={{
                    width: "100%",
                    borderRadius: "6px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    height: "50px",
                  }}
                />
              </div>
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="discountCode"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Código de descuento:{" "}
                  {newQuote.discountCode !== "" && discountValidation && (
                    <i style={{ color: "green" }} className="pi pi-check"></i>
                  )}
                  {newQuote.discountCode !== "" && !discountValidation && (
                    <i style={{ color: "red" }} className="pi pi-times"></i>
                  )}
                  {newQuote.discountCode === "" && (
                    <i
                      style={{ color: "#35c4dc" }}
                      className="pi pi-question-circle"
                    ></i>
                  )}
                </label>
                <InputText
                  id="discountCode"
                  value={
                    newQuote.discountCode !== undefined &&
                    newQuote.discountCode !== ""
                      ? newQuote.discountCode
                      : ""
                  }
                  onChange={(e) =>
                    onChangeQuoteHandler("discountCode", e.target.value)
                  }
                  style={{
                    width: "100%",
                    borderRadius: "6px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    height: "50px",
                  }}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
  

  return (
    <>
      <YesNoModal
        className="p-"
        header={headerNewQuote}
        content={contentNewQuote}
        visible={props.visibleNewQuote}
        setVisible={props.setVisibleNewQuote}
        yesHandler={createQuoteHandler}
        showButton={newQuote.user !== undefined}
        yesButtonLabel="Generar"
        icon="pi pi-save"
      />
      <YesNoModal
        className="p-"
        header={headerNewQuote}
        content={
          <div ref={reportTemplateRef}>
            <Quote quote={quote} />
          </div>
        }
        visible={visibleQuote}
        setVisible={setVisibleQuote}
        showButton={false}
        ExternalAction={true}
        actionButtonLabel="Enviar Cotización"
        actionIcon="pi pi-envelope"
        actionSeverity="primary"
        actionHandler={generateQuotePDF}
      />
      <DialogModal
        email={auth.UserInfo.email}
        header="Agregar participante"
        visible={visibleUser}
        setVisible={setVisibleUser}
        addUserHandler={addUserHandler}
        refresh={refresh}
      />
    </>
  );
};
export default NewQuoteCourseComponent;
