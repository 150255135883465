import React, { useEffect, useState } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import TextAlign from "@tiptap/extension-text-align";
import Bold from "@tiptap/extension-bold";
import FontSize from "./FontSize";
import MenuEditor from "./MenuEditor";
import "./Editor.css";
import { useSelector } from "react-redux";
// extensions/FontSize.js

const Editor = ({ onChangeHandler }) => {
  const course = useSelector((state) => state.course.course);
  const [content] = useState(course.description);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bold: false,
      }),
      Bold,
      Underline,
      TextStyle,
      FontSize,
      Color,
      Link.configure({
        openOnClick: false,
      }),
      Image,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
    ],
    content: content,

    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onChangeHandler("description", html);
    },
  });

  return (
    <div
      style={{
        padding: "20px",
        border: "1px solid #ccc",
        backgroundColor: "#ffffff",
      }}
    >
      <MenuEditor editor={editor} />
      <div className="ProseMirror">
        <EditorContent editor={editor} />
      </div>
    </div>
  );
};
export default Editor;
