import { conferenceActions } from "./conference-slice";
import { uiActions } from "../ui-slice";

export const GetConferences = (email, showNotification, getall) => {
  return async (dispatch) => {
    if (showNotification) {
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "Solicitando congresos",
          message: "Solicitando congresos al servidor.",
        })
      );
    }

    const GetConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener los congresos");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetConferenceInfo();
      if (response.result.errorCode === "OK") {
        if (getall) {
          dispatch(
            conferenceActions.setConferences({
              conferences: response.data,
            })
          );
        } else {
          dispatch(
            conferenceActions.setConferences({
              conferences: response.data
                .filter((conf) => conf.status === true)
                .map((conf) => {
                  return {
                    code: conf.id,
                    name: conf.name,
                  };
                }),
            })
          );
        }
        if (showNotification) {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Datos Cargados",
              message: response.result.message,
            })
          );
        }
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceActions.setConferences({
            conferences: [],
          })
        );
        if (showNotification) {
          dispatch(
            uiActions.showNotification({
              status: "warning",
              title: "Advertencia",
              message: response.result.message,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener congresos",
        })
      );
    }
  };
};
export const GetConferenceAndOrdens = (id, email) => {

  
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando congresos",
        message: "Solicitando congresos al servidor.",
      })
    );

    const GetConferenceInfo = async () => {
      console.log(`${process.env.REACT_APP_URL}admin/conferences/orden`);
      
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences/orden`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );

      
      if (!response.ok) {
        console.log(response);
        throw new Error("No se pudo obtener los congreso");
      }
      const data = await response.json();

      return data;
    };

    try {
      const response = await GetConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceActions.setConference({
            conference: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceActions.setConferences({
            conferences: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      console.log(error);
      
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener congreso",
        })
      );
    }
  };
};
export const GetConferenceById = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Congreso",
        message: "Solicitando congreso al servidor.",
      })
    );
    const getConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el congreso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceActions.setConference({
            conference: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceActions.setConference({
            conference: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el congreso",
        })
      );
    }
  };
};
export const UpadateConference = (conference, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando el congreso",
        message: "Solicitando Actualización del congreso al servidor.",
      })
    );
    let configs = [];
    if (conference.conferencesNetworkingConfig !== undefined) {
      configs = conference.conferencesNetworkingConfig.map((config) => {
        return {
          Id: config.id,
          ConferenceId: config.conferenceId,
          NetWorkingDate: new Date(config.netWorkingDate),
          Interval: config.interval,
          StartingHour: config.startingHour,
          EndHour: config.endHour,
          Available: config.available,
        };
      });
    }
    let formData = new FormData();

    formData.append("Id", conference.id);
    formData.append("Name", conference.name);
    formData.append("ConferecenCodeName", conference.conferecenCodeName);
    formData.append("Description", conference.description);
    formData.append("Location", conference.location);
    formData.append("Price", conference.price);
    formData.append("SoldOut", conference.soldOut);
    formData.append("InformativeTitle", conference.informativeTitle);
    formData.append("InformativeBody", conference.informativeBody);
    formData.append("TernmConditions", conference.ternmConditions);
    formData.append("CreditCardOnly", conference.creditCardOnly);
    formData.append("WithRooms", conference.withRooms);

    if (typeof conference.startDate === "string") {
      formData.append("StartDate", conference.startDate);
    } else {
      formData.append("StartDate", conference.startDate.toDateString());
    }
    if (typeof conference.endDate === "string") {
      formData.append("EndDate", conference.endDate);
    } else {
      formData.append("EndDate", conference.endDate.toDateString());
    }

    conference.conferenceMenuInfoes.forEach((option, index) => {
      formData.append(`conferenceMenuInfoes[${index}][name]`, option.name);
      formData.append(`conferenceMenuInfoes[${index}][url]`, option.url);
    });
    formData.append("Config", JSON.stringify([...configs]));
    formData.append("File", conference.file);
    formData.append("UserName", email);

    const updateConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences/update`,
        {
          method: "POST",
          body: formData,
          headers: {
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el congreso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceActions.setConference({
            conference: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Congreso Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceActions.setConference({
            conference: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el congreso",
        })
      );
    }
  };
};
export const CreateConference = (conference, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Congreso",
        message: "Solicitando Creación del congreso al servidor.",
      })
    );
    let formData = new FormData();
    formData.append("Name", conference.name);
    formData.append("Description", conference.description);
    formData.append("ConferecenCodeName", conference.conferecenCodeName);
    formData.append("Location", conference.location);
    formData.append("Price", conference.price);
    formData.append("SoldOut", conference.soldOut);
    formData.append("InformativeTitle", conference.informativeTitle);
    formData.append("InformativeBody", conference.informativeBody);
    formData.append("StartDate", conference.startDate.toDateString());
    formData.append("EndDate", conference.endDate.toDateString());
    formData.append("TernmConditions", conference.ternmConditions);
    formData.append("CreditCardOnly", conference.creditCardOnly);
    formData.append("WithRooms", conference.withRooms);

    conference.conferenceMenuInfoes.forEach((option, index) => {
      formData.append(
        `conferenceMenuInfoes[${index}][id]`,
        option.id !== undefined ? option.id : 0
      );
      formData.append(`conferenceMenuInfoes[${index}][name]`, option.name);
      formData.append(`conferenceMenuInfoes[${index}][url]`, option.url);
    });
    formData.append("File", conference.file);
    formData.append("UserName", email);

    const createConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences/create`,
        {
          method: "POST",
          body: formData,
          headers: {
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el congreso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceActions.setConference({
            conference: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Congreso Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceActions.setConference({
            conference: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el congreso",
        })
      );
    }
  };
};
export const ActiveConference = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Activando congreso",
        message: "Solicitando activación del congreso al servidor.",
      })
    );
    const activeOrdenTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences/active`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo activar el congreso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await activeOrdenTypeInfo();

      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceActions.setConference({
            conference: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Congreso Activado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceActions.setConference({
            ordenType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo activar el congreso",
        })
      );
    }
  };
};
export const InactiveConference = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Inactivando congreso",
        message: "Solicitando inactivación del congreso al servidor.",
      })
    );
    const inactiveConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences/inactive`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo inactivar el congreso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await inactiveConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceActions.setConference({
            conference: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Congreso Inactivado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceActions.setConference({
            ordenType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo inactivar el estado",
        })
      );
    }
  };
};
export const ConfigConference = (conference, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Configuración Congreso",
        message: "Se esta creando la configuración del congreso.",
      })
    );

    const ConfigConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences/createConfig`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: conference.id,
            roomtypes: conference.roomtypes,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo inactivar el congreso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await ConfigConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceActions.setConference({
            conference: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Congreso Inactivado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceActions.setConference({
            ordenType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo inactivar el estado",
        })
      );
    }
  };
};
export const RemoveConfigConference = (configId, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Eliminado Configuración Congreso",
        message: "Se esta eliminando la configuración del congreso.",
      })
    );

    const RemoveConfigConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/conferences/removeConfig`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            RoomConfigId: configId,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo inactivar el congreso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await RemoveConfigConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          conferenceActions.setConference({
            conference: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Congfiguración Eliminada",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          conferenceActions.setConference({
            ordenType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo inactivar el estado",
        })
      );
    }
  };
};


