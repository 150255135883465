import { discountActions } from "./discount-slice";
import { uiActions } from "../ui-slice";

export const GetDiscounts = (email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando descuentos",
        message: "Solicitando descuentos al servidor.",
      })
    );
    const GetDiscountInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/discount/getall`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener los descuentos");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetDiscountInfo();
      if (response.result.errorCode === "OK") {
        
        dispatch(
          discountActions.setDiscounts({
            discounts: response.data.map((discount) => {
              let startDate = new Date(discount.startDate).toDateString();
              let endDate = new Date(discount.endDate).toDateString();
              return { ...discount, startDate, endDate };
            }),
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener los descuentos",
        })
      );
    }
  };
};

export const GetDiscountById = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Descuento",
        message: "Solicitando descuento al servidor.",
      })
    );
    const getDiscountInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/discount/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el descuento");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getDiscountInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          discountActions.setDiscount({
            discount: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Descuento Cargado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          discountActions.setDiscount({
            discount: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el descuento",
        })
      );
    }
  };
};

export const UpadateDiscount = (discount, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando el descuento",
        message: "Solicitando Actualización del descuento al servidor.",
      })
    );

    const updateDiscountInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/discount/update`,
        {
          method: "POST",
          body: JSON.stringify({
            ...discount,
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el descuento");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateDiscountInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          discountActions.setDiscount({
            discount: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Descuento Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          discountActions.setDiscount({
            discount: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el descuento",
        })
      );
    }
  };
};

export const CreateDiscount = (discount, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Tipo de Orden",
        message: "Solicitando Creación del descuento al servidor.",
      })
    );

    const createDiscountInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/discount/create`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            ...discount,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el descuento");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createDiscountInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          discountActions.setDiscount({
            discount: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Descuento Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          discountActions.setDiscount({
            discount: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el descuento",
        })
      );
    }
  };
};

export const ValidateDiscountByCode = (
  discountCode,
  conferenceId,
  userCounts,
  email
) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Validando Descuento",
        message: "Validando descuento en el servidor.",
      })
    );

    const getDiscountInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/discount/validateDiscountCode`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            UserCounts: userCounts,
            DiscountCode: discountCode,
            Conference: {
              id: conferenceId,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el descuento");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getDiscountInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          discountActions.setDiscountValid({
            isValid: true,
            errorMessage: response.result.message,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          discountActions.setDiscountValid({
            isValid: false,
            errorMessage: response.result.message,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Código descuento",
            message: response.result.message,
          })
        );
      }
    } catch (error) {}
  };
};
export const ValidateCourseDiscountByCode = (
  discountCode,
  courseId,
  userCounts,
  email
) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Validando Descuento",
        message: "Validando descuento en el servidor.",
      })
    );

    const getDiscountInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/discount/validateDiscountCode`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            UserCounts: userCounts,
            DiscountCode: discountCode,
            Conference: {
              id: courseId,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el descuento");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getDiscountInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          discountActions.setDiscountValid({
            isValid: true,
            errorMessage: response.result.message,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          discountActions.setDiscountValid({
            isValid: false,
            errorMessage: response.result.message,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Código descuento",
            message: response.result.message,
          })
        );
      }
    } catch (error) {}
  };
};
export const ValidateDiscountByCodeAndOrdenId = (
  ordenId,
  discountCode,
  email
) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Validando Descuento",
        message: "Validando descuento en el servidor.",
      })
    );

    const getDiscountInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/discount/validateDiscountCodeByOrdenId`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id:ordenId,
            DiscountCode: discountCode,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el descuento");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getDiscountInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          discountActions.setDiscountValid({
            isValid: true,
            errorMessage: response.result.message,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          discountActions.setDiscountValid({
            isValid: false,
            errorMessage: response.result.message,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Código descuento",
            message: response.result.message,
          })
        );
      }
    } catch (error) {}
  };
};

export const ValidateAutoDiscountByCode = async (
  discount,
  email,
  userCounts
) => {
  const getDiscountInfo = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_URL}discount/validateDiscountCode`,
      {
        method: "POST",
        body: JSON.stringify({
          UserName: email,
          UserCounts: userCounts,
          Payload: {
            ...discount,
          },
        }),
        headers: {
          "Content-Type": "application/json",
          APIKey: process.env.REACT_APP_KEY,
          APISecretKey: process.env.REACT_APP_SECRET_KEY,
          APPName: process.env.REACT_APP_NAME,
        },
      }
    );
    if (!response.ok) {
      throw new Error("No se pudo obtener el descuento");
    }
    const data = await response.json();
    return data;
  };
  try {
    const response = await getDiscountInfo();
    if (response.result.errorCode === "OK") {
      return true;
    }
    if (response.result.errorCode === "Warning") {
      return false;
    }
  } catch (error) {
    return false;
  }
};
