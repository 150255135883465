import Excel from "exceljs";
import { saveAs } from "file-saver";
import { name } from "msal/lib-commonjs/packageMetadata";
const discountForReports = (orden, conference) => {
  const discount = orden.discount;
  if (discount !== undefined && discount.id !== 0) {
    let discountValue = 0;
    if (discount.nominal) {
      discountValue = discount.discountNominal;
    }
    if (discount.percentage) {
      discountValue = conference.price * (discount.discountPercentage / 100);
    }
    return discountValue;
  }
  return 0;
};
export const getListUserReport = async (conference) => {
  const columns = [
    { header: "Orden Id", key: "id" },
    { header: "Fecha", key: "creationDate" },
    { header: "Monto Congreso", key: "amount" },
    { header: "Nombre", key: "fullName" },
    { header: "Email", key: "email" },
    { header: "País", key: "country" },
    { header: "Ciudad", key: "city" },
    { header: "Dirección", key: "address" },
    { header: "Entidad Labora", key: "company" },
    { header: "Metodo de Pago", key: "paymentMethod" },
    { header: "Factura a nombre de", key: "billName" },
    { header: "Dirección factura", key: "billAddress" },
    { header: "Estatus Actual", key: "status" },
    { header: "Código Descuento", key: "discountCode" },
    { header: "Tipo descuento", key: "discountType" },
    { header: "Descuento", key: "discount" },
    { header: "Requiere Comprobante", key: "taxBill" },
    { header: "RNC", key: "rnc" },
    { header: "Networking", key: "networking" },
  ];
  let data = [];
  const info = conference.orders.map((orden) => {
    let USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return orden.orders.map((info) => {
      return {
        id: info.id,
        creationDate: info.creationDate.split("T")[0],
        amount: info.amount,
        fullName: info.user.name + " " + info.user.lastName,
        email: info.user.email,
        country: info.user.country,
        city: info.user.city,
        address: info.user.address,
        company: info.user.company.name,
        paymentMethod: orden.paymentMethod.description,
        billName: info.billName,
        billAddress: info.billAddress,
        status: orden.status.description,
        discountCode: orden.discount.discountCode,
        discountType: orden.discount.nominal ? "Nominal" : "Porcentual",
        discount: orden.discount.nominal
          ? USDollar.format(discountForReports(orden, conference))
          : orden.discount.percentage
          ? `(${orden.discount.discountPercentage}%) ` +
            USDollar.format(discountForReports(orden, conference))
          : "",
        taxBill: info.taxBill ? "Si" : "No",
        rnc: info.rnc,
        networking: info.networking ? "Si" : "No",
      };
    });
  });
  for (const iterator of info) {
    for (const iterator2 of iterator) {
      data.push(iterator2);
    }
  }

  const workSheetName = "Registros";
  const workbook = new Excel.Workbook();

  try {
    // const myInput = document.getElementById(myInputId);
    const fileName = `Registros ${conference.name}`;

    // creating one worksheet in workbook
    const worksheet = workbook.addWorksheet(workSheetName);

    // add worksheet columns
    // each columns contains header and its mapping key from data
    worksheet.columns = columns;

    // updated the font for first row.
    worksheet.getRow(1).font = { bold: true, size: 16 };

    // loop through all of the columns and set the alignment with width.
    worksheet.columns.forEach((column) => {
      column.width = column.header.length + 5;
      column.alignment = { horizontal: "center" };
    });

    // loop through data and add each one to worksheet
    data.forEach((singleData) => {
      worksheet.addRow(singleData);
    });

    // loop through all of the rows and set the outline style.
    worksheet.eachRow({ includeEmpty: false }, (row) => {
      // store each cell to currentCell
      const currentCell = row._cells;

      // loop through currentCell to apply border only for the non-empty cell of excel
      currentCell.forEach((singleCell) => {
        // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
        const cellAddress = singleCell._address;

        // apply border
        worksheet.getCell(cellAddress).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });
    for (let index = 2; index <= worksheet.rowCount; index++) {
      worksheet.getRow(index).font = { size: 14 };
    }

    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();

    // download the processed file
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  } catch (error) {
    console.error("<<<ERRROR>>>", error);
    console.error("Something Went Wrong", error.message);
  } finally {
    // removing worksheet's instance to create new one
    workbook.removeWorksheet(workSheetName);
  }
};
export const getListUserCourseReport = async (course) => {
  const columns = [
    { header: "Orden Id", key: "id" },
    { header: "Fecha", key: "creationDate" },
    { header: "Monto Capacitación", key: "amount" },
    { header: "Nombre", key: "fullName" },
    { header: "Email", key: "email" },
    { header: "País", key: "country" },
    { header: "Ciudad", key: "city" },
    { header: "Dirección", key: "address" },
    { header: "Entidad Labora", key: "company" },
    { header: "Metodo de Pago", key: "paymentMethod" },
    { header: "Factura a nombre de", key: "billName" },
    { header: "Dirección factura", key: "billAddress" },
    { header: "Estatus Actual", key: "status" },
    { header: "Código Descuento", key: "discountCode" },
    { header: "Tipo descuento", key: "discountType" },
    { header: "Descuento", key: "discount" },
    { header: "Requiere Comprobante", key: "taxBill" },
    { header: "RNC", key: "rnc" },
    { header: "Networking", key: "networking" },
  ];
  let data = [];

  const info = course.orders.map((orden) => {
    let USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    return orden.orders.map((info) => {
      return {
        id: info.id,
        creationDate: info.creationDate.split("T")[0],
        amount: info.amount,
        fullName: info.user.name + " " + info.user.lastName,
        email: info.user.email,
        country: info.user.country,
        city: info.user.city,
        address: info.user.address,
        company: info.user.company.name,
        paymentMethod: orden.paymentMethod.description,
        billName: info.billName,
        billAddress: info.billAddress,
        status: orden.status.description,
        discountCode: orden.discount.discountCode,
        discountType: orden.discount.nominal ? "Nominal" : "Porcentual",
        discount: orden.discount.nominal
          ? USDollar.format(discountForReports(orden, course))
          : orden.discount.percentage
          ? `(${orden.discount.discountPercentage}%) ` +
            USDollar.format(discountForReports(orden, course))
          : "",
        taxBill: info.taxBill ? "Si" : "No",
        rnc: info.rnc,
        networking: info.networking ? "Si" : "No",
      };
    });
  });
  for (const iterator of info) {
    for (const iterator2 of iterator) {
      data.push(iterator2);
    }
  }

  const workSheetName = "Registros";
  const workbook = new Excel.Workbook();

  try {
    // const myInput = document.getElementById(myInputId);
    const fileName = `Registros ${course.name}`;

    // creating one worksheet in workbook
    const worksheet = workbook.addWorksheet(workSheetName);

    // add worksheet columns
    // each columns contains header and its mapping key from data
    worksheet.columns = columns;

    // updated the font for first row.
    worksheet.getRow(1).font = { bold: true, size: 16 };

    // loop through all of the columns and set the alignment with width.
    worksheet.columns.forEach((column) => {
      column.width = column.header.length + 5;
      column.alignment = { horizontal: "center" };
    });

    // loop through data and add each one to worksheet
    data.forEach((singleData) => {
      worksheet.addRow(singleData);
    });

    // loop through all of the rows and set the outline style.
    worksheet.eachRow({ includeEmpty: false }, (row) => {
      // store each cell to currentCell
      const currentCell = row._cells;

      // loop through currentCell to apply border only for the non-empty cell of excel
      currentCell.forEach((singleCell) => {
        // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
        const cellAddress = singleCell._address;

        // apply border
        worksheet.getCell(cellAddress).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });
    for (let index = 2; index <= worksheet.rowCount; index++) {
      worksheet.getRow(index).font = { size: 14 };
    }

    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();

    // download the processed file
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  } catch (error) {
    console.error("<<<ERRROR>>>", error);
    console.error("Something Went Wrong", error.message);
  } finally {
    // removing worksheet's instance to create new one
    workbook.removeWorksheet(workSheetName);
  }
};
export const getBalanceReport = async (conference) => {
  const columns = [
    { header: "Fecha", key: "creationDate" },
    { header: "Orden Id", key: "id" },
    { header: "Código Descuento", key: "discountCode" },
    { header: "Cantidad", key: "quantity" },
    { header: "Precio/Participante", key: "price" },
    { header: "Monto orden", key: "amount" },
    { header: "Descuento/Participante", key: "discount" },
    { header: "Total descuento", key: "totalDiscount" },
    { header: "Total Facturado", key: "total" },
  ];
  let data = [];
  let ordens = [];
  const info = conference.orders.map((orden) => {
    return orden.orders.map((info) => {
      let USDollar = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      const ordenId = info.id;
      let counter = 0;
      if (!ordens.includes(ordenId)) {
        for (let i = 0; i < orden.orders.length; i++) {
          if (orden.orders[i].id === ordenId) counter++;
        }
        if (orden.status.id === 2) {
          ordens.push(ordenId);
          const discount = discountForReports(orden, conference);
          const montoOrden = counter * conference.price;
          const totalDescuento = counter * discount;

          return {
            creationDate: info.creationDate.split("T")[0],
            id: info.id,
            discountCode: orden.discount.discountCode,
            quantity: counter,
            price: USDollar.format(conference.price),
            amount: USDollar.format(orden.amount),
            discount: USDollar.format(discount),
            totalDiscount: USDollar.format(totalDescuento),
            total: USDollar.format(montoOrden - totalDescuento),
          };
        } else {
          return undefined;
        }
      } else {
        return undefined;
      }
    });
  });
  for (const iterator of info) {
    for (const iterator2 of iterator) {
      data.push(iterator2);
    }
  }

  const workSheetName = "Pagos";
  const workbook = new Excel.Workbook();

  try {
    // const myInput = document.getElementById(myInputId);
    const fileName = `Pagos ${conference.name}`;

    // creating one worksheet in workbook
    const worksheet = workbook.addWorksheet(workSheetName);

    // add worksheet columns
    // each columns contains header and its mapping key from data
    worksheet.columns = columns;

    // updated the font for first row.
    worksheet.getRow(1).font = { bold: true, size: 16 };

    // loop through all of the columns and set the alignment with width.
    worksheet.columns.forEach((column) => {
      column.width = column.header.length + 5;
      column.alignment = { horizontal: "center" };
    });

    // loop through data and add each one to worksheet
    data.forEach((singleData) => {
      if (singleData !== undefined) {
        worksheet.addRow(singleData);
      }
    });

    // loop through all of the rows and set the outline style.
    worksheet.eachRow({ includeEmpty: false }, (row) => {
      // store each cell to currentCell
      const currentCell = row._cells;

      // loop through currentCell to apply border only for the non-empty cell of excel
      currentCell.forEach((singleCell) => {
        // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
        const cellAddress = singleCell._address;

        // apply border
        worksheet.getCell(cellAddress).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });
    for (let index = 2; index <= worksheet.rowCount; index++) {
      worksheet.getRow(index).font = { size: 14 };
    }
    // write the content using writeBuffer
    const buf = await workbook.xlsx.writeBuffer();

    // download the processed file
    saveAs(new Blob([buf]), `${fileName}.xlsx`);
  } catch (error) {
    console.error("<<<ERRROR>>>", error);
    console.error("Something Went Wrong", error.message);
  } finally {
    // removing worksheet's instance to create new one
    workbook.removeWorksheet(workSheetName);
  }
};
