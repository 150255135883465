import { Button } from "primereact/button";
import { ColorPicker } from "primereact/colorpicker";
import { Dropdown } from "primereact/dropdown";
import { useState } from "react";

const MenuEditor = ({ editor }) => {
  const [color, setColor] = useState(null);
  const [selectFontSize, setSelectFontSize] = useState(null);
  const fontSizes = [
    { name: "12", code: "12px" },
    { name: "14", code: "14px" },
    { name: "16", code: "16px" },
    { name: "18", code: "18px" },
    { name: "24", code: "24px" },
    { name: "32", code: "32px" },
    { name: "36", code: "36px" },
    { name: "48", code: "48px" },
    { name: "64", code: "64px" },
    { name: "72", code: "72px" },
  ];

  if (!editor) return null;

  return (
    <div style={{ marginBottom: "1rem" }}>
      <div className="formgrid grid">
        <div className="field col-12 md:col-1">
          <Button
            label="B"
            type="button"
            style={{
              backgroundColor: editor.isActive("bold") ? "#21689C" : "#ffffff",
              color: editor.isActive("bold") ? "#ffffff" : "#000000",
              border: "none",
              marginRight: "5px",
            }}
            onClick={() => editor.chain().focus().toggleBold().run()}
          />
        </div>
        <div className="field col-12 md:col-1">
          <Button
            label="I"
            type="button"
            style={{
              backgroundColor: editor.isActive("italic")
                ? "#21689C"
                : "#ffffff",
              color: editor.isActive("italic") ? "#ffffff" : "#000000",
              border: "none",
              marginRight: "5px",
            }}
            onClick={() => editor.chain().focus().toggleItalic().run()}
          />
        </div>
        <div className="field col-12 md:col-1">
          <Button
            label={<span style={{ textDecoration: "underline" }}>U</span>}
            type="button"
            style={{
              backgroundColor: editor.isActive("underline")
                ? "#21689C"
                : "#ffffff",
              color: editor.isActive("underline") ? "#ffffff" : "#000000",
              border: "none",
              marginRight: "5px",
            }}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
          />
        </div>
        <div className="field col-12 md:col-1">
          <ColorPicker
            value={color}
            defaultColor="#000000"
            style={{
              border: "none",
              marginRight: "5px",
            }}
            onChange={(e) => {
              console.log(e.value);

              setColor(e.value);
              editor
                .chain()
                .focus()
                .setColor("#" + e.value)
                .run();
            }}
          />
        </div>
        <div className="field col-12 md:col-1">
          <Button
            icon="pi pi-align-left"
            type="button"
            style={{
              backgroundColor: editor.isActive("left") ? "#21689C" : "#ffffff",
              color: editor.isActive("left") ? "#ffffff" : "#000000",
              border: "none",
              marginRight: "5px",
            }}
            onClick={() => editor.chain().focus().setTextAlign("left").run()}
          />
        </div>
        <div className="field col-12 md:col-1">
          <Button
            icon="pi pi-align-center"
            type="button"
            style={{
              backgroundColor: editor.isActive("center")
                ? "#21689C"
                : "#ffffff",
              color: editor.isActive("center") ? "#ffffff" : "#000000",
              border: "none",
              marginRight: "5px",
            }}
            onClick={() => editor.chain().focus().setTextAlign("center").run()}
          />
        </div>
        <div className="field col-12 md:col-1">
          <Button
            icon="pi pi-align-justify"
            type="button"
            style={{
              backgroundColor: editor.isActive("justify")
                ? "#21689C"
                : "#ffffff",
              color: editor.isActive("justify") ? "#ffffff" : "#000000",
              border: "none",
              marginRight: "5px",
            }}
            onClick={() => editor.chain().focus().setTextAlign("justify").run()}
          />
        </div>
        <div className="field col-12 md:col-1">
          <Button
            icon="pi pi-align-right"
            type="button"
            style={{
              backgroundColor: editor.isActive("right") ? "#21689C" : "#ffffff",
              color: editor.isActive("right") ? "#ffffff" : "#000000",
              border: "none",
              marginRight: "5px",
            }}
            onClick={() => editor.chain().focus().setTextAlign("right").run()}
          />
        </div>
        <div className="field col-12 md:col-1">
          <Dropdown
            value={selectFontSize}
            onChange={(e) => {
              setSelectFontSize(e.value);
              editor
                .chain()
                .focus()
                .updateAttributes("textStyle", { fontSize: e.value.code })
                .run();
            }}
            options={fontSizes}
            optionLabel="name"
            placeholder="Tamaño"
          />
        </div>
      </div>
    </div>
  );
};
export default MenuEditor;
