import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roomPersonTypes: [],
  roomPersonType: {
    id: 0,
    conferences: {},
    name: "",
    description: "",
    status: false,
    required: false,
    price: 0,
  },
  roomPersonTypeValidation: {
    isValid: true,
    name: {
      isValid: true,
      errorMessage: "El campo nombre no debe estar vacio",
    },
    description: {
      isValid: true,
      errorMessage: "El campo descripción no debe estar vacio",
    },
    conferences: {
      isValid: true,
      errorMessage: "Debe seleccionar un congreso",
    },
    price: {
      isValid: true,
      errorMessage: "El campo precio no debe estar vacio",
    },
    required: {
      isValid: true,
      errorMessage: "El campo requerido no debe estar vacio",
    },

  },
};

const roomPersonTypeSlice = createSlice({
  name: "roomPersonType",
  initialState,
  reducers: {
    restartState(state) {
      state.roomPersonType = {};
      state.roomPersonTypes = [];
    },
    setRoomPersonTypes(state, actions) {
      state.roomPersonTypes = actions.payload.roomPersonTypes;
      state.roomPersonType = {};
    },
    setRoomPersonType(state, actions) {
      state.roomPersonType = actions.payload.roomPersonType;
      state.roomPersonTypes = [];
    },
    modifyPropertyValue(state, actions) {
      state.roomPersonType[actions.payload.id] = actions.payload.value;
    },
    setRoomPersonTypeValidation(state, actions) {
      state.roomPersonTypeValidation = {
        name: {
          ...state.roomPersonTypeValidation.name,
          isValid: actions.payload.name !== "",
        },
        description: {
          ...state.roomPersonTypeValidation.description,
          isValid: actions.payload.description !== "",
        },
        conferences: {
          ...state.roomPersonTypeValidation.conferences,
          isValid:
            actions.payload.conferences !== undefined &&
            actions.payload.conferences.id > 0,
        },
        price: {
          ...state.roomPersonTypeValidation.price,
          isValid: actions.payload.price > 0,
        },
        required: {
          ...state.roomPersonTypeValidation.required,
          isValid: actions.payload.required !== "",
        },
      };
      state.roomPersonTypeValidation.isValid =
        state.roomPersonTypeValidation.description.isValid;
    },
    setFieldValid(state, actions) {
      state.roomPersonTypeValidation[actions.payload.id].isValid =
        actions.payload.value;
    },
  },
});

export const roomPersonTypeActions = roomPersonTypeSlice.actions;
export default roomPersonTypeSlice;
