import { roomPersonTypeActions } from "./roompersontype-slice";
import { uiActions } from "../ui-slice";

export const GetRoomPersonTypes = (
  email,
  showNotification,
  getAll,
  conferenceId
) => {
  return async (dispatch) => {
    if (showNotification) {
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "Cargando inforamción",
          message: "Se esta solicitando la información al servidor.",
        })
      );
    }
    const GetRoomPersonTypesInfo = async () => {
      let response = null;
      if (conferenceId !== undefined && conferenceId > 0) {
        response = await fetch(
          `${process.env.REACT_APP_URL}admin/roompersontype/getallbyconference`,
          {
            method: "POST",
            body: JSON.stringify({
              UserName: email,
              conferences: {
                id: conferenceId,
              },
            }),
            headers: {
              "Content-Type": "application/json",
              APIKey: process.env.REACT_APP_KEY,
              APISecretKey: process.env.REACT_APP_SECRET_KEY,
              APPName: process.env.REACT_APP_NAME,
            },
          }
        );
      } else {
        response = await fetch(
          `${process.env.REACT_APP_URL}admin/roomPersonType/getall`,
          {
            method: "POST",
            body: JSON.stringify({
              UserName: email,
            }),
            headers: {
              "Content-Type": "application/json",
              APIKey: process.env.REACT_APP_KEY,
              APISecretKey: process.env.REACT_APP_SECRET_KEY,
              APPName: process.env.REACT_APP_NAME,
            },
          }
        );
      }

      if (!response.ok) {
        throw new Error("No se pudo obtener la información solicitada");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetRoomPersonTypesInfo();
      if (response.result.errorCode === "OK") {
        if (getAll) {
          dispatch(
            roomPersonTypeActions.setRoomPersonTypes({
              roomPersonTypes: response.data.map((roomPersonType) => {
                return {
                  ...roomPersonType,
                  status:
                    roomPersonType.status === true ? "Activo" : "Inactivo",
                };
              }),
            })
          );
        } else {
          dispatch(
            roomPersonTypeActions.setRoomPersonTypes({
              roomPersonTypes: response.data
                .filter((roomPersonType) => roomPersonType.status === true)
                .map((roomPersonType) => {
                  return {
                    code: roomPersonType.id,
                    name: roomPersonType.name,
                    price: roomPersonType.price,
                  };
                }),
            })
          );
        }
        if (showNotification) {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Datos Cargados",
              message: response.result.message,
            })
          );
        }
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          roomPersonTypeActions.setRoomPersonTypes({
            statues: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la información solicitada",
        })
      );
    }
  };
};
export const GetRoomPersonTypeByConferenceId = (
  email,
  conferenceId,
  showNotification,
  getAll
) => {
  return async (dispatch) => {
    if (showNotification) {
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "Cargando inforamción",
          message: "Se esta solicitando la información al servidor.",
        })
      );
    }
    const GetRoomPersonTypesInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/roomPersonType/getallbyconference`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            conference: {
              id: conferenceId,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener la información solicitada");
      }
      const data = await response.json();
      return data;
    };

    try {
      const response = await GetRoomPersonTypesInfo();
      if (response.result.errorCode === "OK") {
        if (getAll) {
          dispatch(
            roomPersonTypeActions.setRoomPersonTypes({
              roomPersonTypes: response.data.map((roomPersonType) => {
                return {
                  ...roomPersonType,
                  status:
                    roomPersonType.status === true ? "Activo" : "Inactivo",
                };
              }),
            })
          );
        } else {
          dispatch(
            roomPersonTypeActions.setRoomPersonTypes({
              roomPersonTypes: response.data
                .filter((roomPersonType) => roomPersonType.status === true)
                .map((roomPersonType) => {
                  return {
                    code: roomPersonType.id,
                    name: roomPersonType.name,
                  };
                }),
            })
          );
        }
        if (showNotification) {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Datos Cargados",
              message: response.result.message,
            })
          );
        }
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          roomPersonTypeActions.setRoomPersonTypes({
            statues: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener la información solicitada",
        })
      );
    }
  };
};
export const GetRoomPersonType = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando tipo de habitación",
        message: "Solicitando tipo de habitación al servidor.",
      })
    );
    const getRoomPersonTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/roomPersonType/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el tipo de habitación");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getRoomPersonTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          roomPersonTypeActions.setRoomPersonType({
            roomPersonType: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          roomPersonTypeActions.setRoomPersonType({
            roomPersonType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el tipo de habitación",
        })
      );
    }
  };
};
export const UpdateRoomPersonType = (roomPersonType, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Actualizando tipo de habitación",
        message:
          "Solicitando Actualización del tipo de habitación al servidor.",
      })
    );
    const updateRoomPersonTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/roomPersonType/update`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: roomPersonType.id,
            Name: roomPersonType.name,
            Description: roomPersonType.description,
            Conferences: roomPersonType.conferences,
            Required: roomPersonType.required,
            Price: roomPersonType.price,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el tipo de habitación");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await updateRoomPersonTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          roomPersonTypeActions.setRoomPersonType({
            roomPersonType: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          roomPersonTypeActions.setRoomPersonType({
            roomPersonType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el tipo de habitación",
        })
      );
    }
  };
};
export const CreateRoomPersonType = (roomPersonType, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando tipo de habitación",
        message: "Solicitando Creación del tipo de habitación al servidor.",
      })
    );
    const createRoomPersonTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/roomPersonType/create`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Name: roomPersonType.name,
            Description: roomPersonType.description,
            Conferences: roomPersonType.conferences,
            Price: roomPersonType.price,
            Required: roomPersonType.required,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo crear el tipo de habitación");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await createRoomPersonTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          roomPersonTypeActions.setRoomPersonType({
            roomPersonType: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Creado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          roomPersonTypeActions.setRoomPersonType({
            roomPersonType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo crear el tipo de habitación",
        })
      );
    }
  };
};
export const ActiveRoomPersonType = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Activando tipo de habitación",
        message: "Solicitando activación del tipo de habitación al servidor.",
      })
    );
    const activeRoomPersonTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/roomPersonType/active`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo activar el tipo de habitación");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await activeRoomPersonTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          roomPersonTypeActions.setRoomPersonType({
            roomPersonType: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Activado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          roomPersonTypeActions.setRoomPersonType({
            roomPersonType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo activar el tipo de habitación",
        })
      );
    }
  };
};
export const InactiveRoomPersonType = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Inactivando tipo de habitación",
        message: "Solicitando activación del tipo de habitación al servidor.",
      })
    );
    const inactiveRoomPersonTypeInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/roomPersonType/inactive`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo inactivar el tipo de habitación");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await inactiveRoomPersonTypeInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          roomPersonTypeActions.setRoomPersonType({
            roomPersonType: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cargo Inactivado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          roomPersonTypeActions.setRoomPersonType({
            roomPersonType: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo inactivar el tipo de habitación",
        })
      );
    }
  };
};
