import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Tree } from "primereact/tree";
import { classNames } from "primereact/utils";
import YesNoModal from "../YesNoModal";
import { Dropdown } from "primereact/dropdown";
import { Message } from "primereact/message";
import { courseActions } from "../../store/courses-store/course-slice";

const CourseStep4 = (props) => {
  const dispatch = useDispatch();
  const [visibleModule, setVisibleModule] = useState(false);
  const [isValidModule, setIsValidModule] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState({ 0: true, "0-0": true });

  const [nodes, setNodes] = useState([]);
  const [teacher, setTeacher] = useState({
    key: 0,
    label: "",
    Modules: [],
  });
  const [modules, setModules] = useState([]);
  const [moduleDropdown, setModuleDropdown] = useState({
    code: "",
    name: "",
  });

  const generateNodes = useCallback(() => {
    let nodes = [];

    if (props.course.courseTeachers) {
      const listTeachers = [...props.course.courseTeachers];

      // listTeachers.sort((a, b) => a.id - b.id);
      listTeachers.forEach((t) => {
        if (t.modules !== undefined) {
          const listModules = [];

          t.modules.forEach((m) => {
            const fModule = props.course.courseModules.find((mod) => {
              return mod.key === m.key;
            });
            if (fModule === undefined) {
              return;
            }
            listModules.push({
              key: m.key,
              label: fModule.label,
            });
          });
          listModules.sort((a, b) => a.key - b.key);

          const modules = listModules.map((l) => {
            return {
              key: l.key,
              label: l.label,
              parent: t.id,
            };
          });

          nodes.push({
            key: t.id,
            label: t.name + " " + t.lastName,
            image:
              t.photo !== null &&
              t.photo !== undefined &&
              t.photo.objectURL !== undefined &&
              t.photo.objectURL !== null
                ? t.photo.objectURL
                : t.photoURL,
            children: modules,
          });
        } else {
          nodes.push({
            key: t.id,
            label: t.name + " " + t.lastName,
            image:
              t.photo !== null &&
              t.photo !== undefined &&
              t.photo.objectURL !== undefined &&
              t.photo.objectURL !== null
                ? t.photo.objectURL
                : t.photoURL,
            children: [],
          });
        }
      });
    }
    setNodes(nodes);

    setTeacher({
      key: 0,
      label: "",
      lessons: [],
    });
    setIsValidModule(true);
  }, [props.course.courseModules, props.course.courseTeachers]);

  const generateNodesUpdating = useCallback(() => {
    let nodes = [];

    if (props.course.courseTeachers) {
      const listTeachers = [...props.course.courseTeachers];
      listTeachers.sort((a, b) => a.id - b.id);
      listTeachers.forEach((t) => {
        if (t.courseModules !== undefined && t.courseModules !== null) {
          const listModules = [];

          t.courseModules.forEach((m) => {
            const fModule = props.course.courseModules.find((mod) => {
              return mod.id === m.id;
            });
            if (fModule === undefined) {
              return;
            }
            listModules.push({
              key: m.id,
              label: fModule.moduleName,
            });
          });
          listModules.sort((a, b) => a.id - b.id);

          const modules = listModules.map((l) => {
            return {
              key: l.key,
              label: l.label,
              parent: t.id,
            };
          });

          nodes.push({
            key: t.id,
            label: t.name + " " + t.lastName,
            image:
              t.photo !== null &&
              t.photo !== undefined &&
              t.photo.objectURL !== undefined &&
              t.photo.objectURL !== null
                ? t.photo.objectURL
                : t.photoURL,
            children: modules,
          });
        } else {
          nodes.push({
            key: t.id,
            label: t.name + " " + t.lastName,
            image:
              t.photo !== null &&
              t.photo !== undefined &&
              t.photo.objectURL !== undefined &&
              t.photo.objectURL !== null
                ? t.photo.objectURL
                : t.photoURL,
            children: [],
          });
        }
      });
    }
    setNodes(nodes);

    setTeacher({
      key: 0,
      label: "",
      lessons: [],
    });
    setIsValidModule(true);
  }, [props.course.courseModules, props.course.courseTeachers]);

  useEffect(() => {
    if (props.course.courseTeachers.length > 0 && nodes.length === 0) {
      if (props.updating === true) {
        generateNodesUpdating();
      } else {
        generateNodes();
      }
    }
  }, [
    generateNodes,
    generateNodesUpdating,
    nodes.length,
    props.course.courseTeachers,
    props.updating,
  ]);

  useEffect(() => {
    if (props.course.courseModules.length > 0) {
      let modules = [];
      if (!props.updating) {
        props.course.courseModules.forEach((m) => {
          modules.push({
            code: m.key,
            name: m.label,
          });
        });
      } else {
        props.course.courseModules.forEach((m) => {
          modules.push({
            code: m.id,
            name: m.moduleName,
          });
        });
      }
      modules.sort((a, b) => a.code - b.code);
      setModules(modules);
    }
  }, [props.course.courseModules, props.updating]);

  const nodeTemplate = (node, options) => {
    let label = <b>{node.label}</b>;
    let labelModule = (
      <b style={{ color: "white", marginLeft: "10px" }}>{node.label}</b>
    );

    const style =
      node.parent === undefined
        ? { borderBottom: "solid 2px white", width: "100%" }
        : { border: "none", width: "100%" };

    return (
      <>
        <div className="formgrid grid" style={style}>
          {node.parent === undefined && (
            <div style={{ border: "solid 1px white" }} className="mb-2">
              {node.children && (
                <Button
                  label="Agregar Módulo"
                  rounded
                  text
                  icon="pi pi-plus"
                  severity="primary"
                  onClick={() => {
                    setTeacher(node);
                    setVisibleModule(true);
                  }}
                />
              )}
            </div>
          )}
          <div className="field col-12 md:col-12" style={{ width: "100%" }}>
            <span style={{ color: "white" }}>
              {node.parent && (
                <Button
                  icon="pi pi-trash"
                  rounded
                  text
                  severity="danger"
                  onClick={() => {
                    const teacher = props.course.courseTeachers.find((t) => {
                      return t.id === node.parent;
                    });
                    props.updating
                      ? removeModuleUpdating(node, teacher)
                      : removeModule(node, teacher);
                  }}
                >
                  {labelModule}
                </Button>
              )}
              {node.parent === undefined && label}
            </span>
            {node.parent === undefined && <br />}
            {node.parent === undefined && (
              <img
                src={node.image}
                alt={node.label}
                style={{ width: "75px" }}
              />
            )}
          </div>
          <div
            className="field col-12 md:col-6"
            style={{ width: "100%" }}
          ></div>
        </div>
      </>
    );
  };

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }

    const expanded = options.expanded;
    const iconClassName = classNames("p-tree-toggler-icon pi pi-fw", {
      "pi-plus": !expanded,
      "pi-minus": expanded,
    });

    return (
      <button
        type="button"
        className="p-tree-toggler p-link"
        tabIndex={-1}
        onClick={options.onClick}
        style={{ color: "#ffffff" }}
      >
        <span className={iconClassName} aria-hidden="true"></span>
      </button>
    );
  };

  const addModule = () => {
    if (moduleDropdown.code === "") {
      setIsValidModule(false);
    } else {
      let isValid = true;
      props.course.courseTeachers.forEach((t) => {
        if (t.modules === undefined || t.modules.length === 0) {
          isValid = true;
        } else {
          const isValidMod = t.modules.find(
            (m) => m.key === moduleDropdown.code
          );

          if (isValidMod !== undefined) {
            isValid = false;
            return;
          }
        }
      });

      if (isValid) {
        setIsValidModule(true);
        const selectedTeacher = props.course.courseTeachers.find(
          (t) => t.id === teacher.key
        );
        if (selectedTeacher.modules === undefined) {
          const newModule = {
            key: moduleDropdown.code,
            label: moduleDropdown.name,
            parent: selectedTeacher.id,
          };

          const t = { ...selectedTeacher, modules: [newModule] };

          let teachers = [
            ...props.course.courseTeachers.filter(
              (m) => m.id !== selectedTeacher.id
            ),
          ];
          teachers.push({ ...t });

          dispatch(
            courseActions.setCourseTeacher({ courseTeachers: teachers })
          );
          if (teachers.length > 0) {
            let hasModules = false;
            for (let index = 0; index < teachers.length; index++) {
              const teacher = teachers[index];
              if (teacher.modules !== undefined && teacher.modules.length > 0) {
                hasModules = true;
              }
            }
            if (hasModules) {
              dispatch(
                courseActions.setFieldValid({
                  id: "courseTeacherModule",
                  value: true,
                })
              );
            } else {
              dispatch(
                courseActions.setFieldValid({
                  id: "courseTeacherModule",
                  value: false,
                })
              );
            }
          }

          let _expandedKeys = {};
          _expandedKeys[teacher.key] = true;
          setExpandedKeys(_expandedKeys);
          setNodes([]);
          setVisibleModule(false);
        } else {
          const isValidModule = selectedTeacher.modules.find(
            (m) => m.key === moduleDropdown.code
          );
          if (isValidModule === undefined) {
            const newModule = {
              key: moduleDropdown.code,
              label: moduleDropdown.name,
              parent: selectedTeacher.id,
            };
            const listModules = [...selectedTeacher.modules];
            listModules.push(newModule);
            const t = { ...selectedTeacher, modules: listModules };

            let teachers = [
              ...props.course.courseTeachers.filter(
                (m) => m.id !== selectedTeacher.id
              ),
            ];
            teachers.push({ ...t });

            dispatch(
              courseActions.setCourseTeacher({ courseTeachers: teachers })
            );
            if (teachers.length > 0) {
              let hasModules = false;
              for (let index = 0; index < teachers.length; index++) {
                const teacher = teachers[index];
                if (
                  teacher.modules !== undefined &&
                  teacher.modules.length > 0
                ) {
                  hasModules = true;
                }
              }
              if (hasModules) {
                dispatch(
                  courseActions.setFieldValid({
                    id: "courseTeacherModule",
                    value: true,
                  })
                );
              } else {
                dispatch(
                  courseActions.setFieldValid({
                    id: "courseTeacherModule",
                    value: false,
                  })
                );
              }
            }

            let _expandedKeys = {};
            _expandedKeys[teacher.key] = true;
            setExpandedKeys(_expandedKeys);
            setNodes([]);
            setVisibleModule(false);
          } else {
            setIsValidModule(false);
          }
        }
      } else {
        setIsValidModule(false);
      }
    }
  };
  const addModuleUpdating = () => {
    if (moduleDropdown.code === "") {
      setIsValidModule(false);
    } else {
      let isValid = true;
      props.course.courseTeachers.forEach((t) => {
        if (
          t.courseModules === undefined ||
          t.courseModules === null ||
          t.courseModules.length === 0
        ) {
          isValid = true;
        } else {
          const isValidMod = t.courseModules.find(
            (m) => m.id === moduleDropdown.code
          );
          if (isValidMod !== undefined) {
            isValid = false;
            return;
          }
        }
      });

      if (isValid) {
        setIsValidModule(true);
        const selectedTeacher = props.course.courseTeachers.find(
          (t) => t.id === teacher.key
        );

        if (selectedTeacher.courseModules === undefined) {
          const newModule = {
            id: moduleDropdown.code,
            moduleName: moduleDropdown.name,
            parent: selectedTeacher.id,
          };

          const t = { ...selectedTeacher, courseModules: [newModule] };

          let teachers = [
            ...props.course.courseTeachers.filter(
              (m) => m.id !== selectedTeacher.id
            ),
          ];
          teachers.push({ ...t });

          dispatch(
            courseActions.setCourseTeacher({ courseTeachers: teachers })
          );
          if (teachers.length > 0) {
            let hasModules = false;
            for (let index = 0; index < teachers.length; index++) {
              const teacher = teachers[index];

              if (
                teacher.courseModules !== undefined &&
                teacher.courseModules !== null &&
                teacher.courseModules.length > 0
              ) {
                hasModules = true;
              }
            }
            if (hasModules) {
              dispatch(
                courseActions.setFieldValid({
                  id: "courseTeacherModule",
                  value: true,
                })
              );
            } else {
              dispatch(
                courseActions.setFieldValid({
                  id: "courseTeacherModule",
                  value: false,
                })
              );
            }
          }

          let _expandedKeys = {};
          _expandedKeys[teacher.key] = true;
          setExpandedKeys(_expandedKeys);
          setNodes([]);
          setVisibleModule(false);
        } else {
          let isValidModule = undefined;
          if (
            selectedTeacher.courseModules !== undefined &&
            selectedTeacher.courseModules !== null
          ) {
            isValidModule = selectedTeacher.courseModules.find(
              (m) => m.id === moduleDropdown.code
            );
          }

          if (isValidModule === undefined) {
            const newModule = {
              id: moduleDropdown.code,
              moduleName: moduleDropdown.name,
              parent: selectedTeacher.id,
            };
            let listModules = [];
            if (
              selectedTeacher.courseModules !== undefined &&
              selectedTeacher.courseModules !== null
            ) {
              listModules = [...selectedTeacher.courseModules];
            }

            listModules.push(newModule);
            listModules.sort((a, b) => a.id - b.id);
            const t = { ...selectedTeacher, courseModules: listModules };

            let teachers = [
              ...props.course.courseTeachers.filter(
                (m) => m.id !== selectedTeacher.id
              ),
            ];
            teachers.push({ ...t });

            dispatch(
              courseActions.setCourseTeacher({ courseTeachers: teachers })
            );
            if (teachers.length > 0) {
              let hasModules = false;
              for (let index = 0; index < teachers.length; index++) {
                const teacher = teachers[index];
                if (
                  teacher.courseModules !== undefined &&
                  teacher.courseModules !== null &&
                  teacher.courseModules.length > 0
                ) {
                  hasModules = true;
                }
              }
              if (hasModules) {
                dispatch(
                  courseActions.setFieldValid({
                    id: "courseTeacherModule",
                    value: true,
                  })
                );
              } else {
                dispatch(
                  courseActions.setFieldValid({
                    id: "courseTeacherModule",
                    value: false,
                  })
                );
              }
            }

            let _expandedKeys = {};
            _expandedKeys[teacher.key] = true;
            setExpandedKeys(_expandedKeys);
            setNodes([]);
            setVisibleModule(false);
          } else {
            setIsValidModule(false);
          }
        }
      } else {
        setIsValidModule(false);
      }
    }
  };
  const removeModule = (module, teacher) => {
    const modules = teacher.modules.filter((m) => {
      return m.key !== module.key;
    });
    modules.forEach((item, index) => {
      if (item.key === module.key) {
        modules.splice(index, 1);
      }
    });

    const t = { ...teacher, modules: modules };

    let teachers = [
      ...props.course.courseTeachers.filter((m) => m.id !== teacher.id),
    ];
    teachers.push({ ...t });

    dispatch(courseActions.setCourseTeacher({ courseTeachers: teachers }));
    if (teachers.length > 0) {
      let hasModules = false;
      for (let index = 0; index < teachers.length; index++) {
        const teacher = teachers[index];
        if (teacher.modules !== undefined && teacher.modules.length > 0) {
          hasModules = true;
        }
      }
      if (hasModules) {
        dispatch(
          courseActions.setFieldValid({
            id: "courseTeacherModule",
            value: true,
          })
        );
      } else {
        dispatch(
          courseActions.setFieldValid({
            id: "courseTeacherModule",
            value: false,
          })
        );
      }
    }

    setNodes([]);

    let _expandedKeys = {};
    _expandedKeys[teacher.id] = true;
    setExpandedKeys(_expandedKeys);
    setNodes([]);
  };
  const removeModuleUpdating = (module, teacher) => {
    const modules = teacher.courseModules.filter((m) => {
      return m.id !== module.key;
    });
    modules.forEach((item, index) => {
      if (item.id === module.key) {
        modules.splice(index, 1);
      }
    });

    const t = { ...teacher, courseModules: modules };

    let teachers = [
      ...props.course.courseTeachers.filter((m) => m.id !== teacher.id),
    ];
    teachers.push({ ...t });

    dispatch(courseActions.setCourseTeacher({ courseTeachers: teachers }));
    if (teachers.length > 0) {
      let hasModules = false;
      for (let index = 0; index < teachers.length; index++) {
        const teacher = teachers[index];
        if (teacher.modules !== undefined && teacher.modules.length > 0) {
          hasModules = true;
        }
      }
      if (hasModules) {
        dispatch(
          courseActions.setFieldValid({
            id: "courseTeacherModule",
            value: true,
          })
        );
      } else {
        dispatch(
          courseActions.setFieldValid({
            id: "courseTeacherModule",
            value: false,
          })
        );
      }
    }

    setNodes([]);

    let _expandedKeys = {};
    _expandedKeys[teacher.id] = true;
    setExpandedKeys(_expandedKeys);
    setNodes([]);
  };

  const getFormErrorMessageModule = () => {
    return !isValidModule ? (
      <Message
        severity="warn"
        text={`El módulo ya ha sido asignado a este u otro facilitador`}
        style={{ width: "100%" }}
      />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };

  //agregar modulo
  const headerModule = (
    <>
      <span style={{ fontSize: "x-large" }}>
        Asignar módulo al facilitador: {teacher.label}
      </span>
    </>
  );
  // console.log(props.course.courseModules);

  const contentModule = (
    <>
      <div className="formgrid grid">
        <div className="field col-12 md:col-12">
          <label
            htmlFor="module"
            style={{ fontSize: "large", color: "#35c4dc" }}
          >
            Nombre del Módulo:
          </label>
          <Dropdown
            value={moduleDropdown}
            onChange={(e) => setModuleDropdown(e.value)}
            options={modules}
            optionLabel="name"
            placeholder="Selecciona un módulo"
            filter
          />
          <div style={{ width: "100%" }}>{getFormErrorMessageModule()}</div>
        </div>
      </div>
    </>
  );
  return (
    <>
      <div
        className="mb-4 bg-blue-900 p-3 mt-4"
        style={{
          marginLeft: "0px",
          marginBottom: "20px",
          width: "100%",
          height: "670px",
          overflowY: "auto",
        }}
      >
        <h2
          style={{
            color: "#fff",
            textDecoration: "underline",
            textAlign: "center",
            fontSize: "xx-large",
          }}
        >
          Asignar Módulos a Facilitadores
        </h2>
        <div className="formgrid grid">
          <div className="field col-12 md:col-12">
            {nodes && nodes.length > 0 && (
              <Tree
                value={nodes}
                nodeTemplate={nodeTemplate}
                togglerTemplate={togglerTemplate}
                style={{ backgroundColor: "#142F47" }}
                expandedKeys={expandedKeys}
                onToggle={(e) => setExpandedKeys(e.value)}
              />
            )}
            <div>{props.getFormErrorMessage("courseTeacherModule")}</div>
          </div>
        </div>
      </div>
      <YesNoModal
        className="p-"
        header={headerModule}
        content={contentModule}
        visible={visibleModule}
        setVisible={setVisibleModule}
        yesHandler={props.updating ? addModuleUpdating : addModule}
        showButton={true}
        yesButtonLabel="Guardar"
        icon="pi pi-save"
      />
    </>
  );
};
export default CourseStep4;
