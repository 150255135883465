import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Menubar } from "primereact/menubar";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import UserMenu from "./UserMenu";
import logoABA from "../assets/img/LogoABA.png";
import "./NavBar.css";

const NavBar = (props) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const menuCourses = useRef(null);
  const menuDinero = useRef(null);
  const menuUser = useRef(null);
  const menuConference = useRef(null);
  const menuDataLAB = useRef(null);

  const courseItems = [];
  const items = [];
  const moneyItems = [];
  const userItems = [];
  const conferenceItems = [];
  const datalabItems = [];

  const courseActions = {
    label: "Cursos",
    icon: "pi pi-fw pi-address-book",

    template: () => {
      return (
        <>
          {courseItems.length > 0 && (
            <>
              <Menu
                model={courseItems}
                popup
                ref={menuCourses}
                id="popup_menu_right"
                style={{
                  backgroundColor: "#143047",
                  border: "solid #fff",
                  width: "f0%",
                }}
              />
              <Button
                label="Cursos"
                size="small"
                icon="pi pi-address-book"
                onClick={(event) => menuCourses.current.toggle(event)}
                style={{
                  width: "100%",
                  marginTop: "0%",
                  backgroundColor: "#143047",
                  color: "#fff",
                  border: "none",
                  fontSize: "large",
                }}
              />
            </>
          )}
        </>
      );
    },
  };

  const moneyActions = {
    label: "Dinero",
    icon: "pi pi-fw pi-wallet",

    template: () => {
      return (
        <>
          {moneyItems.length > 0 && (
            <>
              <Menu
                model={moneyItems}
                popup
                ref={menuDinero}
                id="popup_menu_right"
                style={{
                  backgroundColor: "#143047",
                  border: "solid #fff",
                  width: "f0%",
                }}
              />
              <Button
                label="Pagos"
                size="small"
                icon="pi pi-fw pi-wallet"
                onClick={(event) => menuDinero.current.toggle(event)}
                style={{
                  width: "100%",
                  marginTop: "0%",
                  backgroundColor: "#143047",
                  color: "#fff",
                  border: "none",
                  fontSize: "large",
                }}
              />
            </>
          )}
        </>
      );
    },
  };

  const UserActions = {
    label: "Perfiles",
    icon: "pi pi-fw pi-users",

    template: () => {
      return (
        <>
          {userItems.length > 0 && (
            <>
              <Menu
                model={userItems}
                popup
                ref={menuUser}
                id="popup_menu_right"
                style={{
                  backgroundColor: "#143047",
                  border: "solid #fff",
                  width: "f0%",
                }}
              />
              <Button
                label="Perfiles"
                size="small"
                icon="pi pi-fw pi-users"
                onClick={(event) => menuUser.current.toggle(event)}
                style={{
                  width: "100%",
                  marginTop: "0%",
                  backgroundColor: "#143047",
                  color: "#fff",
                  border: "none",
                  fontSize: "large",
                }}
              />
            </>
          )}
        </>
      );
    },
  };
  const ConferenceActions = {
    label: "Eventos",
    icon: "pi pi-fw pi-wallet",

    template: () => {
      return (
        <>
          {conferenceItems.length > 0 && (
            <>
              <Menu
                model={conferenceItems}
                popup
                ref={menuConference}
                id="popup_menu_right"
                style={{
                  backgroundColor: "#143047",
                  border: "solid #fff",
                  width: "f0%",
                }}
              />
              <Button
                label="Eventos"
                size="small"
                icon="pi pi-megaphone"
                onClick={(event) => menuConference.current.toggle(event)}
                style={{
                  width: "100%",
                  marginTop: "0%",
                  backgroundColor: "#143047",
                  color: "#fff",
                  border: "none",
                  fontSize: "large",
                }}
              />
            </>
          )}
        </>
      );
    },
  };
  const datalabActions = {
    label: "DataLAB",
    icon: "pi pi-fw pi-wallet",

    template: () => {
      return (
        <>
          {datalabItems.length > 0 && (
            <>
              <Menu
                model={datalabItems}
                popup
                ref={menuDataLAB}
                id="popup_menu_right"
                style={{
                  backgroundColor: "#143047",
                  border: "solid #fff",
                  width: "f0%",
                }}
              />
              <Button
                label="DataLAB"
                size="small"
                icon="pi pi-database"
                onClick={(event) => menuDataLAB.current.toggle(event)}
                style={{
                  width: "100%",
                  marginTop: "0%",
                  backgroundColor: "#143047",
                  color: "#fff",
                  border: "none",
                  fontSize: "large",
                }}
              />
            </>
          )}
        </>
      );
    },
  };
  //Perfiles
  items.push(UserActions);
  if (auth.roles !== undefined && auth.roles.includes("user.menu")) {
    userItems.push({
      template: () => {
        return (
          <Button
            label="Usuarios"
            size="small"
            icon="pi pi-users"
            onClick={() => {
              navigate("/users");
            }}
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              fontSize: "large",
            }}
          />
        );
      },
    });
  }
  if (auth.roles !== undefined && auth.roles.includes("charge.menu")) {
    userItems.push({
      template: () => {
        return (
          <Button
            label="Cargos"
            size="small"
            icon="pi pi-shopping-bag"
            onClick={() => {
              navigate("/charges");
            }}
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              fontSize: "large",
            }}
          />
        );
      },
    });
  }
  if (auth.roles !== undefined && auth.roles.includes("department.menu")) {
    userItems.push({
      template: () => {
        return (
          <Button
            label="Departamentos"
            size="small"
            icon="pi pi-share-alt"
            onClick={() => {
              navigate("/departments");
            }}
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              fontSize: "large",
            }}
          />
        );
      },
    });
  }
  if (auth.roles !== undefined && auth.roles.includes("company.menu")) {
    userItems.push({
      template: () => {
        return (
          <Button
            label="Empresas"
            size="small"
            icon="pi pi-building"
            onClick={() => {
              navigate("/companies");
            }}
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              fontSize: "large",
            }}
          />
        );
      },
    });
  }

  //Cursos
  items.push(courseActions);
  if (auth.roles !== undefined && auth.roles.includes("coursetype.menu")) {
    courseItems.push({
      template: () => {
        return (
          <Button
            label="Tipo de cursos"
            size="small"
            icon="pi pi-shop"
            onClick={() => {
              navigate("/course/types");
            }}
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              fontSize: "large",
            }}
          />
        );
      },
    });
  }
  if (auth.roles !== undefined && auth.roles.includes("coursecategory.menu")) {
    courseItems.push({
      template: () => {
        return (
          <Button
            label="Categorías de cursos"
            size="small"
            icon="pi pi-th-large"
            onClick={() => {
              navigate("/course/categories");
            }}
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              fontSize: "large",
            }}
          />
        );
      },
    });
  }
  if (auth.roles !== undefined && auth.roles.includes("course.menu")) {
    courseItems.push({
      template: () => {
        return (
          <Button
            label="Capacitaciones"
            size="small"
            icon="pi pi-book"
            onClick={() => {
              navigate("/courses");
            }}
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              fontSize: "large",
            }}
          />
        );
      },
    });
  }

  //Pagos
  items.push(moneyActions);
  if (auth.roles !== undefined && auth.roles.includes("dolardiario.menu")) {
    moneyItems.push({
      template: () => {
        return (
          <Button
            label="Tasa de cambio"
            size="small"
            icon="pi pi-dollar"
            onClick={() => {
              navigate("/dolardiario/edit/1");
            }}
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              borderBottom: "solid rgb(255, 255, 255)",
              borderBottomStyle: "double",
              fontSize: "large",
            }}
          />
        );
      },
    });
  }
  if (auth.roles !== undefined && auth.roles.includes("discount.menu")) {
    moneyItems.push({
      template: () => {
        return (
          <Button
            label="Descuentos"
            size="small"
            icon="pi pi-tags"
            onClick={() => {
              navigate("/discounts");
            }}
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              fontSize: "large",
            }}
          />
        );
      },
    });
  }
  if (auth.roles !== undefined && auth.roles.includes("status.menu")) {
    moneyItems.push({
      template: () => {
        return (
          <Button
            label="Estados órdenes"
            size="small"
            icon="pi pi-shield"
            onClick={() => {
              navigate("/orden/statues");
            }}
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              fontSize: "large",
            }}
          />
        );
      },
    });
  }
  if (auth.roles !== undefined && auth.roles.includes("ordentype.menu")) {
    moneyItems.push({
      template: () => {
        return (
          <Button
            label="Tipos órdenes"
            size="small"
            icon="pi pi-sitemap"
            onClick={() => {
              navigate("/orden/types");
            }}
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              fontSize: "large",
            }}
          />
        );
      },
    });
  }

  //Eventos
  items.push(ConferenceActions);
  if (auth.roles !== undefined && auth.roles.includes("roomtype.menu")) {
    conferenceItems.push({
      template: () => {
        return (
          <Button
            label="Tipos de Habitación"
            size="small"
            icon="pi pi-building"
            onClick={() => {
              navigate("/roomtypes");
            }}
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              fontSize: "large",
            }}
          />
        );
      },
    });
  }
  if (auth.roles !== undefined && auth.roles.includes("roompersontype.menu")) {
    conferenceItems.push({
      template: () => {
        return (
          <Button
            label="Tipos de personas de Habitación"
            size="small"
            icon="pi pi-user-plus"
            onClick={() => {
              navigate("/roompersontypes");
            }}
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              fontSize: "large",
            }}
          />
        );
      },
    });
  }
  if (auth.roles !== undefined && auth.roles.includes("conferences.menu")) {
    conferenceItems.push({
      template: () => {
        return (
          <Button
            label="Congresos"
            size="small"
            icon="pi pi-globe"
            onClick={() => {
              navigate("/conferences");
            }}
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              fontSize: "large",
            }}
          />
        );
      },
    });
  }

  

  //DataLAB
  items.push(datalabActions);
  if (auth.roles !== undefined && auth.roles.includes("datalab.menu")) {
    datalabItems.push({
      template: () => {
        return (
          <Button
            label="Subir archivo"
            size="small"
            icon="pi pi-upload"
            onClick={() => {
              navigate("/datalab/upload");
            }}
            style={{
              width: "100%",
              marginTop: "0%",
              backgroundColor: "#143047",
              color: "#fff",
              border: "none",
              fontSize: "large",
            }}
          />
        );
      },
    });
  }

  const start = (
    <>
      <img
        alt="logo"
        src={logoABA}
        height="110"
        className="mr-2"
        style={{ cursor: "pointer" }}
        onClick={() => {
          window.location.href = props.home;
        }}
      ></img>
    </>
  );
  const end = (
    <UserMenu
      userName={props.userName}
      ordens={props.ordens}
      changePassword={props.changePassword}
      logoutHandler={props.logoutHandler}
    />
  );

  return (
    <>
      <Menubar
        model={items}
        start={start}
        end={end}
        style={{
          marginTop: "0px",
          width: "100%",
          backgroundColor: "#143047",
          border: "#143047",
          borderRadius: "0px",
          borderBottom: "solid #fff",
        }}
      />
    </>
  );
};
export default NavBar;
