import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ABADatatable from "../../../components/ABADatatable";
import { GetDiscounts } from "../../../store/discount-store/discount-actions";
import { discountActions } from "../../../store/discount-store/discount-slice";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

const DiscountHomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const discounts = useSelector((state) => state.discount.discounts);
  const auth = useSelector((state) => state.auth);
  const [filterDiscounts, setFilterDiscounts] = useState([]);

  useEffect(() => {
    if (auth.roles !== undefined && auth.roles.includes("discount.showAll")) {
      dispatch(GetDiscounts(auth.UserInfo.email));
    }
  }, [dispatch, auth]);
  useEffect(() => {
    if (discounts.length >= 1) {
      if (
        auth.roles.includes("conferences.showAll") &&
        auth.roles.includes("course.showAll")
      ) {
        setFilterDiscounts(discounts);
      } else if (auth.roles.includes("conferences.showAll")) {
        setFilterDiscounts(
          discounts.filter((discount) => discount.conference.id > 0)
        );
      } else if (auth.roles.includes("course.showAll")) {
        setFilterDiscounts(
          discounts.filter((discount) => discount.course.id > 0)
        );
      }
    }
  }, [auth.roles, discounts]);

  const viewDetailHanlder = (val) => {
    navigate(`/discount/view/${val.id}`);
  };
  const editHanlder = (val) => {
    navigate(`/discount/edit/${val.id}`);
  };
  const createHanlder = () => {
    dispatch(
      discountActions.setDiscount({
        discount: {
          id: 0,
          course: { id: 0 },
          conference: { id: 0 },
          description: "",
          name: "",
          discountCode: "",
          percentage: false,
          discountPercentage: 0.0,
          nominal: false,
          numberOfUses: 0,
          discountNominal: 0.0,
          startDate: new Date().toDateString(),
          endDate: new Date().toDateString(),
        },
      })
    );
    navigate(`/discount/create`);
  };
  const backHanlder = () => {
    navigate(`/`);
  };
  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const buttonEdit = (
      <Button
        label="Editar"
        icon="pi pi-file-edit"
        className="p-button-rounded p-button-success p-button-text"
        onClick={() => {
          editHanlder(rowData);
        }}
      />
    );
    const buttonView = (
      <Button
        label="Ver"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-text"
        onClick={() => {
          viewDetailHanlder(rowData);
        }}
      />
    );
    if (auth.roles !== undefined && auth.roles.includes("discount.edit")) {
      buttons.push(buttonEdit);
    }
    if (auth.roles !== undefined && auth.roles.includes("discount.view")) {
      buttons.push(buttonView);
    }
    return buttons;
  };
  actions.push(buttons);

  const discountForTemplate = (discount) => {
    let Showinfo = (
      <Tag
        value="No disponible"
        style={{ width: "100%", fontSize: "medium" }}
        severity="danger"
      ></Tag>
    );
    if (
      discount !== undefined &&
      discount.course !== undefined &&
      discount.course.id > 0
    ) {
      Showinfo = (
        <Tag
          value={discount.course.topic}
          style={{ width: "100%", fontSize: "medium" }}
          severity="success"
        ></Tag>
      );
    }
    if (
      discount !== undefined &&
      discount.conference !== undefined &&
      discount.conference.id > 0
    ) {
      Showinfo = (
        <Tag
          value={discount.conference.name}
          style={{ width: "100%", fontSize: "medium" }}
          severity="info"
        ></Tag>
      );
    }
    return <>{Showinfo}</>;
  };

  const columns = [
    {
      field: "name",
      header: "Nombre",
      filterMatchMode: "contains",
    },
    {
      field: "description",
      header: "Descripcion",
      filterMatchMode: "contains",
    },
    {
      header: "Descuento para",
      body: discountForTemplate,
      filterMatchMode: "contains",
    },
    {
      field: "discountCode",
      header: "Código de descuento",
      filterMatchMode: "contains",
    },
    {
      field: "startDate",
      header: "Inicio",
    },
    {
      field: "endDate",
      header: "Fin",
    },
  ];
  return (
    <>
      <ABADatatable
        title="Descuentos"
        values={filterDiscounts}
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        Columns={columns}
        actions={actions}
        width="18%"
        createButton={{
          label: "Descuento",
          onClick: createHanlder,
          permission: "discount.create",
        }}
        backButton={{
          label: "Regresar",
          onClick: backHanlder,
          permission: "goBack",
        }}
      />
    </>
  );
};
export default DiscountHomePage;
