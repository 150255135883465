import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roomtypes: [],
  roomtype: {
    id: 0,
    name: "",
    description: "",
    status: false,
    quantity: 0,
  },
  roomtypeValidation: {
    isValid: true,
    name: {
      isValid: true,
      errorMessage: "El campo nombre no debe estar vacio",
    },
    description: {
      isValid: true,
      errorMessage: "El campo descripción no debe estar vacio",
    },
    quantity: {
      isValid: true,
      errorMessage: "El campo cantidad no debe estar vacio",
    },
  },
};

const roomtypeSlice = createSlice({
  name: "roomtype",
  initialState,
  reducers: {
    restartState(state) {
      state.roomtype = {};
      state.roomtypes = [];
    },
    setRoomTypes(state, actions) {
      state.roomtypes = actions.payload.roomtypes;
      state.roomtype = {};
    },
    setRoomType(state, actions) {
      state.roomtype = actions.payload.roomtype;
      state.roomtypes = [];
    },
    modifyPropertyValue(state, actions) {
      state.roomtype[actions.payload.id] = actions.payload.value;
    },
    setRoomTypeValidation(state, actions) {
      state.roomtypeValidation = {
        name: {
          ...state.roomtypeValidation.name,
          isValid: actions.payload.name !== "",
        },
        description: {
          ...state.roomtypeValidation.description,
          isValid: actions.payload.description !== "",
        },
        quantity: {
          ...state.roomtypeValidation.quantity,
          isValid: actions.payload.quantity !== "",
        },
      };
      state.roomtypeValidation.isValid =
        state.roomtypeValidation.description.isValid;
    },
    setFieldValid(state, actions) {
      state.roomtypeValidation[actions.payload.id].isValid =
        actions.payload.value;
    },
  },
});

export const roomtypeActions = roomtypeSlice.actions;
export default roomtypeSlice;
