import { courseActions } from "./course-slice";
import { uiActions } from "../ui-slice";

export const GetCourses = (email, showNotification) => {
  return async (dispatch) => {
    if (showNotification) {
      dispatch(
        uiActions.showNotification({
          status: "pending",
          title: "Solicitando Cursos",
          message: "Solicitando Cursos al servidor.",
        })
      );
    }
    const getCoursesInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/getCourses`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener los cursos");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCoursesInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseActions.setCourses({
            courses: response.data.map((course) => {
              return {
                ...course,
                status: course.status === true ? "Activo" : "Inactivo",
              };
            }),
          })
        );
        if (showNotification) {
          dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Datos Cargados",
              message: response.result.message,
            })
          );
        }
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourses({
            courses: [],
          })
        );
        if (showNotification) {
          dispatch(
            uiActions.showNotification({
              status: "warning",
              title: "Advertencia",
              message: response.result.message,
            })
          );
        }
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener los cursos",
        })
      );
    }
  };
};
export const GetCourse = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando Curso",
        message: "Solicitando Curso al servidor.",
      })
    );
    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/getCourseById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: `Curso ${response.data.topic} Cargado`,
            message: response.result.message,
          })
        );
        dispatch(
          courseActions.setCourse({
            course: response.data,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener el curso",
        })
      );
    }
  };
};
export const ActiveCourse = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Activando Curso",
        message: "Solicitando Activación del Curso al servidor.",
      })
    );
    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/active`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo activar el curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseActions.setCourse({
            course: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Curso Activado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo activar el curso",
        })
      );
    }
  };
};
export const InactiveCourse = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Inactivando Curso",
        message: "Solicitando Inactivanción del Curso al servidor.",
      })
    );
    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/inactive`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo inactivar el curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseActions.setCourse({
            course: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Curso Inactivado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo inactivar el curso",
        })
      );
    }
  };
};

const getDaysString = (days) => {
  let daysString = "";
  if (days.monday) {
    daysString += "Lunes, ";
  }
  if (days.tuesday) {
    daysString += "Martes, ";
  }
  if (days.wednesday) {
    daysString += "Miércoles, ";
  }
  if (days.thursday) {
    daysString += "Jueves, ";
  }
  if (days.friday) {
    daysString += "Viernes, ";
  }
  if (days.saturday) {
    daysString += "Sabado, ";
  }
  if (days.sunday) {
    daysString += "Domingo, ";
  }
  return daysString;
};
export const CreateCourse = (course, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Curso",
        message: "Solicitando Actualización del Curso al servidor.",
      })
    );

    const formData = new FormData();
    formData.append("Topic", course.topic);
    formData.append("ShortDescription", course.shortDescription);
    formData.append("Description", course.description);
    formData.append("IsAsync", course.isAsync);
    formData.append("IsOpen", course.isOpen);
    formData.append("CategoryId", course.courseCategory.id);
    formData.append("CourseTypeId", course.courseType.id);
    formData.append("Duration", course.duration);
    formData.append("Price", course.price);
    formData.append("MaxEnrollment", course.maxEnrollment);
    formData.append("Discount", course.discount);
    formData.append("StartDate", course.startDate);
    formData.append("EndDate", course.endDate);
    formData.append("StartTime", course.startTime);
    formData.append("EndTime", course.endTime);
    formData.append("Status", course.courseDays.monday);
    formData.append("Days", getDaysString(course.courseDays));

    course.courseTeachers.forEach((teacher, index) => {
      formData.append(`courseTeachers[${index}][Id]`, teacher.id);
      formData.append(`courseTeachers[${index}][Name]`, teacher.name);
      formData.append(`courseTeachers[${index}][LastName]`, teacher.lastName);
      formData.append(`courseTeachers[${index}][Email]`, teacher.email);
      formData.append(
        `courseTeachers[${index}][Identification]`,
        teacher.identification
      );
      formData.append(`courseTeachers[${index}][CellPhone]`, teacher.cellPhone);
      formData.append(`courseTeachers[${index}][Country]`, teacher.country);
      formData.append(`courseTeachers[${index}][City]`, teacher.city);
      formData.append(`courseTeachers[${index}][Address]`, teacher.address);
      formData.append(`courseTeachers[${index}][Resume]`, teacher.resume);
      if (
        teacher.company !== undefined &&
        teacher.company !== null &&
        teacher.company.id !== undefined &&
        teacher.company.id > 0
      ) {
        formData.append(
          `courseTeachers[${index}][CompanyId]`,
          teacher.company.id
        );
      }
      if (
        teacher.charge !== undefined &&
        teacher.charge !== null &&
        teacher.charge.id !== undefined &&
        teacher.charge.id > 0
      ) {
        formData.append(
          `courseTeachers[${index}][ChargeId]`,
          teacher.charge.id
        );
      }
      if (
        teacher.department !== undefined &&
        teacher.department !== null &&
        teacher.department.id !== undefined &&
        teacher.department.id > 0
      ) {
        formData.append(
          `courseTeachers[${index}][DepartmentId]`,
          teacher.department.id
        );
      }
      // formData.append(`courseTeachers[${index}][Status]`, teacher.status);
      formData.append(`courseTeachers[${index}].Photo`, teacher.photo);
      formData.append(`courseTeachers[${index}].Signature`, teacher.sign);
      teacher.modules.forEach((module, indexModule) => {
        formData.append(
          `courseTeachers[${index}].courseModules[${indexModule}][ModuleName]`,
          module.label
        );
      });
    });
    course.courseModules.forEach((module, index) => {
      formData.append(`courseModules[${index}][Id]`, module.key);
      formData.append(`courseModules[${index}][ModuleName]`, module.label);
      module.lessons.forEach((lesson, indexLesson) => {
        formData.append(
          `courseModules[${index}].courseModuleLessons[${indexLesson}][Name]`,
          lesson.label
        );
      });
    });
    formData.append("Image", course.image);
    formData.append("Brochure", course.brochure);
    formData.append("ZoomUrl", course.zoomUrl);
    formData.append("VideoUrl", course.videoUrl);
    formData.append("UserName", email);

    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/addCourse`,
        {
          method: "POST",
          body: formData,
          headers: {
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseActions.setCourse({
            course: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Curso Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Error") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Error!",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el curso",
        })
      );
    }
  };
};
export const UpadateCourse = (course, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Creando Curso",
        message: "Solicitando Actualización del Curso al servidor.",
      })
    );

    const formData = new FormData();
    formData.append("Id", course.id);
    formData.append("Topic", course.topic);
    formData.append("ShortDescription", course.shortDescription);
    formData.append("Description", course.description);
    formData.append("IsAsync", course.isAsync);
    formData.append("IsOpen", course.isOpen);
    formData.append("CategoryId", course.courseCategory.id);
    formData.append("CourseTypeId", course.courseType.id);
    formData.append("Duration", course.duration);
    formData.append("Price", course.price);
    formData.append("MaxEnrollment", course.maxEnrollment);
    formData.append("Discount", course.discount);
    formData.append("StartDate", course.startDate);
    formData.append("EndDate", course.endDate);
    formData.append("StartTime", course.startTime);
    formData.append("EndTime", course.endTime);
    formData.append("Status", course.courseDays.monday);
    formData.append("Days", getDaysString(course.courseDays));

    course.courseTeachers.forEach((teacher, index) => {
      formData.append(`courseTeachers[${index}][Id]`, teacher.id);
      formData.append(`courseTeachers[${index}][Name]`, teacher.name);
      formData.append(`courseTeachers[${index}][LastName]`, teacher.lastName);
      formData.append(`courseTeachers[${index}][Email]`, teacher.email);
      formData.append(
        `courseTeachers[${index}][Identification]`,
        teacher.identification
      );
      formData.append(`courseTeachers[${index}][CellPhone]`, teacher.cellPhone);
      formData.append(`courseTeachers[${index}][Country]`, teacher.country);
      formData.append(`courseTeachers[${index}][City]`, teacher.city);
      formData.append(`courseTeachers[${index}][Address]`, teacher.address);
      formData.append(`courseTeachers[${index}][Resume]`, teacher.resume);
      if (
        teacher.company !== undefined &&
        teacher.company !== null &&
        teacher.company.id !== undefined &&
        teacher.company.id > 0
      ) {
        formData.append(
          `courseTeachers[${index}][CompanyId]`,
          teacher.company.id
        );
      }
      if (
        teacher.charge !== undefined &&
        teacher.charge !== null &&
        teacher.charge.id !== undefined &&
        teacher.charge.id > 0
      ) {
        formData.append(
          `courseTeachers[${index}][ChargeId]`,
          teacher.charge.id
        );
      }
      if (
        teacher.department !== undefined &&
        teacher.department !== null &&
        teacher.department.id !== undefined &&
        teacher.department.id > 0
      ) {
        formData.append(
          `courseTeachers[${index}][DepartmentId]`,
          teacher.department.id
        );
      }

      // formData.append(
      //   `courseTeachers[${index}][CompanyId]`,
      //   teacher.company.id
      // );
      // formData.append(`courseTeachers[${index}][ChargeId]`, teacher.charge.id);
      // formData.append(
      //   `courseTeachers[${index}][DepartmentId]`,
      //   teacher.department.id
      // );
      formData.append(`courseTeachers[${index}][Status]`, teacher.status);
      formData.append(`courseTeachers[${index}].Photo`, teacher.photo);
      formData.append(`courseTeachers[${index}].Signature`, teacher.sign);

      teacher.courseModules.forEach((module, indexModule) => {
        formData.append(
          `courseTeachers[${index}].courseModules[${indexModule}][ModuleName]`,
          module.moduleName
        );
      });
    });

    course.courseModules.forEach((module, index) => {
      formData.append(`courseModules[${index}][Id]`, module.id);
      formData.append(`courseModules[${index}][ModuleName]`, module.moduleName);
      module.courseModuleLessons.forEach((lesson, indexLesson) => {
        formData.append(
          `courseModules[${index}].courseModuleLessons[${indexLesson}][Name]`,
          lesson.name
        );
      });
    });
    formData.append("Image", course.image);
    formData.append("Brochure", course.brochure);
    formData.append("ZoomUrl", course.zoomUrl);
    formData.append("VideoUrl", course.videoUrl);
    formData.append("UserName", email);

    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/updateCourse`,
        {
          method: "POST",
          body: formData,
          headers: {
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo actualizar el curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseActions.setCourse({
            course: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Curso Actualizado",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Error") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Error!",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo actualizar el curso",
        })
      );
    }
  };
};

export const GetCoursesActive = (email) => {
  return async (dispatch) => {
    const getCoursesInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}Course/getallActive`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener los cursos");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCoursesInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseActions.setCourses({
            courses: response.data.map((course) => {
              return {
                ...course,
                status: course.status === true ? "Activo" : "Inactivo",
              };
            }),
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourses({
            courses: [],
          })
        );
      }
    } catch (error) {}
  };
};
export const GetCourseSelected = (id, email) => {
  return async (dispatch) => {
    const getCourseInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}Course/getById`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Payload: {
              id: id,
            },
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener el curso");
      }
      const data = await response.json();
      return data;
    };
    try {
      const response = await getCourseInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseActions.setCourse({
            course: {
              id: response.data.id,
              speaker: response.data.speaker,
              type: response.data.type,
              topic: response.data.topic,
              description: response.data.description,
              format: response.data.format,
              monday: response.data.monday,
              tuesday: response.data.tuesday,
              wednesday: response.data.wednesday,
              thursday: response.data.thursday,
              friday: response.data.friday,
              saturday: response.data.sunday,
              sunday: response.data.sunday,
              duration: response.data.duration,
              price: response.data.price,
              discount: response.data.discount,
              startDate: response.data.startDate,
              endDate: response.data.endDate,
              startTime: response.data.startTime,
              endTime: response.data.endTime,
              createdAt: response.data.createdAt,
              creationUser: response.data.creationUser,
              updatedAt: response.data.updatedAt,
              updateUser: response.data.updateUser,
              status: response.data.status,
            },
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourse({
            courses: {},
          })
        );
      }
    } catch (error) {}
  };
};

export const GetCourseAndOrdens = (id, email) => {
  return async (dispatch) => {
    dispatch(
      uiActions.showNotification({
        status: "pending",
        title: "Solicitando cursos",
        message: "Solicitando cursos al servidor.",
      })
    );

    const GetConferenceInfo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_URL}admin/course/orden`,
        {
          method: "POST",
          body: JSON.stringify({
            UserName: email,
            Id: id,
          }),
          headers: {
            "Content-Type": "application/json",
            APIKey: process.env.REACT_APP_KEY,
            APISecretKey: process.env.REACT_APP_SECRET_KEY,
            APPName: process.env.REACT_APP_NAME,
          },
        }
      );
      if (!response.ok) {
        throw new Error("No se pudo obtener los cursos");
      }
      const data = await response.json();

      return data;
    };

    try {
      const response = await GetConferenceInfo();
      if (response.result.errorCode === "OK") {
        dispatch(
          courseActions.setCourse({
            course: response.data,
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Datos Cargados",
            message: response.result.message,
          })
        );
      }
      if (response.result.errorCode === "Warning") {
        dispatch(
          courseActions.setCourse({
            courses: [],
          })
        );
        dispatch(
          uiActions.showNotification({
            status: "warning",
            title: "Advertencia",
            message: response.result.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Error!",
          message: "No se pudo obtener cursos",
        })
      );
    }
  };
};
