import { useEffect } from "react";
import { GetCourses } from "../../../store/courses-store/course-actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ABADatatable from "../../../components/ABADatatable";
import { courseActions } from "../../../store/courses-store/course-slice";
import { Button } from "primereact/button";

const CourseHomePAge = () => {
  const dispatch = useDispatch();
  const course = useSelector((state) => state.course);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.roles !== undefined && auth.roles.includes("course.view")) {
      dispatch(GetCourses(auth.UserInfo.email, true));
    } else {
      window.location.href = "/";
    }
  }, [dispatch, auth]);

  const navigate = useNavigate();

  const viewDetailHanlder = (val) => {
    navigate(`/course/view/${val.id}`);
  };
  const editHanlder = (val) => {
    navigate(`/course/edit/${val.id}`);
  };
  const createHanlder = () => {
    dispatch(courseActions.startCourse());
    navigate(`/course/create`);
  };
  const backHanlder = () => {
    navigate(`/`);
  };
  const priceTemplate = (course) => {
    return <span>US$ {course.price}.00</span>;
  };
  const durationTemplate = (course) => {
    return <span>{course.duration} horas</span>;
  };

  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const buttonEdit = (
      <Button
        label="Editar"
        icon="pi pi-file-edit"
        className="p-button-rounded p-button-success p-button-text"
        onClick={() => {
          editHanlder(rowData);
        }}
      />
    );
    const buttonView = (
      <Button
        label="Ver"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-text"
        onClick={() => {
          viewDetailHanlder(rowData);
        }}
      />
    );
    if (auth.roles !== undefined && auth.roles.includes("course.edit")) {
      buttons.push(buttonEdit);
    }
    if (auth.roles !== undefined && auth.roles.includes("course.view")) {
      buttons.push(buttonView);
    }
    return buttons;
  };
  actions.push(buttons);

  const columns = [
    {
      field: "topic",
      header: "Tema",
    },

    {
      field: "price",
      header: "Precio",
      body: priceTemplate,
    },
    {
      field: "duration",
      header: "Duración",
      body: durationTemplate,
    },
    {
      field: "startDate",
      header: "Fecha Inicio",
    },
    {
      field: "endDate",
      header: "Fecha Fin",
    },
    {
      field: "status",
      header: "Estatus",
    },
  ];
  return (
    <>
      <ABADatatable
        title="Capacitaciones"
        values={course.courses}
        width="20%"
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        Columns={columns}
        actions={actions}
        createButton={{
          label: "Crear curso",
          onClick: createHanlder,
          permission: "course.create",
        }}
        backButton={{
          label: "Regresar",
          onClick: backHanlder,
          permission: "goBack",
        }}
      />
    </>
  );
};
export default CourseHomePAge;
