import { createSlice } from "@reduxjs/toolkit";
import { getCurrentDate } from "../../utils/CurrentDate";

const initialState = {
  discounts: [],
  discount: {
    id: 0,
    course: {id: 0},
    conference: {id: 0},
    idCourse: "",
    description: "",
    name: "",
    discountCode: "",
    percentage: false,
    discountPercentage: 0.0,
    nominal: false,
    automatic: false,
    numberOfUses: 0,
    discountNominal: 0.0,
    startDate: new Date().toDateString(),
    endDate: new Date().toDateString(),
  },
  discountValidation: {
    isValid: true,
    name: {
      isValid: true,
      errorMessage: "El campo nombre no debe estar vacio",
    },
    description: {
      isValid: true,
      errorMessage: "El campo descripción no debe estar vacio",
    },
    idCourse: {
      isValid: true,
      errorMessage: "Debe seleccionar un congreso",
    },
    conference: {
      isValid: true,
      errorMessage: "Debe seleccionar un congreso",
    },
    startDate: {
      isValid: true,
      errorMessage: "El fecha inicio Docente no debe estar vacio",
    },
    endDate: {
      isValid: true,
      errorMessage: "El campo fecha Fin no debe estar vacio",
    },
    numberOfUses: {
      isValid: true,
      errorMessage: "La Cantidad de usos no puede ser menor a 1",
    },
    discountCode: {
      isValid: true,
      errorMessage: "",
    },
  },
};

const discountSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    restartState(state) {
      state.discount = {};
      state.discounts = [];
    },
    setDiscounts(state, actions) {
      state.discounts = actions.payload.discounts;
      state.discount = {};
    },
    setDiscount(state, actions) {
      state.discount = actions.payload.discount;
      state.discounts = [];
    },
    modifyPropertyValue(state, actions) {
      state.discount[actions.payload.id] = actions.payload.value;
    },
    setDiscountValidation(state, actions) {
      state.discountValidation = {
        name: {
          ...state.discountValidation.name,
          isValid: actions.payload.name !== "",
        },
        description: {
          ...state.discountValidation.description,
          isValid: actions.payload.description !== "",
        },
        startDate: {
          ...state.discountValidation.startDate,
          isValid: actions.payload.startDate !== "",
        },
        endDate: {
          ...state.discountValidation.endDate,
          isValid: actions.payload.endDate !== "",
        },
        numberOfUses: {
          ...state.discountValidation.numberOfUses,
          isValid: actions.payload.numberOfUses >= 1,
        },
        discountCode: {
          ...state.discountValidation.discountCode,
          isValid: actions.payload.discountCode !== "",
        },
      };

      state.discountValidation.isValid =
        state.discountValidation.name.isValid &&
        state.discountValidation.description.isValid &&
        state.discountValidation.startDate.isValid &&
        state.discountValidation.endDate.isValid &&
        state.discountValidation.numberOfUses.isValid &&
        state.discountValidation.discountCode.isValid;
    },
    setDiscountValid(state, actions) {
      state.discountValidation.discountCode.isValid = actions.payload.isValid;
      state.discountValidation.discountCode.errorMessage =
        actions.payload.errorMessage;
    },
    setFieldValid(state, actions) {
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const CurrentDate = new Date(getCurrentDate("/")).toLocaleDateString(
        "es-ES",
        options
      );
      if (actions.payload.id === "startDate") {
        if (
          new Date(state.discount.startDate) < new Date(getCurrentDate("/"))
        ) {
          state.discountValidation[actions.payload.id].isValid = false;
          state.discountValidation[
            actions.payload.id
          ].errorMessage = `La fecha no puede ser menor a ${CurrentDate}`;
          state.discount[actions.payload.id] = new Date().toDateString();
        } else {
          state.discountValidation[actions.payload.id].isValid =
            actions.payload.value;
        }
      } else if (actions.payload.id === "endDate") {
        if (new Date(state.discount.endDate) < new Date(getCurrentDate("/"))) {
          state.discountValidation[actions.payload.id].isValid = false;
          state.discountValidation[
            actions.payload.id
          ].errorMessage = `La fecha no puede ser menor a ${CurrentDate}`;
          state.discount[actions.payload.id] = new Date().toDateString();
        } else {
          state.discountValidation[actions.payload.id].isValid =
            actions.payload.value;
        }
      } else {
        state.discountValidation[actions.payload.id].isValid =
          actions.payload.value;
      }
    },
  },
});

export const discountActions = discountSlice.actions;
export default discountSlice;
