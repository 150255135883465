import { useSelector } from "react-redux";
import { CreateRoomType } from "../../../store/roomtype-store/roomtype-actions"; 
import RoomTypeForms from "../../../components/Forms/RoomTypeForms";

const RoomTypeCreate = () => {
  const roomtype = useSelector((state) => state.roomtype.roomtype);

  return (
    <>
      <RoomTypeForms actionRoomType={CreateRoomType} roomtype={roomtype} />
    </>
  );
};
export default RoomTypeCreate;
