import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Message } from "primereact/message";

import YesNoModal from "../YesNoModal";
import { Tree } from "primereact/tree";
import { courseActions } from "../../store/courses-store/course-slice";

const CourseStep2 = (props) => {
  const dispatch = useDispatch();
  const [visibleModule, setVisibleModule] = useState(false);
  const [visibleLesson, setVisibleLesson] = useState(false);
  const [visibleRemoveModule, setVisibleRemoveModule] = useState(false);
  const [visibleRemoveLesson, setVisibleRemoveLesson] = useState(false);
  const [visibleEditModule, setVisibleEditModule] = useState(false);
  const [visibleEditLesson, setVisibleEditLesson] = useState(false);
  const [isValidModule, setIsValidModule] = useState(true);
  const [isValidLesson, setIsValidLesson] = useState(true);
  const [expandedKeys, setExpandedKeys] = useState({ 0: true, "0-0": true });

  const [lesson, setLesson] = useState({
    key: 0,
    label: "",
    parent: 0,
  });
  const [nodes, setNodes] = useState([]);
  const [module, setModule] = useState({
    key: 0,
    label: "",
    lessons: [],
  });

  const generateNodes = useCallback(() => {
    let nodes = [];
    if (props.course.courseModules) {
      const listModules = [...props.course.courseModules];
      listModules.sort((a, b) => a.key - b.key);

      listModules.forEach((m) => {
        if (m.lessons !== undefined) {
          const listLessons = [...m.lessons];
          listLessons.sort((a, b) => a.key - b.key);

          const lessons = listLessons.map((l) => {
            return {
              key: l.key,
              label: l.label,
              parent: m.key,
            };
          });
          nodes.push({
            key: m.key,
            label: m.label,
            children: lessons,
          });
        } else {
          nodes.push({
            key: m.key,
            label: m.name,
            children: [],
          });
        }
      });
    }
    setNodes(nodes);

    setModule({
      key: 0,
      label: "",
      lessons: [],
    });
    setLesson({
      key: 0,
      label: "",
    });
    setIsValidLesson(true);
    setIsValidModule(true);
  }, [props.course.courseModules]);

  const generateNodesUpdating = useCallback(() => {
    let nodes = [];
    if (props.course.courseModules) {
      const listModules = [...props.course.courseModules];
      listModules.sort((a, b) => a.id - b.id);

      listModules.forEach((m) => {
        if (m.courseModuleLessons !== undefined) {
          const listLessons = [...m.courseModuleLessons];
          listLessons.sort((a, b) => a.id - b.id);

          const lessons = listLessons.map((l) => {
            return {
              key: l.id,
              label: l.name,
              parent: m.id,
            };
          });
          nodes.push({
            key: m.id,
            label: m.moduleName,
            children: lessons,
          });
        } else {
          nodes.push({
            key: m.id,
            label: m.moduleName,
            children: [],
          });
        }
      });
    }
    setNodes(nodes);

    setModule({
      key: 0,
      label: "",
      lessons: [],
    });
    setLesson({
      key: 0,
      label: "",
    });
    setIsValidLesson(true);
    setIsValidModule(true);
  }, [props.course.courseModules]);

  useEffect(() => {
    if (props.course.courseModules.length > 0 && nodes.length === 0) {
      if (!props.updating) {
        generateNodes();
      } else {
        generateNodesUpdating();        
      }
    }
  }, [
    generateNodes,
    generateNodesUpdating,
    nodes.length,
    props.course.courseModules,
    props.updating,
  ]);

  const getFormErrorMessageModule = () => {
    return !isValidModule ? (
      <Message
        severity="warn"
        text={`El módulo ya existe o el nombre esta en blanco, para el curso: ${props.course.topic}`}
        style={{ width: "100%" }}
      />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };
  const getFormErrorMessageLesson = () => {
    return !isValidLesson ? (
      <Message
        severity="warn"
        text={`La Lección ya existe, para el curso: ${module.label}`}
        style={{ width: "100%" }}
      />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };

  //modules
  const addModule = () => {
    if (props.course.courseModules) {
      const exits = props.course.courseModules.find(
        (m) => m.label.toLowerCase() === module.label.toLowerCase()
      );
      if (exits === undefined && module.label !== "") {
        let modules = [...props.course.courseModules];
        let id = 1;
        if (modules.length > 0) {
          id = modules[modules.length - 1].key + 1;
        }
        modules.push({
          key: id,
          label: module.label,
          lessons: [],
        });
        props.onChangeHandler("courseModules", modules);
        if (modules.length > 0) {
          dispatch(
            courseActions.setFieldValid({ id: "courseModules", value: true })
          );
        } else {
          dispatch(
            courseActions.setFieldValid({ id: "courseModules", value: false })
          );
        }
        setVisibleModule(false);
        setModule({ key: 0, label: "", lessons: [] });
        setNodes([]);
      } else {
        setIsValidModule(false);
      }
    }
  };

  const addModuleUpdating = () => {
    if (props.course.courseModules) {
      const exits = props.course.courseModules.find(
        (m) => m.moduleName.toLowerCase() === module.label.toLowerCase()
      );
      if (exits === undefined && module.label !== "") {
        let modules = [...props.course.courseModules];
        let id = 1;
        if (modules.length > 0) {
          id = modules[modules.length - 1].id + 1;
        }
        modules.push({
          id: id,
          moduleName: module.label,
          courseModuleLessons: [],
        });

        props.onChangeHandler("courseModules", modules);
        if (modules.length > 0) {
          dispatch(
            courseActions.setFieldValid({ id: "courseModules", value: true })
          );
        } else {
          dispatch(
            courseActions.setFieldValid({ id: "courseModules", value: false })
          );
        }
        setVisibleModule(false);
        setModule({ key: 0, label: "", lessons: [] });
        setNodes([]);
      } else {
        setIsValidModule(false);
      }
    }
  };

  const editModule = () => {
    if (props.course.courseModules) {
      const selectModule = props.course.courseModules.find(
        (m) => m.key === module.key
      );

      if (selectModule !== undefined && module.label !== "") {
        let modules = [
          ...props.course.courseModules.filter((m) => m.key !== module.key),
        ];
        modules.push({
          key: selectModule.key,
          label: module.label,
          lessons: selectModule.lessons,
        });

        props.onChangeHandler("courseModules", modules);

        setModule({ key: 0, label: "", lessons: [] });
        setNodes([]);
        setVisibleEditModule(false);
      } else {
        setIsValidModule(false);
      }
    }
  };
  const editModuleUpdating = () => {
    if (props.course.courseModules) {
      const selectModule = props.course.courseModules.find(
        (m) => m.id === module.key
      );

      if (selectModule !== undefined && module.moduleName !== "") {
        let modules = [
          ...props.course.courseModules.filter((m) => m.id !== module.key),
        ];

        modules.push({
          id: selectModule.id,
          moduleName: module.label,
          courseModuleLessons: selectModule.courseModuleLessons,
        });

        props.onChangeHandler("courseModules", modules);

        setModule({ key: 0, label: "", lessons: [] });
        setNodes([]);
        setVisibleEditModule(false);
      } else {
        setIsValidModule(false);
      }
    }
  };

  const removeModule = () => {
    if (props.course.courseModules) {
      const exits = props.course.courseModules.find(
        (m) => m.key === module.key
      );

      if (exits !== undefined) {
        let modules = props.course.courseModules.filter((m) => {
          return m.key !== module.key;
        });
        props.onChangeHandler("courseModules", modules);
        if (modules.length > 0) {
          dispatch(
            courseActions.setFieldValid({ id: "courseModules", value: true })
          );
        } else {
          dispatch(
            courseActions.setFieldValid({ id: "courseModules", value: false })
          );
        }
        setVisibleRemoveModule(false);
        setNodes([]);
      }
    }
  };
  const removeModuleUpdating = () => {
    if (props.course.courseModules) {
      const exits = props.course.courseModules.find((m) => m.id === module.key);

      if (exits !== undefined) {
        let modules = props.course.courseModules.filter((m) => {
          return m.id !== module.key;
        });
        props.onChangeHandler("courseModules", modules);
        if (modules.length > 0) {
          dispatch(
            courseActions.setFieldValid({ id: "courseModules", value: true })
          );
        } else {
          dispatch(
            courseActions.setFieldValid({ id: "courseModules", value: false })
          );
        }
        setVisibleRemoveModule(false);
        setNodes([]);
      }
    }
  };

  const upModule = (node) => {
    if (props.course.courseModules) {
      const selectModule = { ...node };

      if (selectModule !== undefined) {
        let modules = [
          ...nodes.map((m) => {
            return { key: m.key, label: m.label, lessons: m.children };
          }),
        ];

        modules = [...modules.filter((m) => m.key !== selectModule.key)];
        modules.sort((a, b) => b.key - a.key);

        for (let i = 0; i < modules.length; i++) {
          let m = { ...modules[i] };

          if (selectModule.key > m.key) {
            let tempkey = m.key;
            m.key = selectModule.key;
            selectModule.key = tempkey;

            modules = [...modules.filter((m) => m.key !== tempkey)];

            modules.push({
              key: selectModule.key,
              label: selectModule.label,
              lessons: [...selectModule.children],
            });

            modules.push({ key: m.key, label: m.label, lessons: m.lessons });

            break;
          }
        }
        props.onChangeHandler("courseModules", modules);
        setNodes([]);
      }
    }
  };
  const upModuleUpdating = (node) => {
    if (props.course.courseModules) {
      const selectModule = { ...node };

      if (selectModule !== undefined) {
        let modules = [
          ...nodes.map((m) => {
            return {
              id: m.key,
              moduleName: m.label,
              courseModuleLessons: m.children,
            };
          }),
        ];

        modules = [...modules.filter((m) => m.id !== selectModule.key)];
        modules.sort((a, b) => b.id - a.id);

        for (let i = 0; i < modules.length; i++) {
          let m = { ...modules[i] };

          if (selectModule.key > m.id) {
            let tempkey = m.id;
            m.id = selectModule.key;
            selectModule.key = tempkey;

            modules = [...modules.filter((m) => m.id !== tempkey)];

            modules.push({
              id: selectModule.key,
              moduleName: selectModule.label,
              courseModuleLessons: [
                ...selectModule.children.map((l) => {
                  return { id: l.key, name: l.label, parent: l.parent };
                }),
              ],
            });

            modules.push({
              id: m.id,
              moduleName: m.moduleName,
              courseModuleLessons: m.courseModuleLessons,
            });

            break;
          }
        }
        props.onChangeHandler("courseModules", modules);
        setNodes([]);
      }
    }
  };
  const downModule = (node) => {
    if (props.course.courseModules) {
      const selectModule = { ...node };

      if (selectModule !== undefined) {
        let modules = [
          ...nodes.map((m) => {
            return { key: m.key, label: m.label, lessons: m.children };
          }),
        ];

        modules = [...modules.filter((m) => m.key !== selectModule.key)];
        modules.sort((a, b) => a.key - b.key);

        for (let i = 0; i < modules.length; i++) {
          let m = { ...modules[i] };
          if (selectModule.key < m.key) {
            let tempkey = m.key;
            m.key = selectModule.key;
            selectModule.key = tempkey;

            modules = [...modules.filter((m) => m.key !== tempkey)];

            modules.push({
              key: selectModule.key,
              label: selectModule.label,
              lessons: selectModule.children,
            });

            modules.push({ key: m.key, label: m.label, lessons: m.lessons });

            break;
          }
        }

        props.onChangeHandler("courseModules", modules);
        setNodes([]);
      }
    }
  };
  const downModuleUpdating = (node) => {
    if (props.course.courseModules) {
      const selectModule = { ...node };

      if (selectModule !== undefined) {
        let modules = [
          ...nodes.map((m) => {
            return {
              id: m.key,
              moduleName: m.label,
              courseModuleLessons: m.children,
            };
          }),
        ];

        modules = [...modules.filter((m) => m.id !== selectModule.key)];
        modules.sort((a, b) => a.id - b.id);

        for (let i = 0; i < modules.length; i++) {
          let m = { ...modules[i] };
          if (selectModule.key < m.id) {
            let tempkey = m.id;
            m.id = selectModule.key;
            selectModule.key = tempkey;

            modules = [...modules.filter((m) => m.id !== tempkey)];

            modules.push({
              id: selectModule.key,
              moduleName: selectModule.label,
              courseModuleLessons: selectModule.children.map((l) => {
                return { id: l.key, name: l.label, parent: l.parent };
              }),
            });

            modules.push({
              id: m.id,
              moduleName: m.moduleName,
              courseModuleLessons: m.courseModuleLessons,
            });

            break;
          }
        }

        props.onChangeHandler("courseModules", modules);
        setNodes([]);
      }
    }
  };

  //lessons
  const addLesson = () => {
    let selectModule = { ...module };

    if (selectModule !== undefined) {
      if (
        selectModule.children !== undefined &&
        selectModule.children.length > 0
      ) {
        const selectLesson = selectModule.children.find(
          (m) => m.label.toLowerCase() === lesson.label.toLowerCase()
        );

        if (selectLesson === undefined) {
          let listLessons = [...selectModule.children];
          let id = 1;
          if (listLessons.length > 0) {
            id = listLessons[listLessons.length - 1].key + 1;
          }
          listLessons.push({
            key: id,
            label: lesson.label,
            parent: selectModule.key,
          });

          setVisibleLesson(false);
          let modules = [...props.course.courseModules];

          modules.forEach((item, index) => {
            if (item.key === selectModule.key) {
              modules.splice(index, 1);
            }
          });
          modules.push({
            key: selectModule.key,
            label: selectModule.label,
            lessons: listLessons,
          });
          props.onChangeHandler("courseModules", modules);

          setModule("");
          setLesson("");
          setNodes([]);
        } else {
          setIsValidLesson(false);
        }
      } else {
        let listLessons = [];
        listLessons.push({ key: 1, label: lesson.label });
        selectModule.children = listLessons;
        setVisibleLesson(false);
        let modules = [...props.course.courseModules];

        modules.forEach((item, index) => {
          if (item.key === selectModule.key) {
            modules.splice(index, 1);
          }
        });
        modules.push({
          key: selectModule.key,
          label: selectModule.label,
          lessons: listLessons,
        });
        props.onChangeHandler("courseModules", modules);

        let _expandedKeys = {};
        _expandedKeys[module.key] = true;
        setExpandedKeys(_expandedKeys);
        setVisibleEditLesson(false);

        setModule("");
        setLesson("");
        setNodes([]);
      }
    } else {
      setIsValidLesson(false);
    }
  };
  const addLessonUpdating = () => {
    let selectModule = { ...module };

    if (selectModule !== undefined) {
      if (
        selectModule.children !== undefined &&
        selectModule.children.length > 0
      ) {
        const selectLesson = selectModule.children.find(
          (m) => m.label.toLowerCase() === lesson.label.toLowerCase()
        );

        if (selectLesson === undefined) {
          let listLessons = [...selectModule.children];

          let id = 1;
          if (listLessons.length > 0) {
            id = listLessons[listLessons.length - 1].key + 1;
          }
          listLessons = listLessons.map((l) => {
            return { id: l.key, name: l.label, parent: l.parent };
          });

          listLessons.push({
            id: id,
            name: lesson.label,
            parent: selectModule.key,
          });

          setVisibleLesson(false);
          let modules = [...props.course.courseModules];

          modules.forEach((item, index) => {
            if (item.id === selectModule.key) {
              modules.splice(index, 1);
            }
          });
          modules.push({
            id: selectModule.key,
            moduleName: selectModule.label,
            courseModuleLessons: listLessons,
          });
          props.onChangeHandler("courseModules", modules);

          setModule("");
          setLesson("");
          setNodes([]);
        } else {
          setIsValidLesson(false);
        }
      } else {
        let listLessons = [];
        listLessons.push({
          id: 1,
          name: lesson.label,
          parent: selectModule.key,
        });

        selectModule.children = listLessons;
        setVisibleLesson(false);
        let modules = [...props.course.courseModules];

        modules.forEach((item, index) => {
          if (item.id === selectModule.key) {
            modules.splice(index, 1);
          }
        });

        modules.push({
          id: selectModule.key,
          moduleName: selectModule.label,
          courseModuleLessons: listLessons,
        });

        props.onChangeHandler("courseModules", modules);

        let _expandedKeys = {};
        _expandedKeys[module.key] = true;
        setExpandedKeys(_expandedKeys);
        setVisibleEditLesson(false);

        setModule("");
        setLesson("");
        setNodes([]);
      }
    } else {
      setIsValidLesson(false);
    }
  };
  const editLesson = () => {
    let selectModule = { ...module };

    if (selectModule !== undefined) {
      const lessons = selectModule.children.filter((l) => {
        return l.key !== lesson.key;
      });
      let modules = [...props.course.courseModules];
      modules.forEach((item, index) => {
        if (item.key === selectModule.key) {
          modules.splice(index, 1);
        }
      });
      let selectLesson = {
        ...selectModule.children.find((l) => l.key === lesson.key),
      };

      selectLesson.label = lesson.label;
      lessons.push({ ...selectLesson });

      modules.push({
        key: selectModule.key,
        label: selectModule.label,
        lessons: lessons,
      });
      props.onChangeHandler("courseModules", modules);
      let _expandedKeys = {};
      _expandedKeys[module.key] = true;
      setExpandedKeys(_expandedKeys);
      setVisibleEditLesson(false);
      setNodes([]);
    }
  };
  const editLessonUpdating = () => {
    let selectModule = { ...module };

    if (selectModule !== undefined) {
      const lessons = selectModule.children.filter((l) => {
        return l.id !== lesson.key;
      });
      let modules = [...props.course.courseModules];
      modules.forEach((item, index) => {
        if (item.id === selectModule.key) {
          modules.splice(index, 1);
        }
      });

      let selectLesson = {
        ...selectModule.children.find((l) => l.id === lesson.key),
      };

      selectLesson.name = lesson.label;
      lessons.push({ ...selectLesson });

      modules.push({
        id: selectModule.key,
        moduleName: selectModule.label,
        courseModuleLessons: lessons,
      });

      props.onChangeHandler("courseModules", modules);
      let _expandedKeys = {};
      _expandedKeys[selectModule.key] = true;
      setExpandedKeys(_expandedKeys);
      setVisibleEditLesson(false);
      setNodes([]);
    }
  };

  const removeLesson = () => {
    let selectModule = props.course.courseModules.find(
      (m) => m.key === module.key
    );

    if (selectModule !== undefined) {
      const lessons = selectModule.lessons.filter((l) => {
        return l.key !== lesson.key;
      });
      let modules = [...props.course.courseModules];
      modules.forEach((item, index) => {
        if (item.key === selectModule.key) {
          modules.splice(index, 1);
        }
      });
      modules.push({ ...selectModule, lessons: lessons });
      props.onChangeHandler("courseModules", modules);

      let _expandedKeys = {};
      _expandedKeys[module.key] = true;
      setExpandedKeys(_expandedKeys);
      setVisibleEditLesson(false);

      setVisibleRemoveLesson(false);
      setNodes([]);
    }
  };
  const removeLessonUpdating = () => {
    let selectModule = props.course.courseModules.find(
      (m) => m.id === module.key
    );

    if (selectModule !== undefined) {
      const lessons = selectModule.courseModuleLessons.filter((l) => {
        return l.id !== lesson.key;
      });
      let modules = [...props.course.courseModules];
      modules.forEach((item, index) => {
        if (item.id === selectModule.id) {
          modules.splice(index, 1);
        }
      });
      modules.push({ ...selectModule, courseModuleLessons: lessons });
      props.onChangeHandler("courseModules", modules);

      let _expandedKeys = {};
      _expandedKeys[module.key] = true;
      setExpandedKeys(_expandedKeys);
      setVisibleEditLesson(false);

      setVisibleRemoveLesson(false);
      setNodes([]);
    }
  };

  const upLesson = (lesson, module) => {
    if (props.course.courseModules) {
      let selectModule = { ...module };

      if (selectModule !== undefined) {
        let lessons = [
          ...selectModule.lessons.map((l) => {
            return { key: l.key, label: l.label, parent: l.parent };
          }),
        ];
        lessons = [...lessons.filter((m) => m.key !== lesson.key)];

        lessons.sort((a, b) => b.key - a.key);

        for (let i = 0; i < lessons.length; i++) {
          let l = { ...lessons[i] };
          if (lesson.key > l.key) {
            let tempkey = l.key;
            l.key = lesson.key;
            lesson.key = tempkey;

            lessons = [...lessons.filter((m) => m.key !== tempkey)];

            lessons.push({
              key: lesson.key,
              label: lesson.label,
              parent: module.key,
            });

            lessons.push({ key: l.key, label: l.label, parent: module.key });

            break;
          }
        }
        let modules = [
          ...props.course.courseModules.map((m) => {
            return { key: m.key, label: m.label, lessons: m.lessons };
          }),
        ];
        modules.forEach((item, index) => {
          if (item.key === selectModule.key) {
            modules.splice(index, 1);
          }
        });

        modules.push({
          key: selectModule.key,
          label: selectModule.label,
          lessons: lessons,
        });
        let _expandedKeys = {};
        _expandedKeys[module.key] = true;
        setExpandedKeys(_expandedKeys);
        props.onChangeHandler("courseModules", modules);
        setNodes([]);
      }
    }
  };
  const downLesson = (lesson, module) => {
    if (props.course.courseModules) {
      let selectModule = nodes.find((m) => m.key === module.key);
      if (selectModule !== undefined) {
        let lessons = [
          ...selectModule.children.map((l) => {
            return { key: l.key, name: l.label, parent: l.parent };
          }),
        ];

        lessons = [...lessons.filter((m) => m.key !== lesson.key)];
        lessons = [
          ...lessons.map((l) => {
            return { key: l.key, label: l.name, parent: module.key };
          }),
        ];

        lessons.sort((a, b) => a.key - b.key);

        for (let i = 0; i < lessons.length; i++) {
          let l = { ...lessons[i] };

          if (lesson.key < l.key) {
            let tempkey = l.key;
            l.key = lesson.key;
            lesson.key = tempkey;

            lessons = [...lessons.filter((m) => m.key !== tempkey)];

            lessons.push({
              key: lesson.key,
              label: lesson.label,
              parent: module.key,
            });

            lessons.push({ key: l.key, label: l.label, parent: module.key });

            break;
          }
        }
        let modules = [...props.course.courseModules];
        modules.forEach((item, index) => {
          if (item.key === selectModule.key) {
            modules.splice(index, 1);
          }
        });

        modules.push({
          key: selectModule.key,
          label: selectModule.label,
          lessons: lessons,
        });

        let _expandedKeys = {};
        _expandedKeys[module.key] = true;
        setExpandedKeys(_expandedKeys);
        props.onChangeHandler("courseModules", modules);
        setNodes([]);
      }
    }
  };

  //agregar modulo
  const headerModule = (
    <>
      <span style={{ fontSize: "x-large" }}>
        Creando un nuevo módulo para el curso: {props.course.topic}
      </span>
    </>
  );
  const contentModule = (
    <>
      <div className="formgrid grid">
        <div className="field col-12 md:col-12">
          <label
            htmlFor="module"
            style={{
              fontSize: "large",
              color: "#35c4dc",
            }}
          >
            Nombre del Módulo:
          </label>
          <InputText
            id="module"
            value={module.label}
            onChange={(e) =>
              setModule({ key: 0, label: e.target.value, children: [] })
            }
            placeholder="Nombre del Módulo"
            style={{ width: "100%" }}
          />
          <div style={{ width: "100%" }}>{getFormErrorMessageModule()}</div>
        </div>
      </div>
    </>
  );
  //agregar leccion
  const headerLesson = (
    <>
      <span style={{ fontSize: "x-large" }}>
        Agregar lección al módulo: {module.label}
      </span>
    </>
  );
  const contentLesson = (
    <>
      <div className="formgrid grid">
        <div className="field col-12 md:col-12">
          <label
            htmlFor="lesson"
            style={{ fontSize: "large", color: "#35c4dc" }}
          >
            Nombre de la lección:
          </label>
          <InputText
            id="lesson"
            value={lesson.label}
            onChange={(e) =>
              setLesson({ key: 0, label: e.target.value, parent: module.key })
            }
            placeholder="Nombre de la lección"
            style={{ width: "100%" }}
          />
          <div style={{ width: "100%" }}>{getFormErrorMessageLesson()}</div>
        </div>
      </div>
    </>
  );
  //Eliminar modulo
  const headerRemoveModule = (
    <>
      <span style={{ fontSize: "x-large" }}>
        Eliminar módulo: {module.label}, del curso: {props.course.topic}
      </span>
    </>
  );
  const contentRemoveModule = (
    <>
      <div className="formgrid grid">
        <div className="field col-12 md:col-12">
          <label
            htmlFor="lesson"
            style={{ fontSize: "large", color: "#35c4dc" }}
          >
            ¿Desea Eliminar el módulo: {module.label}, del curso:{" "}
            {props.course.topic}?
          </label>
        </div>
      </div>
    </>
  );
  //Eliminar leccion
  const headerRemoveLesson = (
    <>
      <span style={{ fontSize: "x-large" }}>
        Eliminar lección: {lesson.label}, del módulo: {module.label}
      </span>
    </>
  );
  const contentRemoveLesson = (
    <>
      <div className="formgrid grid">
        <div className="field col-12 md:col-12">
          <label
            htmlFor="lesson"
            style={{ fontSize: "large", color: "#35c4dc" }}
          >
            ¿Desea Eliminar la lección: {lesson.label}, del módulo:{" "}
            {module.label}
            {props.course.topic}?
          </label>
        </div>
      </div>
    </>
  );
  //Editar modulo
  const headerEditModule = (
    <>
      <span style={{ fontSize: "x-large" }}>
        Editar el módulo: {module.label}
      </span>
    </>
  );
  const contentEditModule = (
    <>
      <div className="formgrid grid">
        <div className="field col-12 md:col-12">
          <label
            htmlFor="module"
            style={{ fontSize: "large", color: "#35c4dc" }}
          >
            Nombre del Módulo:
          </label>
          <InputText
            id="module"
            value={module.label}
            onChange={(e) => setModule({ ...module, label: e.target.value })}
            placeholder="Nombre del Módulo"
            style={{ width: "100%" }}
          />
          <div style={{ width: "100%" }}>{getFormErrorMessageModule()}</div>
        </div>
      </div>
    </>
  );
  //Editar leccion
  const headerEditLesson = (
    <>
      <span style={{ fontSize: "x-large" }}>
        Editar la lección: {lesson.label}
      </span>
    </>
  );
  const contentEditLesson = (
    <>
      <div className="formgrid grid">
        <div className="field col-12 md:col-12">
          <label
            htmlFor="lesson"
            style={{ fontSize: "large", color: "#35c4dc" }}
          >
            Nombre de la lección:
          </label>
          <InputText
            id="lesson"
            value={lesson.label}
            onChange={(e) => setLesson({ ...lesson, label: e.target.value })}
            placeholder="Nombre de la lección"
            style={{ width: "100%" }}
          />
          <div style={{ width: "100%" }}>{getFormErrorMessageLesson()}</div>
        </div>
      </div>
    </>
  );

  const nodeTemplate = (node, options) => {
    let label = <b>{node.label}</b>;
    let selectModule = undefined;
    if (node.parent) {
      selectModule = props.course.courseModules.find(
        (m) => m.key === node.parent
      );
      let listLesson = [...selectModule.lessons];
      listLesson.sort((a, b) => a.key - b.key);
      selectModule = { ...selectModule, lessons: listLesson };
    }
    const style =
      node.parent === undefined
        ? { borderBottom: "solid 2px white", width: "100%" }
        : {};
    let courseKey = 1;
    if (
      selectModule !== undefined &&
      selectModule.courseModules !== undefined &&
      selectModule.courseModules.length !== undefined
    ) {
      courseKey =
        selectModule.courseModules[selectModule.courseModules.length - 1].key;
    }

    return (
      <>
        <div className="formgrid grid" style={style}>
          <div
            style={{
              border: "solid 1px white",
            }}
            className="mb-2"
          >
            {node.children && (
              <Button
                label="Agregar lección"
                rounded
                text
                icon="pi pi-plus"
                severity="primary"
                onClick={() => {
                  setModule(node);
                  setVisibleLesson(true);
                }}
              />
            )}

            <Button
              label="Editar"
              rounded
              text
              icon="pi pi-file-edit"
              severity="success"
              onClick={() => {
                if (node.children) {
                  setModule(node);
                  setVisibleEditModule(true);
                } else {
                  const module = props.course.courseModules.find(
                    (m) => m.key === node.parent
                  );

                  setModule({
                    key: module.key,
                    label: module.label,
                    children: module.lessons,
                  });
                  setLesson(node);
                  setVisibleEditLesson(true);
                }
              }}
            />
            <Button
              label="Eliminar"
              icon="pi pi-trash"
              rounded
              text
              severity="danger"
              onClick={() => {
                if (node.children) {
                  setModule(node);
                  setVisibleRemoveModule(true);
                } else {
                  const module = props.course.courseModules.find(
                    (m) => m.key === node.parent
                  );
                  setModule({
                    key: module.key,
                    label: module.name,
                    children: module.lessons,
                  });
                  setLesson(node);
                  setVisibleRemoveLesson(true);
                }
              }}
            />
            {node.children && (
              <>
                {(node.key === 1 || node.key < nodes[nodes.length - 1].key) && (
                  <Button
                    rounded
                    text
                    icon="pi pi-sort-down-fill"
                    severity="warning"
                    onClick={() => {
                      props.updating
                        ? downModuleUpdating(node)
                        : downModule(node);
                    }}
                  />
                )}
                {(node.key === nodes.length || node.key > 1) &&
                  nodes.length > 1 && (
                    <Button
                      rounded
                      text
                      icon="pi pi-sort-up-fill"
                      severity="warning"
                      onClick={() => {
                        // upModule(node)
                        props.updating
                          ? upModuleUpdating(node)
                          : upModule(node);
                      }}
                    />
                  )}
              </>
            )}
            {node.parent && (
              <>
                {((node.key === 1 && !props.updating) ||
                  node.key <
                    selectModule.lessons[selectModule.lessons.length - 1]
                      .key) && (
                  <Button
                    rounded
                    text
                    icon="pi pi-sort-down-fill"
                    severity="warning"
                    onClick={() => {
                      const module = props.course.courseModules.find(
                        (m) => m.key === node.parent
                      );
                      setLesson(node);
                      downLesson(node, module);
                    }}
                  />
                )}

                {((node.key === 1 &&
                  props.updating &&
                  selectModule.courseModules !== undefined) ||
                  node.key < courseKey) && (
                  <Button
                    rounded
                    text
                    icon="pi pi-sort-down-fill"
                    severity="warning"
                    onClick={() => {
                      const module = props.course.courseModules.find(
                        (m) => m.key === node.parent
                      );
                      setLesson(node);
                      downLesson(node, module);
                    }}
                  />
                )}
                {(node.key === nodes.length || node.key > 1) &&
                  nodes.length > 1 && (
                    <Button
                      rounded
                      text
                      icon="pi pi-sort-up-fill"
                      severity="warning"
                      onClick={() => {
                        const module = props.course.courseModules.find(
                          (m) => m.key === node.parent
                        );
                        upLesson(node, module);
                      }}
                    />
                  )}
              </>
            )}
          </div>

          <div className="field col-12 md:col-6" style={{ width: "100%" }}>
            <span style={{ color: "white" }}>{label}</span>
          </div>
        </div>
      </>
    );
  };
  const nodeTemplateUpdating = (node, options) => {
    let label = <b>{node.label}</b>;

    let selectModule = undefined;
    if (node.children !== undefined) {
      selectModule = props.course.courseModules.find((m) => m.id === node.key);

      let listLesson = [...selectModule.courseModuleLessons];
      listLesson.sort((a, b) => a.id - b.key);
      selectModule = { ...selectModule, courseModuleLessons: listLesson };
    }

    const style =
      node.parent === undefined
        ? { borderBottom: "solid 2px white", width: "100%" }
        : {};

    return (
      <>
        <div className="formgrid grid" style={style}>
          <div
            style={{
              border: "solid 1px white",
            }}
            className="mb-2"
          >
            {node.children && (
              <Button
                label="Agregar lección"
                rounded
                text
                icon="pi pi-plus"
                severity="primary"
                onClick={() => {
                  setModule(node);
                  setVisibleLesson(true);
                }}
              />
            )}

            <Button
              label="Editar"
              rounded
              text
              icon="pi pi-file-edit"
              severity="success"
              onClick={() => {
                if (node.children) {
                  setModule(node);
                  setVisibleEditModule(true);
                } else {
                  const module = props.course.courseModules.find(
                    (m) => m.id === node.parent
                  );

                  setModule({
                    key: module.id,
                    label: module.moduleName,
                    children: module.courseModuleLessons,
                  });
                  setLesson(node);
                  setVisibleEditLesson(true);
                }
              }}
            />
            <Button
              label="Eliminar"
              icon="pi pi-trash"
              rounded
              text
              severity="danger"
              onClick={() => {
                if (node.children) {
                  setModule(node);
                  setVisibleRemoveModule(true);
                } else {
                  const module = props.course.courseModules.find(
                    (m) => m.id === node.parent
                  );
                  setModule({
                    key: module.id,
                    label: module.moduleName,
                    children: module.courseModuleLessons,
                  });
                  setLesson(node);
                  setVisibleRemoveLesson(true);
                }
              }}
            />
            {node.children && (
              <>
                {(node.key === 1 || node.key < nodes[nodes.length - 1].key) && (
                  <Button
                    rounded
                    text
                    icon="pi pi-sort-down-fill"
                    severity="warning"
                    onClick={() => {
                      props.updating
                        ? downModuleUpdating(node)
                        : downModule(node);
                    }}
                  />
                )}
                {(node.key === nodes.length || node.key > 1) &&
                  nodes.length > 1 && (
                    <Button
                      rounded
                      text
                      icon="pi pi-sort-up-fill"
                      severity="warning"
                      onClick={() => {
                        // upModule(node)
                        props.updating
                          ? upModuleUpdating(node)
                          : upModule(node);
                      }}
                    />
                  )}
              </>
            )}
            {node.parent && (
              <>
                {selectModule !== undefined &&
                  (node.key === 1 ||
                    node.key <
                      selectModule.lessons[selectModule.lessons.length - 1]
                        .key) && (
                    <Button
                      rounded
                      text
                      icon="pi pi-sort-down-fill"
                      severity="warning"
                      onClick={() => {
                        const module = props.course.courseModules.find(
                          (m) => m.key === node.parent
                        );
                        setLesson(node);
                        downLesson(node, module);
                      }}
                    />
                  )}
                {selectModule !== undefined &&
                  (node.key === nodes.length || node.key > 1) &&
                  nodes.length > 1 && (
                    <Button
                      rounded
                      text
                      icon="pi pi-sort-up-fill"
                      severity="warning"
                      onClick={() => {
                        const module = props.course.courseModules.find(
                          (m) => m.key === node.parent
                        );
                        upLesson(node, module);
                      }}
                    />
                  )}
              </>
            )}
          </div>

          <div className="field col-12 md:col-6" style={{ width: "100%" }}>
            <span style={{ color: "white" }}>{label}</span>
          </div>
        </div>
      </>
    );
  };

  const togglerTemplate = (node, options) => {
    if (!node) {
      return;
    }

    const expanded = options.expanded;
    const iconClassName = classNames("p-tree-toggler-icon pi pi-fw", {
      "pi-plus": !expanded,
      "pi-minus": expanded,
    });

    return (
      <button
        type="button"
        className="p-tree-toggler p-link"
        tabIndex={-1}
        onClick={options.onClick}
        style={{ color: "#ffffff" }}
      >
        <span className={iconClassName} aria-hidden="true"></span>
      </button>
    );
  };

  return (
    <>
      <div
        className="mb-4 bg-blue-900 p-3 mt-4"
        style={{
          marginLeft: "0px",
          margin: "0px",
          marginBottom: "20px",
          width: "100%",
          height: "670px",
          overflowY: "auto",
        }}
      >
        <h2
          style={{
            color: "#fff",
            textDecoration: "underline",
            textAlign: "center",
            fontSize: "xx-large",
          }}
        >
          Módulos
        </h2>
        <div className="formgrid grid">
          <div className="field col-12 md:col-6">
            <Button
              label="Nuevo Módulo"
              className="p-button-prymary"
              rounded
              outlined
              onClick={() => {
                setVisibleModule(true);
              }}
              icon={"pi pi-plus"}
              style={{
                width: "20%",
                borderRadius: "6px",
                backgroundColor: "#ffffff",
              }}
            />
          </div>
          <div className="field col-12 md:col-12">
            {nodes && nodes.length > 0 && (
              <Tree
                value={nodes}
                nodeTemplate={
                  props.updating ? nodeTemplateUpdating : nodeTemplate
                }
                togglerTemplate={togglerTemplate}
                style={{ backgroundColor: "#142F47" }}
                expandedKeys={expandedKeys}
                onToggle={(e) => setExpandedKeys(e.value)}
              />
            )}
            <div>{props.getFormErrorMessage("courseModules")}</div>
          </div>
        </div>
      </div>
      <YesNoModal
        className="p-"
        header={headerModule}
        content={contentModule}
        visible={visibleModule}
        setVisible={setVisibleModule}
        yesHandler={props.updating ? addModuleUpdating : addModule}
        showButton={true}
        yesButtonLabel="Guardar"
        icon="pi pi-save"
      />
      <YesNoModal
        className="p-"
        header={headerLesson}
        content={contentLesson}
        visible={visibleLesson}
        setVisible={setVisibleLesson}
        yesHandler={props.updating ? addLessonUpdating : addLesson}
        showButton={true}
        yesButtonLabel="Guardar"
        icon="pi pi-save"
      />
      <YesNoModal
        className="p-"
        header={headerRemoveModule}
        content={contentRemoveModule}
        visible={visibleRemoveModule}
        setVisible={setVisibleRemoveModule}
        yesHandler={props.updating ? removeModuleUpdating : removeModule}
        showButton={true}
        yesButtonLabel="Eliminar"
        icon="pi pi-trash"
        actionSeverity="danger"
      />
      <YesNoModal
        className="p-"
        header={headerRemoveLesson}
        content={contentRemoveLesson}
        visible={visibleRemoveLesson}
        setVisible={setVisibleRemoveLesson}
        yesHandler={props.updating ? removeLessonUpdating : removeLesson}
        showButton={true}
        yesButtonLabel="Eliminar"
        icon="pi pi-trash"
        actionSeverity="danger"
      />
      <YesNoModal
        className="p-"
        header={headerEditModule}
        content={contentEditModule}
        visible={visibleEditModule}
        setVisible={setVisibleEditModule}
        yesHandler={props.updating ? editModuleUpdating : editModule}
        showButton={true}
        yesButtonLabel="Guardar"
        icon="pi pi-save"
      />
      <YesNoModal
        className="p-"
        header={headerEditLesson}
        content={contentEditLesson}
        visible={visibleEditLesson}
        setVisible={setVisibleEditLesson}
        yesHandler={props.updating ? editLessonUpdating : editLesson}
        showButton={true}
        yesButtonLabel="Guardar"
        icon="pi pi-save"
      />
    </>
  );
};
export default CourseStep2;
