import { useEffect } from "react";
import YesNoModal from "../YesNoModal";
import { useDispatch, useSelector } from "react-redux";
import { GetUserById } from "../../store/user-store/user-actions";
import UserDetailInfo from "../../pages/Admin/Users/UserDetailInfo";

// var justOnce = true;
const UserViewComponent = (props) => {
  const user = useSelector((state) => state.user.user);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      (user === undefined || user.id === undefined || user.id === 0) &&
      props.visibleUserView 
    ) {
      dispatch(GetUserById(props.userId, auth.UserInfo.email));
    }
  }, [props.userId, auth, dispatch, user, props.visibleUserView]);

  return (
    <>
      <YesNoModal
        visible={props.visibleUserView}
        setVisible={props.setVisibleUserView}
        content={<UserDetailInfo user={user} showPasswordChange={false} />}
      />
    </>
  );
};
export default UserViewComponent;
