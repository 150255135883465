import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import {
  GetRoomType,
  ActiveRoomType,
  InactiveRoomType,
} from "../../../store/roomtype-store/roomtype-actions";
import Loading from "../../../components/UI/Loading";

const RoomTypeDetail = () => {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roomtype = useSelector((state) => state.roomtype.roomtype);
  const auth = useSelector((state) => state.auth);
  const notification = useSelector((state) => state.ui.notification);

  useEffect(() => {
    if (roomtype.id !== undefined || roomtype.id !== 0) {
      dispatch(GetRoomType(param.roomtypeId, auth.UserInfo.email));
    }
  }, [param.roomtypeId, auth, dispatch, roomtype.id]);

  const activeHandler = () => {
    dispatch(ActiveRoomType(param.roomtypeId, auth.UserInfo.email));
  };
  const inactiveHandler = () => {
    dispatch(InactiveRoomType(param.roomtypeId, auth.UserInfo.email));
  };
  let activeSuspend;
  if (auth.roles !== undefined && auth.roles.includes("roomtype.active")) {
    activeSuspend = (
      <Button
        rounded
        text
        raised
        style={{ backgroundColor: "#fff", marginLeft: "1%" }}
        label="Activar"
        className="p-button-success"
        onClick={activeHandler}
        icon={
          notification && notification.status === "pending"
            ? "pi pi-spin pi-spinner"
            : "pi pi-check"
        }
        disabled={notification && notification.status === "pending"}
      />
    );
  }
  if (roomtype.status) {
    if (auth.roles !== undefined && auth.roles.includes("roomtype.inactive")) {
      activeSuspend = (
        <Button
          rounded
          text
          raised
          style={{ backgroundColor: "#fff", marginLeft: "1%" }}
          label="Suspender"
          className="p-button-warning"
          onClick={inactiveHandler}
          icon={
            notification && notification.status === "pending"
              ? "pi pi-spin pi-spinner"
              : "pi pi-times"
          }
          disabled={notification && notification.status === "pending"}
        />
      );
    }
  }

  return (
    <>
      {(roomtype.id === undefined || roomtype.id <= 0) && (
        <>
          <Loading visible={true} />
        </>
      )}
      {(roomtype.id !== undefined || roomtype.id > 0) && (
        <Card
          style={{
            backgroundColor: "#183462",
            marginLeft: "0%",
            marginTop: "8px",
            boxShadow: "none",
            width: "98%",
            border: "solid #326fd1",
          }}
        >
          <div
            className="user-info text-center"
            style={{ borderBottom: "solid #fff", paddingBottom: "4px" }}
          >
            <h2
              id="header"
              className="mb-2"
              style={{ color: "#fff", textDecoration: "underline" }}
            >
              {roomtype.name.toUpperCase()}
            </h2>
            <span
              className="badge bg-label-secondary"
              style={{
                fontSize: "large",
                color: "lightskyblue",
                fontWeight: "bold",
              }}
            >
              Tipo de Habitación
            </span>
          </div>
          <div className="formgrid grid mt-3">
          <div className="field col-12 md:col-3">
              <>
                <label style={{ color: "#fff", fontSize: "large" }}>
                  Cantidad:{" "}
                </label>
                <label
                  style={{
                    color: "lightskyblue",
                    marginLeft: "1%",
                    fontSize: "large",
                  }}
                >
                  {" "}
                  {roomtype.quantity}
                </label>
              </>
            </div>
            <div className="field col-12 md:col-3">
              <>
                <label style={{ color: "#fff", fontSize: "large" }}>
                  Descripción:{" "}
                </label>
                <label
                  style={{
                    color: "lightskyblue",
                    marginLeft: "1%",
                    fontSize: "large",
                  }}
                >
                  {" "}
                  {roomtype.description}
                </label>
              </>
            </div>
            <div className="field col-12 md:col-12 mt-3">
              <Button
                rounded
                text
                raised
                style={{ backgroundColor: "#fff" }}
                label="Regresar"
                icon="pi pi-arrow-left"
                className="p-button-danger"
                onClick={() => {
                  navigate("/roomtypes");
                }}
              />
              {auth.roles !== undefined &&
                auth.roles.includes("roomtype.edit") && (
                  <Button
                    rounded
                    text
                    raised
                    style={{ backgroundColor: "#fff", marginLeft: "1%" }}
                    label="Editar"
                    icon="pi pi-file-edit"
                    className="p-button-primary"
                    onClick={() => {
                      navigate(`/roomtype/edit/${param.roomtypeId}`);
                    }}
                  />
                )}
              {activeSuspend}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
export default RoomTypeDetail;
