import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { GetConferences } from "../../../store/conferences-store/conference-actions";
import ABADatatable from "../../../components/ABADatatable";
import { conferenceActions } from "../../../store/conferences-store/conference-slice";
import { Tag } from "primereact/tag";

const ConferencesHomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const conference = useSelector((state) => state.conference);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.roles.includes("conferences.showAll")) {
      dispatch(GetConferences(auth.UserInfo.email, true, true));
    }
  }, [dispatch, auth, conference.conference]);

  const viewDetailHanlder = (val) => {
    navigate(`/conference/detail/${val.id}`);
  };
  const editHanlder = (val) => {
    navigate(`/conference/edit/${val.id}`);
  };
  const createHanlder = () => {
    dispatch(
      conferenceActions.setConference({
        conference: {
          id: 0,
          name: "",
          conferecenCodeName: "",
          description: "",
          location: "",
          price: 0,
          soldOut: 0,
          status: false,
          file: null,
          imageUrl: "",
          startDate: "",
          endDate: "",
          informativeTitle: "",
          informativeBody: "",
          conferenceMenuInfoes: [],
          ternmConditions: "",
          creditCardOnly: false,
          withRooms : false,
        },
      })
    );
    navigate("/conference/create");
  };
  const backHanlder = () => {
    navigate("/");
  };
  let actions = [];
  const buttons = (rowData) => {
    let buttons = [];
    const buttonEdit = (
      <Button
        label="Editar"
        icon="pi pi-file-edit"
        className="p-button-rounded p-button-success p-button-text"
        onClick={() => {
          editHanlder(rowData);
        }}
      />
    );
    const buttonView = (
      <Button
        label="Ver"
        icon="pi pi-info-circle"
        className="p-button-rounded p-button-text"
        onClick={() => {
          dispatch(conferenceActions.restartState());
          viewDetailHanlder(rowData);
        }}
      />
    );
    if (auth.roles !== undefined && auth.roles.includes("conferences.edit")) {
      buttons.push(buttonEdit);
    }
    if (auth.roles !== undefined && auth.roles.includes("conferences.view")) {
      buttons.push(buttonView);
    }
    return buttons;
  };
  actions.push(buttons);

  const statusBodyTemplate = (conference) => {
    let severity = "warn";
    let value = "Inactivo";
    if (conference.status) {
      severity = "success";
      value = "Activo";
    }

    return (
      <Tag value={value} style={{ width: "100%" }} severity={severity}></Tag>
    );
  };
  const priceBodyTemplate = (conference) => {
    let USDollar = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return <span>{USDollar.format(conference.price)}</span>;
  };

  const columns = [
    {
      field: "name",
      header: "Congreso",
      filterMatchMode: "contains",
    },
    {
      field: "location",
      header: "Lugar",
      filterMatchMode: "contains",
    },
    {
      field: "startDate",
      header: "Fecha de inico",
      filterMatchMode: "contains",
    },
    {
      field: "price",
      header: "Precio",
      body: priceBodyTemplate,
      filterMatchMode: "contains",
    },
    {
      field: "status",
      header: "Estado",
      body: statusBodyTemplate,
      filterMatchMode: "contains",
    },
  ];
  return (
    <>
      <ABADatatable
        title="Congresos"
        values={conference.conferences}
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        Columns={columns}
        actions={actions}
        width="20%"
        createButton={{
          label: "Congreso",
          onClick: createHanlder,
          permission: "conferences.create",
        }}
        backButton={{
          label: "Regresar",
          onClick: backHanlder,
          permission: "goBack",
        }}
      />
    </>
  );
};
export default ConferencesHomePage;
