import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Card } from "primereact/card";
import { useEffect, useState } from "react";

import { userActions } from "../../store/user-store/user-slice";
import { GetDepartments } from "../../store/department-store/department-actions";
import { GetCharges } from "../../store/charge-store/charge-actions";
import { GetCompanies } from "../../store/company-store/company-actions";

import { isValidEmail } from "../../utils/ValidateUserInfo";
import { arregloPaises } from "../List";

const UserFormDetail = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const isValid = useSelector((state) => state.user.userValidation);

  const charges = useSelector((state) => state.charge.charges);
  const departments = useSelector((state) => state.department.departments);
  const companies = useSelector((state) => state.company.companies);
  const user = useSelector((state) => state.user.user);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCharge, setSelectedCharge] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  // const [charges, setCharges] = useState([]);
  // const [departments, setDepartments] = useState([]);
  // const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (charges.length === 0) {
      dispatch(GetCharges(auth.UserInfo.email, false, false));
    }
  }, [auth.UserInfo.email, charges.length, dispatch]);

  useEffect(() => {
    if (departments.length === 0) {
      dispatch(GetDepartments(auth.UserInfo.email, false, false));
    }
  }, [auth.UserInfo.email, departments.length, dispatch]);

  useEffect(() => {
    if (companies.length === 0) {
      dispatch(GetCompanies(auth.UserInfo.email, false, false));
    }
  }, [auth.UserInfo.email, companies.length, dispatch]);

  useEffect(() => {
    if (
      user.country !== undefined &&
      user.country !== "" &&
      selectedCountry === null
    ) {
      const pais = arregloPaises.find((pais) => pais.nombre === user.country);
      setSelectedCountry({
        code: user.country,
        name: user.country,
        flag: pais !== undefined ? pais.abreviatura : "",
      });
    }
    if (
      user.charge !== undefined &&
      user.charge.id !== undefined &&
      selectedCharge === null
    ) {
      setSelectedCharge({
        code: user.charge.id,
        name: user.charge.name,
      });
    }
    if (
      user.department !== undefined &&
      user.department.id !== undefined &&
      selectedDepartment === null
    ) {
      setSelectedDepartment({
        code: user.department.id,
        name: user.department.name,
      });
    }
    if (
      user.company !== undefined &&
      user.company.id !== undefined &&
      selectedCompany === null
    ) {
      setSelectedCompany({
        code: user.company.id,
        name: user.company.name,
      });
    }
  }, [
    user.country,
    selectedCharge,
    selectedCountry,
    selectedDepartment,
    user,
    selectedCompany,
  ]);

  const onChangeHandler = (id, value) => {
    if (id === "charge" || id === "department" || id === "company") {
      if (id === "charge") {
        dispatch(
          userActions.modifyPropertyValue({
            id,
            value: { id: value.code, name: value.name },
          })
        );

        setSelectedCharge(value);
      }
      if (id === "department") {
        dispatch(
          userActions.modifyPropertyValue({
            id,
            value: { id: value.code, name: value.name },
          })
        );
        setSelectedDepartment(value);
      }
      if (id === "company") {
        dispatch(
          userActions.modifyPropertyValue({
            id,
            value: { id: value.code, name: value.name },
          })
        );
        setSelectedCompany(value);
      }
    } else {
      dispatch(userActions.modifyPropertyValue({ id, value }));
      if (value !== "") {
        if (id === "email") {
          if (isValidEmail(value)) {
            dispatch(userActions.setFieldValid({ id, value: true }));
          } else {
            dispatch(userActions.setFieldValid({ id, value: false }));
          }
        } else if (
          id === "name" ||
          id === "lastName" ||
          id === "email" ||
          id === "cellPhone" ||
          id === "country"
        ) {
          dispatch(userActions.setFieldValid({ id, value: true }));
        }
      } else if (
        id === "name" ||
        id === "lastName" ||
        id === "email" ||
        id === "cellPhone" ||
        id === "country"
      ) {
        dispatch(userActions.setFieldValid({ id, value: false }));
      }
    }
  };
  const onChangeCountriesHandler = (id, value) => {
    dispatch(userActions.modifyPropertyValue({ id, value: value.code }));
    if (value !== "") {
      dispatch(userActions.setFieldValid({ id, value: true }));
    } else {
      dispatch(userActions.setFieldValid({ id, value: false }));
    }
    setSelectedCountry({
      code: value.code,
      name: value.name,
      flag: value.flag,
    });
  };
  const getFormErrorMessage = (id) => {
    return !isValid[id].isValid ? (
      <Message severity="warn" text={isValid[id].errorMessage} />
    ) : (
      <small className="p-warn">&nbsp;</small>
    );
  };

  const paises = arregloPaises.map((pais) => ({
    code: pais.nombre,
    name: pais.nombre,
    flag: pais.abreviatura,
  }));
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src={`https://flagcdn.com/48x36/${option.flag.toLowerCase()}.png`}
            style={{ width: "18px" }}
          />

          <div style={{ marginLeft: "5%" }}>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center" style={{ width: "100px" }}>
        <img
          alt={option.name}
          src={`https://flagcdn.com/48x36/${option.flag.toLowerCase()}.png`}
          style={{ width: "18px" }}
        />
        <div style={{ marginLeft: "5%" }}>{option.name}</div>
      </div>
    );
  };

  return (
    <>
      <div className="card mb-4 ">
        <Card
          style={{
            backgroundColor: "#142f47",
            marginLeft: "0%",
            marginTop: "-16px",
            boxShadow: "none",
            height: "100%",
            width: "98%",
          }}
        >
          <form onSubmit={props.onSubmitHandler}>
            <div className="formgrid grid">
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="name"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  <span style={{ color: "#F9C02D" }}> * </span> Nombres:
                </label>
                <InputText
                  id="name"
                  value={user.name}
                  onChange={(e) => onChangeHandler("name", e.target.value)}
                  placeholder="Nombres"
                  style={{ width: "100%" }}
                />
                <div>{getFormErrorMessage("name")}</div>
              </div>
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="lastName"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  <span style={{ color: "#F9C02D" }}> * </span>Apellidos:
                </label>
                <InputText
                  id="lastName"
                  value={user.lastName}
                  onChange={(e) => onChangeHandler("lastName", e.target.value)}
                  placeholder="Apellidos"
                  style={{ width: "100%" }}
                />
                <div>{getFormErrorMessage("lastName")}</div>
              </div>
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="identification"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Identificación:
                </label>
                <InputText
                  id="identification"
                  value={user.identification}
                  onChange={(e) =>
                    onChangeHandler("identification", e.target.value)
                  }
                  placeholder="Identificación"
                  style={{ width: "100%" }}
                />
                <div>{getFormErrorMessage("identification")}</div>
              </div>
              {props.notEmailEdit === undefined && (
                <div className="field col-12 md:col-4">
                  <label
                    htmlFor="Email"
                    style={{ fontSize: "large", color: "#35c4dc" }}
                  >
                    <span style={{ color: "#F9C02D" }}> * </span>Email:
                  </label>
                  <InputText
                    id="Email"
                    value={user.email}
                    onChange={(e) => onChangeHandler("email", e.target.value)}
                    placeholder="example@email.com"
                    style={{ width: "100%" }}
                  />
                  <div>{getFormErrorMessage("email")}</div>
                </div>
              )}
              {props.notEmailEdit !== undefined && (
                <div className="field col-12 md:col-6">
                  <label
                    htmlFor="Email"
                    style={{ fontSize: "x-large", color: "#35c4dc" }}
                  >
                    Email: {user.email}
                  </label>
                </div>
              )}

              <div className="field col-12 md:col-4">
                <label
                  htmlFor="cellPhone"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  <span style={{ color: "#F9C02D" }}> * </span>Teléfono Celular:
                </label>
                <InputText
                  id="cellPhone"
                  value={user.cellPhone}
                  onChange={(e) => onChangeHandler("cellPhone", e.target.value)}
                  placeholder=""
                  style={{ width: "100%" }}
                />
                <div>{getFormErrorMessage("cellPhone")}</div>
              </div>
            </div>
            <h2 className="mt-3 fw-normal" style={{ color: "#fff" }}>
              Dirección
            </h2>
            <hr className="my-4 mx-n4" />
            <div className="formgrid grid">
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="country"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  <span style={{ color: "#F9C02D" }}> * </span>País:
                </label>
                <br />
                <Dropdown
                  value={selectedCountry}
                  onChange={(e) => {
                    onChangeCountriesHandler("country", e.value);
                  }}
                  options={paises}
                  optionLabel="name"
                  placeholder="Seleccionar pais"
                  valueTemplate={selectedCountryTemplate}
                  filter
                  itemTemplate={countryOptionTemplate}
                  style={{
                    width: "80%",
                    height: "38px",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                />
                <div>{getFormErrorMessage("country")}</div>
              </div>

              <div className="field col-12 md:col-4">
                <label
                  htmlFor="city"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Ciudad:
                </label>
                <InputText
                  id="city"
                  value={user.city}
                  onChange={(e) => onChangeHandler("city", e.target.value)}
                  placeholder="Santo Domingo"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="address"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Domicilio:
                </label>
                <InputText
                  id="address"
                  value={user.address}
                  onChange={(e) => onChangeHandler("address", e.target.value)}
                  placeholder=""
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <h2 className="mt-3 fw-normal" style={{ color: "#fff" }}>
              Información Laboral
            </h2>
            <hr className="my-4 mx-n4" />
            <div className="formgrid grid">
              {companies.length <= 0 && (
                <div className="field col-12 md:col-4">
                  <i
                    className="pi pi-spin pi-cog"
                    style={{ fontSize: "2rem", color: "#fff" }}
                  ></i>
                  <span style={{ fontSize: "2rem", color: "#fff" }}>
                    cargando...
                  </span>
                </div>
              )}
              {companies.length > 0 && (
                <div className="field col-12 md:col-4">
                  <label
                    htmlFor="company"
                    style={{ fontSize: "large", color: "#35c4dc" }}
                  >
                    Empresa:
                  </label>
                  <Dropdown
                    id="company"
                    value={selectedCompany}
                    onChange={(e) => onChangeHandler("company", e.value)}
                    options={companies}
                    optionLabel="name"
                    placeholder="Seleccionar empresa"
                    filter
                    style={{ width: "100%" }}
                  />
                </div>
              )}
              {charges.length <= 0 && (
                <div className="field col-12 md:col-4">
                  <i
                    className="pi pi-spin pi-cog"
                    style={{ fontSize: "2rem", color: "#fff" }}
                  ></i>
                  <span style={{ fontSize: "2rem", color: "#fff" }}>
                    cargando...
                  </span>
                </div>
              )}
              {charges.length > 0 && (
                <div className="field col-12 md:col-4">
                  <label
                    htmlFor="charge"
                    style={{ fontSize: "large", color: "#35c4dc" }}
                  >
                    Cargo:
                  </label>
                  <Dropdown
                    id="cargo"
                    value={selectedCharge}
                    onChange={(e) => onChangeHandler("charge", e.value)}
                    options={charges}
                    optionLabel="name"
                    placeholder="Seleccionar cargo"
                    filter
                    style={{ width: "100%" }}
                  />
                </div>
              )}

              {departments.length <= 0 && (
                <div className="field col-12 md:col-4">
                  <i
                    className="pi pi-spin pi-cog"
                    style={{ fontSize: "2rem", color: "#fff" }}
                  ></i>
                  <span style={{ fontSize: "2rem", color: "#fff" }}>
                    cargando...
                  </span>
                </div>
              )}
              {departments.length > 0 && (
                <div className="field col-12 md:col-4">
                  <label
                    htmlFor="department"
                    style={{ fontSize: "large", color: "#35c4dc" }}
                  >
                    Departamento:
                  </label>
                  <Dropdown
                    id="department"
                    value={selectedDepartment}
                    onChange={(e) => onChangeHandler("department", e.value)}
                    options={departments}
                    optionLabel="name"
                    placeholder="Seleccionar departamento"
                    filter
                    style={{ width: "100%" }}
                  />
                </div>
              )}
              <div className="field col-12 md:col-12">{props.buttons}</div>
            </div>
          </form>
        </Card>
      </div>
    </>
  );
};
export default UserFormDetail;
