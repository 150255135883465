import { useSelector } from "react-redux";
import RoomPersonTypeForms from "../../../components/Forms/RoomPersonTypeForms";
import { CreateRoomPersonType } from "../../../store/roompersontype-store/roompersontype-actions";

const RoomPersonTypeCreate = () => {
  const roompersontype = useSelector(
    (state) => state.roomPersonType.roomPersonType
  );

  return (
    <>
      <RoomPersonTypeForms
        actionRoomPersonType={CreateRoomPersonType}
        roompersontype={roompersontype}
      />
    </>
  );
};
export default RoomPersonTypeCreate;
