import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { GetCourseCategories } from "../../store/coursecategory-store/coursecategory-actions";
import "./CourseStep1.css";
import { GetCourseTypes } from "../../store/coursetype-store/coursetype-actions";
import { Checkbox } from "primereact/checkbox";
import { courseActions } from "../../store/courses-store/course-slice";
import Editor from "../Editor de texto/Editor";

const CourseStep1 = (props) => {
  const auth = useSelector((state) => state.auth);
  const categories = useSelector(
    (state) => state.courseCategory.courseCategories
  );
  const courseTypes = useSelector((state) => state.courseType.courseTypes);

  const [category, setCategory] = useState({ code: "", name: "" });
  const [type, setType] = useState({ code: "", name: "" });
  const [AvailableCategories, setAvailableCategories] = useState([]);
  const [AvailableTypes, setAvailableTypes] = useState([]);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [startTime, setStartTime] = useState(undefined);
  const [endTime, setEndTime] = useState(undefined);
  const [content, setContent] = useState(props.course.description);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.course.description !== undefined) {
      setContent(props.course.description);
    }
  }, [props.course.description]);

  useEffect(() => {
    if (categories === undefined || categories.length === 0) {
      dispatch(GetCourseCategories(auth.UserInfo.email, false, false));
    }
  }, [auth, categories, dispatch]);
  useEffect(() => {
    if (courseTypes === undefined || courseTypes.length === 0) {
      dispatch(GetCourseTypes(auth.UserInfo.email, false, false));
    }
  }, [auth, courseTypes, dispatch]);

  useEffect(() => {
    if (
      categories &&
      categories.length > 0 &&
      props.course.courseCategory &&
      props.course.courseCategory.id > 0 &&
      category.code !== props.course.courseCategory.id
    ) {
      const category = categories.find(
        (category) => category.id === props.course.courseCategory.id
      );
      setCategory({ code: category.id, name: category.category });
    }
  }, [categories, category, props.course.courseCategory]);

  useEffect(() => {
    if (
      courseTypes &&
      courseTypes.length > 0 &&
      props.course.courseType &&
      props.course.courseType.id > 0 &&
      type.code !== props.course.courseType.id
    ) {
      const courseType = courseTypes.find(
        (type) => type.id === props.course.courseType.id
      );

      setType({ code: courseType.id, name: courseType.name });
    }
  }, [courseTypes, type, props.course.courseType]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      setAvailableCategories(
        categories.map((category) => {
          return { code: category.id, name: category.category };
        })
      );
    }
  }, [categories]);

  useEffect(() => {
    if (courseTypes && courseTypes.length > 0) {
      setAvailableTypes(
        courseTypes.map((type) => {
          return { code: type.id, name: type.name };
        })
      );
    }
  }, [courseTypes]);

  useEffect(() => {
    if (
      props.course &&
      props.course.id !== undefined &&
      props.course.id > 0 &&
      props.course.startTime !== undefined &&
      startTime === undefined
    ) {
      // console.log(new Date("01 Jan 1970 " + props.course.startTime));
      setStartTime(new Date("01 Jan 1970 " + props.course.startTime));
    }
  }, [props.course, startTime]);
  // console.log(startTime);

  useEffect(() => {
    if (
      props.course &&
      props.course.id !== undefined &&
      props.course.id > 0 &&
      props.course.endTime !== undefined &&
      endTime === undefined
    ) {
      setEndTime(new Date("01 Jan 1970 " + props.course.endTime));
    }
  }, [props.course, endTime]);
  useEffect(() => {
    if (
      props.course &&
      props.course.id !== undefined &&
      props.course.id > 0 &&
      props.course.startDate !== undefined &&
      startDate === undefined
    ) {
      let parts = props.course.startDate.split("-");
      let dateObject = new Date(parts[2], parts[1] - 1, parts[0]);
      setStartDate(dateObject);
    }
  }, [props.course, startDate]);

  useEffect(() => {
    if (
      props.course &&
      props.course.id !== undefined &&
      props.course.id > 0 &&
      props.course.endDate !== undefined &&
      endDate === undefined
    ) {
      let parts = props.course.endDate.split("-");
      let dateObject = new Date(parts[2], parts[1] - 1, parts[0]);
      setEndDate(dateObject);
    }
  }, [props.course, endDate]);

  const onDaySelected = (day, value) => {
    dispatch(courseActions.setDays({ day }));
    if (value !== "") {
      dispatch(courseActions.setFieldValid({ id: "courseDays", value: true }));
    } else {
      dispatch(courseActions.setFieldValid({ id: "courseDays", value: false }));
    }
  };

  const setStartDatetoCourse = (value) => {
    dispatch(
      courseActions.modifyPropertyValue({
        id: "startDate",
        value: value.toDateString(),
      })
    );
    if (value !== "") {
      dispatch(courseActions.setFieldValid({ id: "startDate", value: true }));
    } else {
      dispatch(courseActions.setFieldValid({ id: "startDate", value: false }));
    }
  };
  const setEndDatetoCourse = (value) => {
    dispatch(
      courseActions.modifyPropertyValue({
        id: "endDate",
        value: value.toDateString(),
      })
    );
    if (value !== "") {
      dispatch(courseActions.setFieldValid({ id: "endDate", value: true }));
    } else {
      dispatch(courseActions.setFieldValid({ id: "endDate", value: false }));
    }
  };
  const setStartTimetoCourse = (value) => {
    if (value === null) {
      return;
    }
    const time = value.toTimeString().split(" ")[0];
    setStartTime(value);
    dispatch(
      courseActions.modifyPropertyValue({ id: "startTime", value: time })
    );
    if (value !== "") {
      dispatch(courseActions.setFieldValid({ id: "startTime", value: true }));
    } else {
      dispatch(courseActions.setFieldValid({ id: "startTime", value: false }));
    }
  };
  const setEndTimeCourse = (value) => {
    if (value === null) {
      return;
    }
    const time = value.toTimeString().split(" ")[0];
    setEndTime(value);
    dispatch(
      courseActions.modifyPropertyValue({
        id: "endTime",
        value: time,
      })
    );
    if (value !== "") {
      dispatch(courseActions.setFieldValid({ id: "endTime", value: true }));
    } else {
      dispatch(courseActions.setFieldValid({ id: "endTime", value: false }));
    }
  };

  return (
    <>
      <div
        className="bg-blue-900 p-3 "
        style={{
          margin: "0px",
          marginBottom: "18px",
          width: "100%",
          height: "700px",
          overflowY: "auto",
        }}
      >
        <h1
          style={{
            color: "#fff",
            textDecoration: "underline",
            textAlign: "center",
          }}
        >
          Información del curso
        </h1>
        <h2 style={{ color: "#fff" }}>
          Nombre del Curso:
          <span style={{ color: "#35c4dc", marginLeft: "1%" }}>
            {props.course &&
              props.course.topic &&
              props.course.topic.toUpperCase()}
          </span>
        </h2>
        <div className="formgrid grid">
          <div className="field col-12 md:col-6">
            <label
              htmlFor="topic"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Tema de la Capacitación
            </label>
            <InputText
              id="topic"
              value={props.course.topic}
              onChange={(e) => props.onChangeHandler("topic", e.target.value)}
              placeholder="Tema de la Capacitación"
              style={{ width: "100%" }}
            />
            <div>{props.getFormErrorMessage("topic")}</div>
            <label
              htmlFor="shortDescription"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Descripción Corta
            </label>
            <InputTextarea
              id="topic"
              rows={5}
              value={props.course.shortDescription}
              onChange={(e) =>
                props.onChangeHandler("shortDescription", e.target.value)
              }
              placeholder="Descripción Corta"
              style={{ width: "100%" }}
            />
            <div>{props.getFormErrorMessage("shortDescription")}</div>
            <div className="formgrid grid">
              <div className="field col-12 md:col-6">
                <label
                  htmlFor="courseCategory"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Categoría:
                </label>
                &nbsp;&nbsp;
                <Dropdown
                  value={category}
                  onChange={(e) =>
                    props.onChangeHandler("courseCategory", e.value)
                  }
                  options={AvailableCategories}
                  optionLabel="name"
                  placeholder="Selecciona una categoría"
                  filter
                />
                <div>{props.getFormErrorMessage("courseCategory")}</div>
              </div>
              <div className="field col-12 md:col-6">
                <label
                  htmlFor="courseType"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Tipo de curso:
                </label>
                &nbsp;&nbsp;
                <Dropdown
                  value={type}
                  onChange={(e) => props.onChangeHandler("courseType", e.value)}
                  options={AvailableTypes}
                  optionLabel="name"
                  placeholder="Seleccionar tipo de curso"
                  filter
                />
                <div>{props.getFormErrorMessage("courseType")}</div>
              </div>
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="price"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Máximo de estudiantes:
                </label>
                <br />
                <InputNumber
                  id={props.course.maxEnrollment}
                  value={
                    props.course.maxEnrollment !== undefined
                      ? props.course.maxEnrollment
                      : ""
                  }
                  onChange={(e) =>
                    props.onChangeHandler("maxEnrollment", e.value)
                  }
                  placeholder=""
                  style={{ width: "10%" }}
                />
                <div>{props.getFormErrorMessage("maxEnrollment")}</div>
              </div>
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="startDate"
                  style={{
                    fontSize: "large",
                    color: "#35c4dc",
                    marginRight: "10px",
                  }}
                >
                  Fecha de inicio
                </label>
                <Calendar
                  id="startDate"
                  value={startDate}
                  onChange={(e) => {
                    setStartDatetoCourse(e.value);
                  }}
                />
                <div>{props.getFormErrorMessage("startDate")}</div>
              </div>
              <div className="field col-12 md:col-3">
                <label
                  htmlFor="endDate"
                  style={{
                    fontSize: "large",
                    color: "#35c4dc",
                    marginRight: "10px",
                  }}
                >
                  Fecha de fin
                </label>
                <Calendar
                  id="endDate"
                  value={endDate}
                  onChange={(e) => {
                    setEndDatetoCourse(e.value);
                  }}
                />
                <div>{props.getFormErrorMessage("endDate")}</div>
              </div>
              <div className="field col-12 md:col-4">
                <label
                  htmlFor="startTime"
                  style={{
                    fontSize: "large",
                    color: "#35c4dc",
                    marginRight: "10px",
                  }}
                >
                  Hora de inicio
                </label>
                <Calendar
                  value={startTime}
                  timeOnly={true}
                  showTime={true}
                  hourFormat="24"
                  onChange={(e) => setStartTimetoCourse(e.value)}
                />
                <div>{props.getFormErrorMessage("startTime")}</div>
              </div>

              <div className="field col-12 md:col-3">
                <label
                  htmlFor="endTime"
                  style={{
                    fontSize: "large",
                    color: "#35c4dc",
                    marginRight: "10px",
                  }}
                >
                  Hora de fin
                </label>
                <Calendar
                  value={endTime}
                  timeOnly={true}
                  showTime={true}
                  hourFormat="24"
                  onChange={(e) => setEndTimeCourse(e.value)}
                />
                <div>{props.getFormErrorMessage("endTime")}</div>
              </div>
              <div className="field col-12 md:col-3">
                <label
                  htmlFor="duration"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Duración (hrs):
                </label>
                <br />
                <InputNumber
                  id={props.course.duration}
                  value={
                    props.course.duration !== undefined
                      ? props.course.duration
                      : ""
                  }
                  onChange={(e) => props.onChangeHandler("duration", e.value)}
                  placeholder="Duración"
                  style={{ width: "10%" }}
                />
                <div>{props.getFormErrorMessage("duration")}</div>
              </div>
              <div className="field col-12 md:col-3">
                <label
                  htmlFor="price"
                  style={{ fontSize: "large", color: "#35c4dc" }}
                >
                  Precio (US$):
                </label>
                <br />
                <InputNumber
                  id={props.course.price}
                  value={
                    props.course.price !== undefined ? props.course.price : ""
                  }
                  onChange={(e) => props.onChangeHandler("price", e.value)}
                  placeholder="Precio (US$)"
                  locale="en-US"
                  minFractionDigits={2}
                  style={{ width: "10%" }}
                />
                <div>{props.getFormErrorMessage("price")}</div>
              </div>
              <div className="field col-12 md:col-2">
                <label
                  htmlFor="isAsync"
                  style={{
                    fontSize: "large",
                    color: "#35c4dc",
                  }}
                >
                  Asincrónico
                </label>
                <br />
                <Checkbox
                  inputId="moisAsyncnday"
                  name="isAsync"
                  value={
                    props.course !== undefined &&
                    props.course.isAsync !== undefined
                      ? props.course.isAsync
                      : false
                  }
                  checked={
                    props.course !== undefined &&
                    props.course.isAsync !== undefined
                      ? props.course.isAsync
                      : false
                  }
                  onChange={(e) => {
                    props.onChangeHandler("isAsync", !e.value);
                  }}
                />
              </div>
              <div className="field col-12 md:col-2">
                <label
                  htmlFor="isOpen"
                  style={{
                    fontSize: "large",
                    color: "#35c4dc",
                  }}
                >
                  Abierto
                </label>
                <br />
                <Checkbox
                  inputId="isOpen"
                  name="isOpen"
                  value={
                    props.course !== undefined &&
                    props.course.isOpen !== undefined
                      ? props.course.isOpen
                      : false
                  }
                  checked={
                    props.course !== undefined &&
                    props.course.isOpen !== undefined
                      ? props.course.isOpen
                      : false
                  }
                  onChange={(e) => {
                    props.onChangeHandler("isOpen", !e.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="field col-12 md:col-6">
            <label
              htmlFor="courseCategory"
              style={{
                fontSize: "large",
                color: "#35c4dc",
                marginLeft: "10px",
              }}
            >
              Descripcíon del Curso:
            </label>
            {props.updating &&
              props.course.description !== undefined &&
              props.course.description !== "" && (
                <Editor
                  content={content}
                  onChangeHandler={props.onChangeHandler}
                />
              )}
            <div>{props.getFormErrorMessage("description")}</div>
            <div className="field col-12 md:col-12">
              <label
                htmlFor="courseCategory"
                style={{ fontSize: "large", color: "#35c4dc" }}
              >
                Días del curso:
              </label>
              <div className="formgrid grid">
                <div className="field col-12 md:col-1">
                  <label
                    htmlFor="monday"
                    style={{
                      fontSize: "large",
                      color: "#35c4dc",
                      marginRight: "10px",
                    }}
                  >
                    Lunes
                  </label>
                  <Checkbox
                    inputId="monday"
                    name="monday"
                    value={
                      props.course !== undefined &&
                      props.course.courseDays !== undefined &&
                      props.course.courseDays.monday !== undefined
                        ? props.course.courseDays.monday
                        : false
                    }
                    checked={
                      props.course !== undefined &&
                      props.course.courseDays !== undefined &&
                      props.course.courseDays.monday !== undefined
                        ? props.course.courseDays.monday
                        : false
                    }
                    onChange={(e) => {
                      onDaySelected("monday", !e.value);
                    }}
                  />
                </div>
                <div
                  className="field col-12 md:col-1"
                  style={{ marginLeft: "4%" }}
                >
                  <label
                    htmlFor="tuesday"
                    style={{
                      fontSize: "large",
                      color: "#35c4dc",
                      marginRight: "10px",
                    }}
                  >
                    Martes
                  </label>
                  <Checkbox
                    inputId="tuesday"
                    name="tuesday"
                    value={
                      props.course !== undefined &&
                      props.course.courseDays !== undefined &&
                      props.course.courseDays.tuesday !== undefined
                        ? props.course.courseDays.tuesday
                        : false
                    }
                    checked={
                      props.course !== undefined &&
                      props.course.courseDays !== undefined &&
                      props.course.courseDays.tuesday !== undefined
                        ? props.course.courseDays.tuesday
                        : false
                    }
                    onChange={(e) => {
                      onDaySelected("tuesday", !e.value);
                    }}
                  />
                </div>
                <div
                  className="field col-12 md:col-1"
                  style={{ marginLeft: "4%" }}
                >
                  <label
                    htmlFor="wednesday"
                    style={{
                      fontSize: "large",
                      color: "#35c4dc",
                      marginRight: "10px",
                    }}
                  >
                    Miércoles
                  </label>
                  <Checkbox
                    inputId="wednesday"
                    name="wednesday"
                    value={
                      props.course !== undefined &&
                      props.course.courseDays !== undefined &&
                      props.course.courseDays.wednesday !== undefined
                        ? props.course.courseDays.wednesday
                        : false
                    }
                    checked={
                      props.course !== undefined &&
                      props.course.courseDays !== undefined &&
                      props.course.courseDays.wednesday !== undefined
                        ? props.course.courseDays.wednesday
                        : false
                    }
                    onChange={(e) => {
                      onDaySelected("wednesday", !e.value);
                    }}
                  />
                </div>
                <div
                  className="field col-12 md:col-1"
                  style={{ marginLeft: "4%" }}
                >
                  <label
                    htmlFor="thursday"
                    style={{
                      fontSize: "large",
                      color: "#35c4dc",
                      marginRight: "10px",
                    }}
                  >
                    Jueves
                  </label>
                  <Checkbox
                    inputId="thursday"
                    name="thursday"
                    value={
                      props.course !== undefined &&
                      props.course.courseDays !== undefined &&
                      props.course.courseDays.thursday !== undefined
                        ? props.course.courseDays.thursday
                        : false
                    }
                    checked={
                      props.course !== undefined &&
                      props.course.courseDays !== undefined &&
                      props.course.courseDays.thursday !== undefined
                        ? props.course.courseDays.thursday
                        : false
                    }
                    onChange={(e) => {
                      onDaySelected("thursday", !e.value);
                    }}
                  />
                </div>
                <div
                  className="field col-12 md:col-1"
                  style={{ marginLeft: "4%" }}
                >
                  <label
                    htmlFor="friday"
                    style={{
                      fontSize: "large",
                      color: "#35c4dc",
                      marginRight: "10px",
                    }}
                  >
                    Viernes
                  </label>
                  <Checkbox
                    inputId="friday"
                    name="friday"
                    value={
                      props.course !== undefined &&
                      props.course.courseDays !== undefined &&
                      props.course.courseDays.friday !== undefined
                        ? props.course.courseDays.friday
                        : false
                    }
                    checked={
                      props.course !== undefined &&
                      props.course.courseDays !== undefined &&
                      props.course.courseDays.friday !== undefined
                        ? props.course.courseDays.friday
                        : false
                    }
                    onChange={(e) => {
                      onDaySelected("friday", !e.value);
                    }}
                  />
                </div>
                <div
                  className="field col-12 md:col-1"
                  style={{ marginLeft: "4%" }}
                >
                  <label
                    htmlFor="saturday"
                    style={{
                      fontSize: "large",
                      color: "#35c4dc",
                      marginRight: "10px",
                    }}
                  >
                    Sábado
                  </label>
                  <Checkbox
                    inputId="saturday"
                    name="saturday"
                    value={
                      props.course !== undefined &&
                      props.course.courseDays !== undefined &&
                      props.course.courseDays.saturday !== undefined
                        ? props.course.courseDays.saturday
                        : false
                    }
                    checked={
                      props.course !== undefined &&
                      props.course.courseDays !== undefined &&
                      props.course.courseDays.saturday !== undefined
                        ? props.course.courseDays.saturday
                        : false
                    }
                    onChange={(e) => {
                      onDaySelected("saturday", !e.value);
                    }}
                  />
                </div>
                <div
                  className="field col-12 md:col-1"
                  style={{ marginLeft: "4%" }}
                >
                  <label
                    htmlFor="sunday"
                    style={{
                      fontSize: "large",
                      color: "#35c4dc",
                      marginRight: "10px",
                    }}
                  >
                    Domingo
                  </label>
                  <Checkbox
                    inputId="sunday"
                    name="sunday"
                    value={
                      props.course !== undefined &&
                      props.course.courseDays !== undefined &&
                      props.course.courseDays.sunday !== undefined
                        ? props.course.courseDays.sunday
                        : false
                    }
                    checked={
                      props.course !== undefined &&
                      props.course.courseDays !== undefined &&
                      props.course.courseDays.sunday !== undefined
                        ? props.course.courseDays.sunday
                        : false
                    }
                    onChange={(e) => {
                      onDaySelected("sunday", !e.value);
                    }}
                  />
                </div>
              </div>
              <div>{props.getFormErrorMessage("courseDays")}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CourseStep1;
