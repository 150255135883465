import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courses: [],
  course: {
    id: 0,
    topic: "",
    shortDescription: "",
    description: "",
    isAsync: true,
    isOpen: true,
    courseCategory: {
      id: 0,
    },
    courseType: {
      id: 0,
    },
    duration: 0,
    price: 0,
    discount: 0,
    currentErrollments: 0,
    maxEnrollment: 0,
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    status: false,
    courseDays: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
    courseTeachers: [],
    courseModules: [],
    image: null,
    brochure: null,
    zoomUrl: "",
    videoUrl: "",
    template: null,
  },

  courseValidation: {
    isValid: true,
    courseTeachers: {
      isValid: true,
      errorMessage: "Debe Seleccionar al menos un facilitador",
    },
    courseDays: {
      isValid: true,
      errorMessage: "Debe seleccionar al menos un día",
    },
    type: {
      isValid: true,
      errorMessage: "El campo Tipo de Curso no debe estar vacio",
    },
    courseCategory: {
      isValid: true,
      errorMessage: "El campo categoría de Curso no debe estar vacio",
    },
    courseType: {
      isValid: true,
      errorMessage: "El campo tipo de Curso no debe estar vacio",
    },
    topic: {
      isValid: true,
      errorMessage: "El campo Tema no debe estar vacio",
    },
    description: {
      isValid: true,
      errorMessage: "El campo Descripción no debe estar vacio",
    },
    format: {
      isValid: true,
      errorMessage: "El campo Formato no debe estar vacio",
    },

    duration: {
      isValid: true,
      errorMessage: "El campo Duración no debe estar vacio",
    },
    price: {
      isValid: true,
      errorMessage: "El campo Precio no debe estar vacio",
    },
    maxEnrollment: {
      isValid: true,
      errorMessage: "El campo Máximo de estudiantes no debe estar vacio",
    },
    startDate: {
      isValid: true,
      errorMessage: "El campo Fecha Inicio no debe estar vacio",
    },
    endDate: {
      isValid: true,
      errorMessage: "El campo Fecha Fin no debe estar vacio",
    },
    startTime: {
      isValid: true,
      errorMessage: "El campo Hora de inicio no debe estar vacio",
    },
    endTime: {
      isValid: true,
      errorMessage: "El campo Hora de fin no debe estar vacio",
    },
    shortDescription: {
      isValid: true,
      errorMessage: "El campo Descripción corta no debe estar vacio",
    },
    courseModules: {
      isValid: true,
      errorMessage: "Debe agregar al menos un módulo",
    },
    courseTeacherModule: {
      isValid: true,
      errorMessage: "Debe agregar al menos un módulo al facilitador",
    },
  },
};

const courseSlice = createSlice({
  name: "Course",
  initialState,
  reducers: {
    restartState(state) {
      state.course = {};
      state.courses = [];
    },
    startCourse(state) {
      state.course = {
        id: 0,
        topic: "",
        shortDescription: "",
        description: "",
        isAsync: true,
        isOpen: true,
        courseCategory: {
          id: 0,
        },
        courseType: {
          id: 0,
        },
        duration: 0,
        price: 0,
        currentErrollments: 0,
        maxEnrollment: 0,
        discount: 0,
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        status: false,
        courseDays: {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        },
        courseTeachers: [],
        courseModules: [],

        image: null,
        brochure: null,
        zoomUrl: "",
        VideoUrl: "",
      };
    },
    setCourse(state, actions) {
      state.course = actions.payload.course;
      state.days = [];
    },
    setCourseTeacher(state, actions) {
      state.course.courseTeachers = actions.payload.courseTeachers;
    },
    setCourses(state, actions) {
      state.course = {};
      state.courses = actions.payload.courses;
      state.days = [];
    },
    modifyPropertyValue(state, actions) {
      state.course[actions.payload.id] = actions.payload.value;
    },
    setDays(state, actions) {
      state.course.courseDays[actions.payload.day] =
        !state.course.courseDays[actions.payload.day];
      if (
        !state.course.courseDays.monday &&
        !state.course.courseDays.tuesday &&
        !state.course.courseDays.wednesday &&
        !state.course.courseDays.thursday &&
        !state.course.courseDays.friday &&
        !state.course.courseDays.saturday &&
        !state.course.courseDays.sunday
      ) {
        state.courseValidation.courseDays.isValid = false;
      }
    },
    setSpeaker(state, actions) {
      state.course.courseTeachers.push(actions.payload.speaker);
      if (state.course.courseTeachers.length >= 1) {
        state.courseValidation.courseTeachers.isValid = true;
      }
    },
    removeSpeake(state, actions) {
      state.course.courseTeachers = state.courseTeachers.filter(
        (speaker) => speaker !== actions.payload.speaker
      );
      if (state.course.courseTeachers.length < 1) {
        state.courseValidation.courseTeachers.isValid = false;
      }
    },
    setIsValid(state, actions) {
      state.courseValidation = {
        courseTeachers: {
          ...state.courseValidation.courseTeachers,
          isValid: actions.payload.courseTeachers.length > 0,
        },
        type: {
          ...state.courseValidation.type,
          isValid: actions.payload.type !== "",
        },
        topic: {
          ...state.courseValidation.topic,
          isValid: actions.payload.topic !== "",
        },
        description: {
          ...state.courseValidation.description,
          isValid: actions.payload.description !== "",
        },
        shortDescription: {
          ...state.courseValidation.shortDescription,
          isValid: actions.payload.shortDescription !== "",
        },
        format: {
          ...state.courseValidation.format,
          isValid: actions.payload.format !== "",
        },
        courseDays: {
          ...state.courseValidation.courseDays,
          isValid:
            state.course.courseDays.monday ||
            state.course.courseDays.tuesday ||
            state.course.courseDays.wednesday ||
            state.course.courseDays.thursday ||
            state.course.courseDays.friday ||
            state.course.courseDays.saturday ||
            state.course.courseDays.sunday,
        },
        duration: {
          ...state.courseValidation.duration,
          isValid: actions.payload.duration > 0,
        },
        price: {
          ...state.courseValidation.price,
          isValid: actions.payload.price > 0,
        },
        maxEnrollment: {
          ...state.courseValidation.maxEnrollment,
          isValid: actions.payload.maxEnrollment > 0,
        },
        startDate: {
          ...state.courseValidation.startDate,
          isValid: actions.payload.startDate !== "",
        },
        endDate: {
          ...state.courseValidation.endDate,
          isValid: actions.payload.endDate !== "",
        },
        startTime: {
          ...state.courseValidation.startTime,
          isValid: actions.payload.startTime !== "",
        },
        endTime: {
          ...state.courseValidation.endTime,
          isValid: actions.payload.endTime !== "",
        },
        courseCategory: {
          ...state.courseValidation.courseCategory,
          isValid: actions.payload.courseCategory.id > 0,
        },
        courseType: {
          ...state.courseValidation.courseType,
          isValid: actions.payload.courseType.id > 0,
        },
        courseModules: {
          ...state.courseValidation.courseModules,
          isValid: actions.payload.courseModules.length > 0,
        },
      };
      state.courseValidation.isValid =
        state.courseValidation.courseTeachers.isValid &&
        state.courseValidation.type.isValid &&
        state.courseValidation.topic.isValid &&
        state.courseValidation.description.isValid &&
        state.courseValidation.shortDescription.isValid &&
        state.courseValidation.format.isValid &&
        state.courseValidation.courseDays.isValid &&
        state.courseValidation.duration.isValid &&
        state.courseValidation.price.isValid &&
        state.courseValidation.maxEnrollment.isValid &&
        state.courseValidation.startDate.isValid &&
        state.courseValidation.endDate.isValid &&
        state.courseValidation.startTime.isValid &&
        state.courseValidation.endTime.isValid &&
        state.courseValidation.courseCategory.isValid &&
        state.courseValidation.courseType.isValid &&
        state.courseValidation.courseModules.isValid;
    },
    setFieldValid(state, actions) {
      state.courseValidation[actions.payload.id].isValid =
        actions.payload.value;
    },
  },
});

export const courseActions = courseSlice.actions;
export default courseSlice;
