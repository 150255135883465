import { useSelector, useDispatch } from "react-redux";
import { CreateDiscount } from "../../../store/discount-store/discount-actions";
import DiscountForm from "../../../components/Forms/DiscountForm";
import { useEffect } from "react";
import { GetConferences } from "../../../store/conferences-store/conference-actions";
import { GetCourses } from "../../../store/courses-store/course-actions";

const DiscountCreate = () => {
  const discount = useSelector((state) => state.discount.discount);
  const courses = useSelector((state) => state.course.courses);
  const conferences = useSelector((state) => state.conference.conferences);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth.roles.includes("conferences.showAll")) {
      dispatch(GetConferences(auth.UserInfo.email, false, false));
    }
  }, [auth.UserInfo.email, auth.roles, dispatch]);

  useEffect(() => {
    if (auth.roles.includes("course.showAll")) {
      dispatch(GetCourses(auth.UserInfo.email, false));
    }
  }, [auth.UserInfo.email, auth.roles, dispatch]);

  return (
    <>
      <DiscountForm
        actionDiscount={CreateDiscount}
        discount={discount}
        courses={courses}
        conferences={conferences}
        creating={true}
      />
    </>
  );
};
export default DiscountCreate;
