import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

import { UpdatePasswordUser } from "../../../store/user-store/user-actions";
import ABADatatable from "../../../components/ABADatatable";
import UserDetailComponent from "../../../components/UserDetailComponent";
import { Card } from "primereact/card";
import Loading from "../../../components/UI/Loading";
import YesNoModal from "../../../components/YesNoModal";
import { InputText } from "primereact/inputtext";
import { uiActions } from "../../../store/ui-slice";

const UserDetailInfo = ({ user, showPasswordChange }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.ui.notification);
  const auth = useSelector((state) => state.auth);

  const [visiblechangePassword, setVisiblechangePassword] = useState(false);
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");

  const onClickHandler = (to) => {
    navigate(to);
  };

  const discountTypeTemplate = (orden) => {
    return (
      <>
        {orden.discount.percentage && orden.discount.discountPercentage + "%"}
        {orden.discount.nominal && "US$" + orden.discount.discountNominal}
      </>
    );
  };
  const discountCourseTemplate = (orden) => {
    let discountAmount = 0;
    if (orden.discount.percentage) {
      discountAmount =
        (orden.discount.discountPercentage / 100) * orden.course.price;
    } else if (orden.discount.nominal) {
      discountAmount = orden.discount.discountNominal;
    }

    return <>US$ {discountAmount}.00</>;
  };
  const discountConferenceTemplate = (orden) => {
    let discountAmount = 0;
    if (orden.discount.percentage) {
      discountAmount =
        (orden.discount.discountPercentage / 100) * orden.conference.price;
    } else if (orden.discount.nominal) {
      discountAmount = orden.discount.discountNominal;
    }

    return <>US$ {discountAmount}.00</>;
  };
  const amountCourseWithTemplate = (orden) => {
    let discountAmount = 0;
    if (orden.discount.percentage) {
      discountAmount =
        (orden.discount.discountPercentage / 100) * orden.course.price;
    } else if (orden.discount.nominal) {
      discountAmount = orden.discount.discountNominal;
    }
    const amount = orden.course.price - discountAmount;
    return <>US$ {amount}.00</>;
  };
  const amountConferenceWithTemplate = (orden) => {
    let discountAmount = 0;
    if (orden.discount.percentage) {
      discountAmount =
        (orden.discount.discountPercentage / 100) * orden.conference.price;
    } else if (orden.discount.nominal) {
      discountAmount = orden.discount.discountNominal;
    }
    const amount = orden.conference.price - discountAmount;
    return <>US$ {amount}.00</>;
  };
  const amountCourseWithOutTemplate = (orden) => {
    return <>US$ {orden.course.price}.00</>;
  };
  const amountConferenceWithOutTemplate = (orden) => {
    return <>US$ {orden.conference.price}.00</>;
  };
  const statusOrden = (orden) => {
    let severity = "warning";
    if (orden.status.id === 2 || orden.status.id === 10) {
      severity = "success";
    }
    return <Tag value={orden.status.description} severity={severity}></Tag>;
  };

  const OrdeIdTemplate = (orden) => {
    const button = (
      <Button
        link
        label={orden.id}
        onClick={() => {
          navigate(`/conference/detail/${orden.conference.id}`);
        }}
      />
    );
    return button;
  };

  const columnsCapacitacion = [
    {
      field: "course.topic",
      header: "Tema",
      filter: false,
      sortable: false,
    },
    {
      field: "course.format",
      header: "Formato",
      filter: false,
      sortable: false,
    },
    {
      header: "Descuento",
      filter: false,
      sortable: false,
      body: (orden) => {
        return discountTypeTemplate(orden);
      },
    },
    {
      header: "Monto descuento",
      filter: false,
      sortable: false,
      body: (orden) => {
        return discountCourseTemplate(orden);
      },
    },
    {
      header: "Monto sin descuento",
      filter: false,
      sortable: false,
      body: (orden) => {
        return amountCourseWithOutTemplate(orden);
      },
    },
    {
      header: "Monto con descuento",
      filter: false,
      sortable: false,
      body: (orden) => {
        return amountCourseWithTemplate(orden);
      },
    },
    {
      header: "Estatus Orden",
      filter: false,
      sortable: false,
      body: (orden) => {
        return statusOrden(orden);
      },
    },
  ];
  const columnsConferences = [
    {
      field: "id",
      header: "Orden",
      filter: true,
      sortable: false,
      body: (orden) => {
        return OrdeIdTemplate(orden);
      },
    },
    {
      field: "conference.name",
      header: "Congreso",
      filter: false,
      sortable: false,
    },
    {
      header: "Descuento",
      filter: false,
      sortable: false,
      body: (orden) => {
        return discountTypeTemplate(orden);
      },
    },
    {
      header: "Monto descuento",
      filter: false,
      sortable: false,
      body: (orden) => {
        return discountConferenceTemplate(orden);
      },
    },
    {
      header: "Monto sin descuento",
      filter: false,
      sortable: false,
      body: (orden) => {
        return amountConferenceWithOutTemplate(orden);
      },
    },
    {
      header: "Monto con descuento",
      filter: false,
      sortable: false,
      body: (orden) => {
        return amountConferenceWithTemplate(orden);
      },
    },
    {
      header: "Estatus Orden",
      filter: false,
      sortable: false,
      body: (orden) => {
        return statusOrden(orden);
      },
    },
  ];

  const headerchangePassword = (
    <>
      <span style={{ fontSize: "x-large" }}>
        Desea Cambiar la contraseña del usuario
        <span style={{ color: "#eec137" }}>{}</span>
      </span>
    </>
  );
  console.log(user);

  const contentchangePassword = (
    <>
      {notification && notification.status === "pending" && (
        <Loading visible={true} />
      )}
      {notification && notification.status !== "pending" && (
        <div className="formgrid grid">
          <div className="field col-12 md:col-12">
            <label
              htmlFor="password"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Contraseña:
            </label>
            <InputText
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ width: "100%" }}
            />
          </div>
          <div className="field col-12 md:col-12">
            <label
              htmlFor="repassword"
              style={{ fontSize: "large", color: "#35c4dc" }}
            >
              Repetir Contraseña:
            </label>
            <InputText
              id="repassword"
              value={repassword}
              onChange={(e) => setRepassword(e.target.value)}
              style={{ width: "100%" }}
            />
          </div>
          <div className="field col-12 md:col-12">
            <Button
              label="Guardar"
              className="p-button-success"
              icon="pi pi-save"
              onClick={() => {
                if (password !== repassword) {
                  dispatch(
                    uiActions.showNotification({
                      status: "warning",
                      title: "Advertencia",
                      message: "Las contraseñas no coinciden.",
                    })
                  );
                } else {
                  dispatch(
                    UpdatePasswordUser(
                      { id: user.id, password: password },
                      auth.UserInfo.email
                    )
                  );
                  setPassword("");
                  setRepassword("");
                }
              }}
            />
          </div>
        </div>
      )}
    </>
  );
  const buttons = (
    <>
      <Button
        rounded
        text
        raised
        label="Regresar"
        icon="pi pi-arrow-left"
        className="p-button-danger"
        style={{ marginLeft: "1%" }}
        onClick={() => {
          onClickHandler("/users");
        }}
      />
      {auth.roles !== undefined && auth.roles.includes("user.edit") && (
        <Button
          rounded
          text
          raised
          label="Editar"
          className="p-button-primary"
          icon="pi pi-file-edit"
          style={{ marginLeft: "1%" }}
          onClick={() => {
            onClickHandler(`/user/edit/${user.id}`);
          }}
        />
      )}
      {auth.roles !== undefined &&
        showPasswordChange &&
        auth.roles.includes("user.changePassword") && (
          <Button
            rounded
            text
            raised
            label="Cambiar Contraseña"
            className="p-button-warning"
            icon="pi pi-key"
            style={{ marginLeft: "1%" }}
            onClick={() => {
              setVisiblechangePassword(true);
            }}
          />
        )}
    </>
  );
  return (
    <>
      <YesNoModal
        className="p-"
        header={headerchangePassword}
        content={contentchangePassword}
        visible={visiblechangePassword}
        setVisible={setVisiblechangePassword}
        showButton={false}
      />

      {user.id === undefined && <Loading visible={true} />}
      {user.id !== undefined && (
        <UserDetailComponent user={user} buttons={buttons} />
      )}

      {/* {user !== null &&
        user.ordens !== undefined &&
        (user.ordens === null || user.ordens.length <= 0) && (
          <Loading visible={true} />
        )} */}
      {user !== null &&
        user.ordens !== undefined &&
        user.ordens !== null &&
        user.ordens.length > 0 && (
          <Card
            className="mt-3"
            style={{
              backgroundColor: "transparent",
              marginBottom: "5%",
              boxShadow: "none",
            }}
          >
            <ABADatatable
              height="300px"
              title="Congresos"
              values={user.ordens.filter((orden) => orden.ordenType.id === 2)}
              rows={5}
              rowsPerPageOptions={[5, 10, 20]}
              Columns={columnsConferences}
              width="0px"
              actions={[]}
              createButton={{
                label: "Crear Usuario",
                onClick: null,
                permission: "na",
              }}
            />
          </Card>
        )}
      {user.ordens !== null &&
        user.ordens !== undefined &&
        user.ordens !== null &&
        user.ordens.length > 0 && (
          <Card
            className="mt-3"
            style={{
              backgroundColor: "transparent",
              marginTop: "5%",
              boxShadow: "none",
            }}
          >
            <ABADatatable
              title="Capacitaciones"
              height=""
              values={user.ordens.filter((orden) => orden.ordenType.id === 1)}
              rows={5}
              rowsPerPageOptions={[5, 10, 20]}
              Columns={columnsCapacitacion}
              width="0px"
              actions={[]}
              createButton={{
                label: "Crear Usuario",
                onClick: null,
                permission: "na",
              }}
            />
          </Card>
        )}
    </>
  );
};

export default UserDetailInfo;
