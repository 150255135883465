import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GetRoomPersonType,
  UpdateRoomPersonType,
} from "../../../store/roompersontype-store/roompersontype-actions";
import RoomPersonTypeForms from "../../../components/Forms/RoomPersonTypeForms";

const RoomPersonTypeEdit = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const roompersontype = useSelector(
    (state) => state.roomPersonType.roomPersonType
  );
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (roompersontype.id === undefined || roompersontype.id === 0) {
      dispatch(GetRoomPersonType(param.roompersontypeId, auth.UserInfo.email));
    }
  }, [param.roompersontypeId, auth.UserInfo.email, dispatch, roompersontype]);


  

  return (
    <>
      <RoomPersonTypeForms
        actionRoomPersonType={UpdateRoomPersonType}
        roompersontype={roompersontype}
      />
    </>
  );
};
export default RoomPersonTypeEdit;
